export const fileSize = mFile => {
  const fsize = mFile.size;
  const fileSize = Math.round(fsize / 1024);
  return fileSize;
};

export const generateRandomID = length => {
  return window
    .btoa(String.fromCharCode(...window.crypto.getRandomValues(new Uint8Array(length * 2))))
    .replace(/[+/]/g, '')
    .substring(0, length);
};

export function randomString(L) {
  var s = '';
  var randomChar = function() {
    var n = Math.floor(Math.random() * 62);
    if (n < 10) return n; //1-10
    if (n < 36) return String.fromCharCode(n + 55); //A-Z
    return String.fromCharCode(n + 61); //a-z
  };
  while (s.length < L) s += randomChar();
  return s;
}

export const acceptFileType = (file, types) => {
  if (types.includes(file.type)) {
    return true;
  }

  return false;
};

export const getImgName = name => {
  const data = name;
  let array = data.split('/');
  let lastItem = array[array.length - 1];
  return lastItem.length < 18 ? lastItem : `${lastItem.slice(0, 17)}...`;
};

export const checkImgRatio = (file, height, width) =>
  new Promise(resolve => {
    let IMG = new Image();
    IMG.src = window.URL.createObjectURL(file);
    IMG.onload = () => {
      if (IMG.width === width && IMG.height === height) resolve(true);
      else resolve(false);
    };
  });

export const splitString = string => {
  const getString = string || '';
  return getString.replace(/_/g, ' ');
};

export function convertSeconds(seconds) {
  let d = Math.floor(seconds / (3600 * 24));
  let h = Math.floor((seconds % (3600 * 24)) / 3600);
  let m = Math.floor((seconds % 3600) / 60);
  let s = seconds % 60;

  return d + 'd ' + h + 'h ' + m + 'm ' + s + 's';
}

export const generateNewFileName = (actualName, newNamePrefix) => {
  const name = actualName.split('.').at(-1);
  const newName = `${newNamePrefix}.${name.toLowerCase()}`;
  //   console.log(newName);
  return newName;
};
