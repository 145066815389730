import { lighten, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(5),
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  container: {
    maxHeight: '80vh',
    background: '#fff',
  },
  table: {
    minWidth: 750,
  },
  dialogRoot: {
    position: 'relative',
    '& .MuiDialog-paperWidthSm': {
      minWidth: '600px',
    },
  },
  dialogRootUser: {
    position: 'relative',
    '& .MuiDialog-paperWidthSm': {
      minWidth: '1000px',
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  total: {
    fontWeight: 'bolder',
    fontSize: 16,
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  dialogTitleRoot: {
    fontWeight: 700,
  },
  modalHead: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderBottom: `1px solid ${theme.palette.borderColor.main}`,
    padding: '7px 24px',
  },
  imgIcon: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#fff',
  },
  userInfoRoot: {
    borderBottom: `1px solid ${theme.palette.borderColor.main}`,
    padding: '20px 24px',
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  codeStatus: {
    display: 'flex',
    alignItems: 'baseline',
    margin: '15px 0',
  },
  reasonGenerate: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: '10px 0',
  },
}));

export default useStyles;
