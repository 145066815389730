import {
  Box,
  Button,
  Popper,
  TableContainer,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { DateRangeOutlined, CloseOutlined } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import NoRecordFound from '../NoRecordFound';
import HistoryTableRow from './HistoryTableRow';
import ConfirmDialog from '@jumbo/components/Common/ConfirmDialog';
import { getScheduledNotification } from 'redux/actions/Notifications';
import DatePicker from './DatePicker';
import { Pagination } from '@material-ui/lab';
import {
  SET_SCHEDULED_NOTIFICATIONS_CURRENT_PAGE,
  SET_SCHEDULED_NOTIFICATIONS_DATE,
  SET_SCHEDULED_NOTIFICATIONS_DATE_APPLIED,
} from '@jumbo/constants/ActionTypes';
import { axiosInstance } from 'utils/axios';

const ScheduleNotification = ({ classes }) => {
  const dispatch = useDispatch();

  let { scheduledNotifications } = useSelector(({ notificationReducer }) => notificationReducer);

  const [showPopover, setShowPopover] = useState(null);

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [tempNotificationId, setTempNotificationId] = useState(null);

  const open = Boolean(showPopover);
  const id = open ? 'simple-popper' : undefined;

  useEffect(() => {
    if (!scheduledNotifications?.isDataFetched) {
      dispatch(getScheduledNotification(scheduledNotifications?.pageSize));
    }
  }, []);

  // console.log(scheduledNotifications);

  const handleDateClear = () => {
    dispatch(getScheduledNotification(scheduledNotifications?.pageSize));
    // setIsDateRangeApplied(false);
    dispatch({ type: SET_SCHEDULED_NOTIFICATIONS_DATE_APPLIED, payload: false });
    dispatch({ type: SET_SCHEDULED_NOTIFICATIONS_DATE, payload: [null, null] });
  };

  const handleApplyDateRange = () => {
    if (!scheduledNotifications?.dateRange[0]) {
      dispatch(fetchError('Please select start date.'));
      return;
    }

    if (!scheduledNotifications?.dateRange[1]) {
      dispatch(fetchError('Please select end date.'));
      return;
    }

    const startDateFormat = moment(scheduledNotifications?.dateRange[0]).format('YYYY-MM-DDT00:00:00+06:00');
    const endDateFormat = moment(scheduledNotifications?.dateRange[1]).format('YYYY-MM-DDT23:59:59+06:00');

    dispatch(
      getScheduledNotification(
        scheduledNotifications?.pageSize,
        scheduledNotifications?.currentPage,
        startDateFormat,
        endDateFormat,
        () => {
          dispatch({ type: SET_SCHEDULED_NOTIFICATIONS_DATE_APPLIED, payload: true });
        }
      )
    );

    setShowPopover(null);
  };

  const deleteNotifications = id => {
    dispatch(fetchStart());
    axiosInstance
      .delete(`/notification/delete/${id}`)
      .then(resp => {
        setDateRange([null, null]);
        setTempNotificationId(null);
        setOpenConfirmDialog(false);
        dispatch(
          getScheduledNotification(scheduledNotifications?.pageSize, 1, '', '', () => {
            dispatch({ type: SET_SCHEDULED_NOTIFICATIONS_DATE_APPLIED, payload: false });
            dispatch({ type: SET_SCHEDULED_NOTIFICATIONS_DATE, payload: [null, null] });
          })
        );

        dispatch(fetchSuccess('Notification deleted successfully.'));
      })
      .catch(error => {
        // console.log(error.response);
        dispatch(fetchError('Sorry, something went wrong. Please try again later.'));
      });
  };

  const handleDeleteItem = data => {
    // console.log(data._id);
    setTempNotificationId(data._id);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDeleteNotification = () => {
    deleteNotifications(tempNotificationId);
  };

  const handlePagination = (e, page) => {
    // setCurrentPage(page);
    // fetchData(search, page);
    // console.log(page);
    dispatch({ type: SET_SCHEDULED_NOTIFICATIONS_CURRENT_PAGE, payload: page });
    dispatch(
      getScheduledNotification(
        scheduledNotifications?.pageSize,
        page,
        scheduledNotifications?.dateRange[0],
        scheduledNotifications?.dateRange[1]
      )
    );
  };

  const setDateRange = value => {
    dispatch({ type: SET_SCHEDULED_NOTIFICATIONS_DATE, payload: value });
  };

  return (
    <>
      <Box display='flex' alignItems='center' justifyContent='space-between' mb={2}>
        <Box>
          <Typography className={classes.title} variant='h4' id='tableTitle' component='div'>
            Scheduled Notifications
          </Typography>
          {scheduledNotifications?.dateRangeApplied && (
            <Typography className='' style={{ marginTop: 5 }} variant='h5' id='tableTitle' component='div'>
              Showing results for:{' '}
              <b>
                {scheduledNotifications && (
                  <>
                    {scheduledNotifications?.dateRange[0] &&
                      moment(scheduledNotifications?.dateRange[0]).format('DD MMM, YY')}{' '}
                    -{' '}
                    {scheduledNotifications?.dateRange[1] &&
                      moment(scheduledNotifications?.dateRange[1]).format('DD MMM, YY')}{' '}
                  </>
                )}
              </b>{' '}
              <span className='clr-btn' onClick={handleDateClear}>
                Clear
              </span>
            </Typography>
          )}
        </Box>
        <button
          aria-describedby={id}
          className='date-range-picker'
          onClick={event => setShowPopover(showPopover ? null : event.currentTarget)}
        >
          <DateRangeOutlined style={{ fill: '#fff', width: 20 }} />
        </button>
      </Box>

      <Popper placement='top-end' id={id} open={open} anchorEl={showPopover} style={{ zIndex: 99 }}>
        <div className='anayltic-popover'>
          <div className='flex justify-end pointer'>
            <CloseOutlined style={{ fill: '#000', width: 30 }} onClick={() => setShowPopover(null)} />
          </div>
          <div className='flex justify-between head'>
            <span>
              {scheduledNotifications && scheduledNotifications?.dateRange[0] ? (
                moment(scheduledNotifications?.dateRange[0]).format('DD MMM, YY')
              ) : (
                <div style={{ color: '#ced4da' }}>start date</div>
              )}
            </span>
            <span>
              {scheduledNotifications && scheduledNotifications?.dateRange[1] ? (
                moment(scheduledNotifications?.dateRange[1]).format('DD MMM, YY')
              ) : (
                <div style={{ color: '#ced4da' }}>end date</div>
              )}
            </span>
          </div>
          <DatePicker
            allowSingleDateInRange
            type='range'
            value={scheduledNotifications?.dateRange}
            onChange={setDateRange}
            minDate={new Date()}
          />
          <Button onClick={handleApplyDateRange} variant='contained' color='primary' className='save'>
            Apply
          </Button>
        </div>
      </Popper>

      <Paper className={classes.paper}>
        <TableContainer className={classes.container}>
          <Table className='' aria-labelledby='tableTitle' aria-label='sticky enhanced table'>
            <TableBody>
              {scheduledNotifications?.data?.length > 0 ? (
                scheduledNotifications?.data.map((row, index) => (
                  <HistoryTableRow
                    key={index}
                    index={index}
                    row={row}
                    addEdit={true}
                    handleDeleteItem={handleDeleteItem}
                  />
                ))
              ) : (
                <TableRow style={{ height: 53 * 6 }}>
                  <TableCell colSpan={7} rowSpan={10}>
                    <NoRecordFound>There are no records found.</NoRecordFound>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {scheduledNotifications?.totalPages > 1 ? (
          <Box px={4} py={5} display='flex' justifyContent='flex-end'>
            <Pagination
              disabled={scheduledNotifications?.paginationDisabled}
              page={scheduledNotifications?.currentPage}
              count={scheduledNotifications?.totalPages}
              color='primary'
              onChange={handlePagination}
            />
          </Box>
        ) : null}
      </Paper>

      <ConfirmDialog
        open={openConfirmDialog}
        title={`Delete Notification`}
        content={'Are you sure you want to delete this notification?'}
        onClose={() => {
          setOpenConfirmDialog(false);
          setTempNotificationId(null);
        }}
        onConfirm={handleConfirmDeleteNotification}
      />
    </>
  );
};

export default ScheduleNotification;
