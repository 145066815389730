import React, { useEffect } from 'react';
import useStyles from '../index.style';
import {
  Box,
  Paper,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
  Table,
} from '@material-ui/core';
import ShowingDate from 'components/ShowingDate.Component';
import { DateRangeOutlined } from '@material-ui/icons';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import moment from 'moment';
import DatePopover from 'components/OnlydatePopover.Component';
import Axios from 'axios';

const tableHeader = ['Category', 'Count'];

const AnalyticOfferClaim = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [showPopover, setShowPopover] = useState(null);
  const open = Boolean(showPopover);
  const id = open ? 'simple-popper' : undefined;

  const [data, setData] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = (startDate, endDate, callback) => {
    let start = startDate || '';
    let end = endDate || '';

    dispatch(fetchStart());

    Axios.get(`${process.env.REACT_APP_BASE_URL}/admin/referral/analytics/claim`, {
      params: { start_time: start, end_time: end },
    })
      .then(resp => {
        // console.log(resp?.data?.data);
        setData(resp?.data?.data);
        // setDynamicLinksFetched(true);
        dispatch(fetchSuccess());
        if (callback && typeof callback === 'function') callback();
      })
      .catch(error => {
        // console.log(error.response);
        dispatch(fetchError('Sorry, something went wrong.'));
      });
  };

  const handleApplyDateRange = () => {
    if (!dateRange[0]) {
      dispatch(fetchError('Please select start date.'));
      return;
    }

    if (!dateRange[1]) {
      dispatch(fetchError('Please select end date.'));
      return;
    }

    const startDateFormat = moment(dateRange[0]).format('YYYY-MM-DDT00:00:00+06:00');
    const endDateFormat = moment(dateRange[1]).format('YYYY-MM-DDT23:59:59+06:00');

    fetchData(startDateFormat, endDateFormat, () => {
      setIsFilterApplied(true);
      setShowPopover(null);
    });
    // setIsFilterApplied(true);
    // console.log(startDateFormat);
    // console.log(endDateFormat);
  };

  const handleDateClear = () => {
    fetchData('', '', () => {
      setIsFilterApplied(false);
      setDateRange([null, null]);
    });
    // setIsFilterApplied(false);
  };

  return (
    <Paper className={classes.paper}>
      <Toolbar className={classes.root} style={{ justifyContent: 'space-between' }}>
        <Box>
          <Typography className={classes.title} variant='h4' id='tableTitle' component='div'>
            Offer Claim (Category Wise) - Data Visualization
          </Typography>

          {isFilterApplied && <ShowingDate dateRange={dateRange} handleClear={handleDateClear} />}
        </Box>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <button
            aria-describedby={id}
            className='date-range-picker'
            onClick={event => setShowPopover(showPopover ? null : event.currentTarget)}
          >
            <DateRangeOutlined style={{ fill: '#fff', width: 20 }} />
          </button>
        </div>
      </Toolbar>

      <TableContainer className={classes.container}>
        <Table
          className='export_paid_user_table1 analytic_table1'
          aria-labelledby='tableTitle'
          aria-label='sticky enhanced table '
        >
          <TableHead style={{ background: '#f4f4f7' }}>
            <TableRow>
              {tableHeader?.map((d, i) => (
                <TableCell key={i} align='center' padding={'normal'}>
                  {d}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align='center' padding={'normal'}>
                Product
              </TableCell>
              <TableCell align='center' padding={'normal'}>
                {data?.product || 0}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='center' padding={'normal'}>
                Any Event
              </TableCell>
              <TableCell align='center' padding={'normal'}>
                {data?.any_event || 0}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell align='center' padding={'normal'}>
                Specific Event
              </TableCell>
              <TableCell align='center' padding={'normal'}>
                {data?.specific_event || 0}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <DatePopover
        id={id}
        open={open}
        showPopover={showPopover}
        setShowPopover={setShowPopover}
        dateRange={dateRange}
        setDateRange={setDateRange}
        handleApplyDateRange={handleApplyDateRange}
        type='range'
        allowSingleDateInRange
        placement='left-end'
      />
    </Paper>
  );
};

export default AnalyticOfferClaim;
