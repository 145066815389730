// /*import Firebase from './firebase';
// import BasicAuth from './Basic';
import JWTAuth from './jwt';

export const AuhMethods = {
  // /*firebase: Firebase,
  // basic: BasicAuth,
  jwtAuth: JWTAuth, //made changes here
  // basic: BasicAuth,
};
