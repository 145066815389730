import React from 'react';
import { Box } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import CmtImage from '../../../../@coremat/CmtImage';

const Logo = ({ color, ...props }) => {
  const logoUrl = '/images/addition/logo.svg';

  return (
    <Box className='pointer' {...props}>
      <NavLink to='/'>
        <CmtImage src={logoUrl} alt='logo' height='35' />
      </NavLink>
    </Box>
  );
};

export default Logo;
