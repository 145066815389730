import React, { memo, useCallback } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core';
import useStyles from '../index.style';
import CmtSearch from '@coremat/CmtSearch';
import { GetApp } from '@material-ui/icons';
import { Pagination } from '@material-ui/lab';
import NoRecordFound from '../../../components/NoRecordFound.Component';
import moment from 'moment';

const tableHeader = [
  'User Name',
  'Payment no.',
  'Login no.',
  'Event Name',
  'Amount - BDT (Including VAT)',
  'Pay Date Time',
  'Trx. Id',
  'VAT(15%)',
  'User Reciept',
  'Office. Copy',
];

const PaymentTable = ({
  handleDownloadPdf,
  paymentData,
  totalPages,
  paginationDisabled,
  handlePagination,
  currentPage,
  selected,
  setSelected,
  allChecked,
  setAllChecked,
  searchTerm,
  setSearchTerm,
  handleSearch,
}) => {
  const classes = useStyles();

  const handleRowCheckbox = useCallback(
    (e, id) => {
      if (e.target.checked) {
        const newSelected = [...selected, id];
        setSelected(newSelected);
      } else {
        const newSelected = [...selected].filter(f => f !== id);
        setSelected(newSelected);
      }
    },
    [selected]
  );

  const handleAllCheckbox = useCallback(
    e => {
      const ids = paymentData?.data?.map((data, i) => i);

      if (e.target.checked) {
        setSelected(ids);
        setAllChecked(true);
      } else {
        setSelected([]);
        setAllChecked(false);
      }
    },
    [paymentData]
  );

  return (
    <Paper className={classes.paper}>
      <Toolbar className={classes.root} display='flex' style={{ justifyContent: 'space-between' }}>
        <Typography className={classes.title} variant='h4' id='tableTitle' component='div'>
          Total: {paymentData?.total_count || 0}
        </Typography>

        <form onSubmit={handleSearch}>
          <Box display='flex'>
            <CmtSearch
              onChange={e => setSearchTerm(e.target.value)}
              value={searchTerm}
              border={true}
              onlyIcon={false}
            />
            <Button type='submit' style={{ marginLeft: 10 }} variant='contained' color='primary'>
              Search
            </Button>
          </Box>
        </form>

        {/* <CmtSearch onChange={e => setSearchTerm(e.target.value)} value={searchTerm} border={false} onlyIcon={false} /> */}
      </Toolbar>

      <TableContainer className={classes.container}>
        <Table stickyHeader className='finance_table'>
          <TableHead>
            <TableRow>
              <TableCell padding='checkbox'>
                <Checkbox style={{ display: 'none' }} checked={allChecked} onChange={handleAllCheckbox} />
              </TableCell>
              <TableCell style={{ display: 'none' }} align='left' padding={'normal'}>
                Trx. No
              </TableCell>
              {tableHeader?.map((d, i) => (
                <TableCell key={i} align='left' padding={'normal'}>
                  {d}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {paymentData?.data?.length > 0 ? (
              paymentData?.data?.map((data, i) => (
                <TableRow key={data._id}>
                  <TableCell padding='checkbox'>
                    <Checkbox checked={selected.includes(i + 1)} onChange={e => handleRowCheckbox(e, i + 1)} />
                  </TableCell>
                  <TableCell align='left' style={{ display: 'none' }}>
                    {data?.serial}
                  </TableCell>
                  <TableCell align='left' style={{ minWidth: 150 }}>
                    {data?.user_info?.user_name}
                  </TableCell>
                  <TableCell align='left'>{data?.bkash_phone}</TableCell>
                  <TableCell align='left'>{data?.user_info?.phone}</TableCell>
                  <TableCell align='left' style={{ minWidth: 200 }}>
                    {data?.prize_event_info?.title}
                  </TableCell>
                  <TableCell align='left' style={{ minWidth: 140 }}>
                    {data?.total_amount_with_vat_tax}
                  </TableCell>
                  <TableCell align='left' style={{ minWidth: 110 }}>
                    {data?.pay_date_time && moment(data?.pay_date_time).format('DD MMM YY; hh.mm A')}
                  </TableCell>
                  <TableCell align='left'>{data?.trx_id}</TableCell>
                  <TableCell align='left' style={{ minWidth: 100 }}>
                    {Number(data?.vat_amount).toFixed(2)}
                  </TableCell>
                  <TableCell align='left'>
                    <GetApp className='pointer' onClick={() => handleDownloadPdf(data, 'user')} />
                  </TableCell>
                  <TableCell align='left'>
                    <GetApp className='pointer' onClick={() => handleDownloadPdf(data, 'office')} />
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow style={{ height: 53 * 6 }}>
                <TableCell colSpan={11} rowSpan={7}>
                  <NoRecordFound>There are no records found.</NoRecordFound>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {totalPages > 1 && (
        <Box px={4} py={5} display='flex' justifyContent='flex-end'>
          <Pagination
            disabled={paginationDisabled}
            page={currentPage}
            count={totalPages}
            color='primary'
            onChange={handlePagination}
          />
        </Box>
      )}
    </Paper>
  );
};

export default memo(PaymentTable);
