import React from 'react';
import { DatePicker as DateRangePicker } from '@mantine/dates';

const DatePicker = ({ value, onChange, maxDate, minDate, weekendDays = [], firstDayOfWeek = 0, ...rest }) => {
  return (
    <DateRangePicker
      firstDayOfWeek={firstDayOfWeek}
      weekendDays={weekendDays}
      value={value}
      onChange={onChange}
      minDate={minDate}
      maxDate={maxDate}
      {...rest}
    />
  );
};
export default DatePicker;
