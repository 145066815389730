import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';

const CustomAlerts = ({ open, setOpen, message, type }) => {
  return (
    <Snackbar
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      open={open}
      autoHideDuration={3000}
      onClose={() => setOpen(false)}
    >
      <Alert onClose={() => setOpen(false)} severity={type}>
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomAlerts;
