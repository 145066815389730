import { Typography } from '@material-ui/core';
import moment from 'moment';
import React from 'react';

const ShowingDate = ({ dateRange, handleClear }) => {
  return (
    <Typography className='' style={{ marginTop: 5 }} variant='h5' id='tableTitle' component='div'>
      Showing results for:{' '}
      <b>
        {' '}
        {dateRange && dateRange[0] && moment(dateRange[0]).format('DD MMM, YY')} -{' '}
        {dateRange && dateRange[1] && moment(dateRange[1]).format('DD MMM, YY')}{' '}
      </b>{' '}
      <span className='clr-btn' onClick={handleClear}>
        Clear
      </span>
    </Typography>
  );
};

export default ShowingDate;
