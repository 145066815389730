import React, { useEffect, useState } from 'react';
import useStyles from '../index.style';
import { useDispatch } from 'react-redux';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import Eye from '../../../assets/img/eye.svg';
import EyeOff from '../../../assets/img/eye-off.svg';
import { toast } from 'react-hot-toast';
import { axiosInstance } from 'utils/axios';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';

const adminList = [
  // { level: 0, type: 'Super Admin' },
  { level: 1, type: 'Admin' },
  { level: 2, type: 'Finance Admin' },
];

const AddEditAdmin = ({ open, selectedAdmin, closeModal, fetchDataAfterActions, setData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [user_name, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [role, setRole] = useState('');

  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (selectedAdmin) {
      // console.log(selectedAdmin);

      setUserName(selectedAdmin?.name);
      setUserEmail(selectedAdmin?.email);
      setPhone(selectedAdmin?.phone_number);
      setRole(selectedAdmin?.level);
    }
  }, [selectedAdmin]);

  const handleSave = () => {
    let namePattern = /^[a-zA-Z. ]{2,30}$/;
    let numberPattern = /^01\d{9}$/;

    if (!user_name) {
      toast.error('User name can not be empty.');
      return;
    }

    if (!namePattern.test(user_name)) {
      toast.error('Please enter a valid name.');
      return;
    }

    if (!phone) {
      toast.error('Phone number can not be empty.');
      return;
    }

    if (!numberPattern.test(phone)) {
      toast.error('Phone number must be 11 digit and bangladeshi number.');
      return;
    }

    if (!role) {
      toast.error('Please select admin role.');
      return;
    }

    const data = {
      user_name: user_name,
      email: userEmail,
      password: password,
      phone_number: phone,
      level: role,
    };

    if (selectedAdmin) {
      // console.log(data, 'update');
      putAdmin({ ...data, role });
    } else {
      postNewAdmin(data);
    }
  };

  const postNewAdmin = data => {
    dispatch(fetchStart());
    axiosInstance
      .post('/admin/register', data)
      .then(resp => {
        // console.log(resp.data);
        dispatch(fetchSuccess());
        closeModal();
        toast.success('Admin added successfully.');
        fetchDataAfterActions();
      })
      .catch(error => {
        // console.log(error.response);
        dispatch(fetchError());
        toast.error('Sorry, something went wrong. Please try again later.');
      });
  };

  const putAdmin = data => {
    // console.log(data);
    // return;
    dispatch(fetchStart());
    axiosInstance
      .put(`/admin/update/${selectedAdmin?._id}`, data)
      .then(resp => {
        // console.log(resp.data);
        toast.success('Admin updated successfully.');
        dispatch(fetchSuccess());
        closeModal();

        setData(prevData => {
          return {
            ...prevData,
            data: prevData?.data?.map(user => {
              if (user._id === resp?.data?.data?._id) {
                return {
                  ...user,
                  name: resp?.data?.data?.name,
                  email: resp?.data?.data?.email,
                  role: resp?.data?.data?.role,
                };
              }
              return { ...user };
            }),
          };
        });
      })
      .catch(error => {
        // console.log(error.response);
        toast.error('Sorry, something went wrong. Please try again later.');
        dispatch(fetchError());
      });
  };

  return (
    <Dialog open={open} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>{selectedAdmin ? 'Edit Admin' : 'Add New Admin'}</DialogTitle>

      <DialogContent dividers>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            fullWidth
            variant='outlined'
            label='User Name'
            value={user_name}
            onChange={e => setUserName(e.target.value)}
            // helperText={prizeEventTitleError}
          />
        </Box>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            fullWidth
            variant='outlined'
            label='User Email'
            value={userEmail}
            onChange={e => setUserEmail(e.target.value)}
            type={'email'}
          />
        </Box>
        <Box mb={{ xs: 6, md: 5 }} className='hide-number-append'>
          <AppTextInput
            fullWidth
            variant='outlined'
            label='User Phone'
            value={phone}
            onChange={e => setPhone(e.target.value)}
            onKeyDown={e => {
              if (e.keyCode === 38 || e.keyCode === 40) e.preventDefault();
            }}
            type={'number'}
            disabled={selectedAdmin ? true : false}
          />
          <small>e.g: 017xxxxxxxx</small>
        </Box>

        <Box mb={{ xs: 6, md: 5 }}>
          <FormControl size='small' fullWidth variant='outlined'>
            <InputLabel id='demo-simple-select-label'>Select Role</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={role}
              label='Select Role'
              onChange={e => setRole(e.target.value)}
            >
              {adminList.map(admin => (
                <MenuItem key={admin.level} value={admin.level}>
                  {admin.type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {!selectedAdmin && (
          <Box mb={{ xs: 6, md: 5 }} className='login-password'>
            <AppTextInput
              fullWidth
              variant='outlined'
              label='Password'
              value={password}
              onChange={e => setPassword(e.target.value)}
              type={showPassword ? 'text' : 'password'}
              inputProps={{
                autoComplete: 'new-password',
              }}
            />
            <div className='hide-unhide pu'>
              {showPassword ? (
                <img onClick={() => setShowPassword(false)} src={EyeOff} alt='' />
              ) : (
                <img onClick={() => setShowPassword(true)} src={Eye} alt='' />
              )}
            </div>
          </Box>
        )}
      </DialogContent>

      <DialogActions>
        <Box display='flex' justifyContent='flex-end' mb={4}>
          <Button onClick={closeModal}>Cancel</Button>
          <Box ml={2}>
            <Button variant='contained' color='primary' onClick={handleSave}>
              Save
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AddEditAdmin;
