import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import useStyles from '../index.style';
import { useDispatch } from 'react-redux';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import CmtList from '@coremat/CmtList';
import GridContainer from '@jumbo/components/GridContainer';
import CancelIcon from '@material-ui/icons/Cancel';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import toast from 'react-hot-toast';
import { axiosInstance } from 'utils/axios';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';

const iconLists = [
  'https://jeeto-assets.s3.amazonaws.com/assets/actions/Single+Tap.png',
  'https://jeeto-assets.s3.amazonaws.com/assets/actions/Double+Tao.png',
  'https://jeeto-assets.s3.amazonaws.com/assets/actions/Swipe+right.png',
  'https://jeeto-assets.s3.amazonaws.com/assets/actions/Swipe+left.png',
  'https://jeeto-assets.s3.amazonaws.com/assets/actions/Swipe+up.png',
  'https://jeeto-assets.s3.amazonaws.com/assets/actions/Swipe+down.png',
  'https://jeeto-assets.s3.amazonaws.com/assets/actions/Hold.png',
];

const AddEditTutorial = ({ open, closeModal, selectedTutorial, gameDetails, setData, setShowAddButton }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [gameName, setGameName] = useState('');
  const [tutorialDescription, setTutorialDescription] = useState('');
  const [videoUrl, setVideoUrl] = useState('');
  const [uploadBanners, setUploadBanners] = useState([{ banner: '', banner_url: '' }]);
  const [iconDescription, setIconDescription] = useState([{ icon: '', description: '', icon_url: '', title: '' }]);

  useEffect(() => {
    if (selectedTutorial) {
      // console.log(selectedTutorial);
      setTutorialDescription(selectedTutorial?.game_details);
      setVideoUrl(selectedTutorial?.video_urls.length && selectedTutorial.video_urls[0]);

      const banners = selectedTutorial.banner.map(b => {
        return {
          banner: '',
          banner_url: b,
        };
      });
      setUploadBanners(selectedTutorial?.banner?.length !== 0 ? banners : [{ banner: '', banner_url: '' }]);

      const iconUrl = selectedTutorial?.actions.map(a => {
        return {
          icon: a.action_image_url,
          description: a.action_details,
          icon_url: '',
          title: a.action_title,
        };
      });
      setIconDescription(iconUrl);
    }

    if (gameDetails) {
      setGameName(gameDetails?.game_title);
    }
  }, [selectedTutorial]);

  const handleSave = () => {
    if (!tutorialDescription) {
      toast.error('Tutorial description is required.');
      return;
    }

    if (!uploadBanners[0].banner_url) {
      toast.error('Banner image is required.');
      return;
    }

    let checkIconDescription = false;

    iconDescription.forEach(single => {
      if (single.description !== '' && single.icon !== '' && single.title !== '') {
        checkIconDescription = true;
      } else {
        checkIconDescription = false;
      }
    });

    if (!checkIconDescription) {
      toast.error('All icon fields must be required.');
      return;
    }

    const actionDetails = iconDescription.map(m => {
      return {
        action_title: m.title,
        action_details: m.description,
        action_image_url: m.icon,
      };
    });

    const tutorialDetail = {
      game_id: gameDetails._id,
      game_title: gameDetails.game_title,
      game_details: tutorialDescription,
      banners: uploadBanners,
      video_urls: [videoUrl],
      actions: actionDetails,
    };

    if (selectedTutorial) {
      // console.log('put data', tutorialDetail);
      putTutorial(tutorialDetail, selectedTutorial?._id);
    } else {
      postTutorial(tutorialDetail);
    }
  };

  const putTutorial = async (data, id) => {
    // console.log(data);
    // console.log(id);

    let formData = new FormData();

    for (let i = 0; i < data?.banners.length; i++) {
      const file = data.banners[i].banner;
      if (file) {
        formData.append(`game_training_banner_${i}`, file, file.name);
      }
    }

    formData.append('data', JSON.stringify(data));

    dispatch(fetchStart());

    axiosInstance
      .put(`/admin/game/training/update/${id}`, formData)
      .then(resp => {
        // console.log(resp.data);
        setData(resp.data?.data);
        dispatch(fetchSuccess());
        closeModal();
        toast.success('Game tutorial updated successfully.');
      })
      .catch(error => {
        // console.log(error.response);
        toast.error('Sorry, something went wrong. Please try again later.');
        dispatch(fetchError());
      });
  };

  const postTutorial = data => {
    let formData = new FormData();

    for (let i = 0; i < data?.banners.length; i++) {
      const file = data.banners[i].banner;
      if (file) {
        formData.append('game_training_banner', file, file.name);
      }
    }

    formData.append('data', JSON.stringify(data));

    dispatch(fetchStart());

    axiosInstance
      .post('/admin/game/training/add', formData)
      .then(resp => {
        // console.log(resp.data);
        setData(resp.data?.data);
        dispatch(fetchSuccess());
        closeModal();
        setShowAddButton(false);
        toast.success('Game tutorial added successfully.');
        // fetchEventsAfterBan();
      })
      .catch(error => {
        // console.log(error.response);
        toast.error('Sorry, something went wrong. Please try again later.');
        dispatch(fetchError());
      });
  };

  const handleBannerImgAdd = (e, i) => {
    // console.log(e);
    // console.log(i);
    // return;
    const image = e.target.files[0];
    const imgLink = URL.createObjectURL(image);
    const newData = [...uploadBanners].map((inp, idx) => {
      if (idx === i) {
        return {
          ...inp,
          banner: image,
          banner_url: imgLink,
        };
      }
      return inp;
    });
    setUploadBanners(newData);
  };

  const handleBannerImgRemove = index => {
    let data = [...uploadBanners];
    data.splice(index, 1);
    setUploadBanners(data);
  };

  const handleBannerAddMoreBtn = () => {
    const newData = { banner: '', banner_url: '' };
    const newD = [...uploadBanners, newData];
    setUploadBanners(newD);
  };

  const handleActionsIcon = (e, i) => {
    let data = [...iconDescription];
    data[i][e.target.name] = e.target.value;
    setIconDescription(data);
  };

  const getIconImageName = url => {
    if (url) {
      const withoutSlash = url.lastIndexOf('/');
      const resultWithoutSlash = url.substring(withoutSlash + 1);

      const withoutDot = resultWithoutSlash.indexOf('.');
      const resultWithoutDot = resultWithoutSlash.substring(0, withoutDot);
      const actualName = resultWithoutDot.replace(/\+/g, ' ');
      if (actualName === 'Double Tao') {
        // console.log('Double Tap');
        return 'Double Tap';
      } else {
        // console.log(actualName);
        return actualName;
      }
    }
  };

  const handleTutorialDescriptionRemove = index => {
    let data = [...iconDescription];
    data.splice(index, 1);
    setIconDescription(data);
  };

  const handleTutorialAddBtn = () => {
    // const oldData = [...iconDescription];
    const newData = { icon: '', description: '', icon_url: '', title: '' };
    const newD = [...iconDescription, newData];
    setIconDescription(newD);
  };

  return (
    <Dialog open={open} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>
        {selectedTutorial ? 'Edit Tutorial' : 'Add New Tutorial'}
      </DialogTitle>

      <DialogContent dividers>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            fullWidth
            variant='outlined'
            label='Game Name'
            value={gameName}
            onChange={e => setGameName(e.target.value)}
            disabled
          />
        </Box>

        {/* Tutorial Description */}
        <Box mb={{ xs: 6, md: 5 }}>
          <TextField
            fullWidth
            multiline
            minRows={3}
            variant='outlined'
            label='Tutorial Description'
            value={tutorialDescription}
            onChange={e => setTutorialDescription(e.target.value)}
          />
        </Box>

        {/* Video URL */}
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            fullWidth
            variant='outlined'
            label='Video URL'
            name='title'
            value={videoUrl}
            onChange={e => setVideoUrl(e.target.value)}
          />
        </Box>

        {/* banners  */}
        <CmtList
          data={uploadBanners}
          renderRow={(item, index) => (
            <GridContainer style={{ marginBottom: 2 }} key={index}>
              <Grid item xs={12} sm={10}>
                <Box mr={{ xs: 0, md: 5 }} mb={{ xs: 3, md: 0 }} className='pointer'>
                  <input
                    type='file'
                    accept='image/jpeg, image/png'
                    onChange={e => handleBannerImgAdd(e, index)}
                    className='tutorial-banner-input'
                    style={{
                      backgroundImage: item.banner_url !== '' ? `url('${item.banner_url}')` : '',
                    }}
                  />
                </Box>
                <span style={{ margin: '0 8px' }}>Banners</span>
              </Grid>
              {index > 0 && (
                <Grid container item xs={1} sm={1} justifyContent='center' alignItems='center'>
                  <IconButton color='inherit' onClick={() => handleBannerImgRemove(index)} size='small'>
                    <CancelIcon />
                  </IconButton>
                </Grid>
              )}
            </GridContainer>
          )}
        />

        <Box mb={{ xs: 6, md: 5 }} display='inline-flex' alignItems='center' className='pointer'>
          <Button color='primary' onClick={handleBannerAddMoreBtn} startIcon={<AddCircleOutlineIcon />}>
            Add More
          </Button>
        </Box>

        {/* Icons */}
        <CmtList
          data={iconDescription}
          renderRow={(item, index) => (
            <GridContainer style={{ marginBottom: 2 }} key={index}>
              <Grid item xs={12} sm={3}>
                <FormControl size='small' fullWidth variant='outlined' style={{ marginBottom: 0 }}>
                  <InputLabel id='demo-simple-select-label'>Icon</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='tutorial-icon-select'
                    label='Game Category'
                    name='icon'
                    onChange={e => handleActionsIcon(e, index)}
                    value={item.icon}
                  >
                    {iconLists.map((url, i) => (
                      <MenuItem value={url} key={i}>
                        <img src={`${url}`} alt='' width={25} style={{ marginRight: 10 }} />{' '}
                        <span className='text-capitalize'>{getIconImageName(url)}</span>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={8}>
                <AppTextInput
                  fullWidth
                  variant='outlined'
                  label='Title'
                  name='title'
                  onChange={e => handleActionsIcon(e, index)}
                  value={item.title}
                  size='medium'
                />
              </Grid>
              <Grid item xs={12} sm={11}>
                <TextField
                  fullWidth
                  variant='outlined'
                  label='Description'
                  name='description'
                  onChange={e => handleActionsIcon(e, index)}
                  minRows={2}
                  value={item.description}
                />
              </Grid>
              {index > 0 && (
                <Grid container item xs={1} sm={1} justifyContent='center' alignItems='center'>
                  <IconButton color='inherit' onClick={() => handleTutorialDescriptionRemove(index)} size='small'>
                    <CancelIcon />
                  </IconButton>
                </Grid>
              )}
              <Grid item xs={12} sm={12}>
                <p style={{ height: -10 }}></p>
              </Grid>
            </GridContainer>
          )}
        />
        <Box mb={{ xs: 6, md: 5 }} display='inline-flex' alignItems='center' className='pointer'>
          <Button color='primary' onClick={handleTutorialAddBtn} startIcon={<AddCircleOutlineIcon />}>
            Add More
          </Button>
        </Box>
      </DialogContent>

      <DialogActions>
        <Box display='flex' justifyContent='flex-end' mb={4}>
          <Button onClick={closeModal}>Cancel</Button>
          <Box ml={2}>
            <Button variant='contained' color='primary' onClick={handleSave}>
              Save
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AddEditTutorial;
