import { lighten, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(5),
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  container: {
    maxHeight: '60vh',
    background: '#fff',
  },
  table: {
    minWidth: 750,
  },
  dialogRoot: {
    position: 'relative',
    '& .MuiDialog-paperWidthSm': {
      minWidth: '500px',
    },
  },
  dialogRootUser: {
    position: 'relative',
    '& .MuiDialog-paperWidthSm': {
      minWidth: '1000px',
    },
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  total: {
    fontWeight: 'bolder',
    fontSize: 16,
  },
  flexCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  dialogTitleRoot: {
    fontWeight: 700,
  },
}));

export default useStyles;
