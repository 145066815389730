import React, { memo, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import useStyles from '../index.style';
import ClearIcon from '@material-ui/icons/Clear';
import GridContainer from '@jumbo/components/GridContainer';
import { toast } from 'react-hot-toast';
import { axiosInstance } from 'utils/axios';
import { useDispatch } from 'react-redux';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';

const DRSummeryComponent = ({ open, closeModal, selectedUser, showActions, setData, fetchUsersAfterCancel }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user_name, ban_information, deletion_request_date, delete_request_otp, deletion_request } = selectedUser;

  const [codeSentStatus, setCodeSentStatus] = useState('pending');

  useEffect(() => {
    if (deletion_request === 2) {
      setCodeSentStatus('done');
    }
  }, [deletion_request]);

  const handleCodeSent = user => {
    dispatch(fetchStart());
    axiosInstance
      .patch(`/admin/user/delete-request/otp-sent/${user._id}`)
      .then(resp => {
        // console.log(resp?.data);
        dispatch(fetchSuccess());
        closeModal();
        toast.success('Status updated successfully.');

        setData(prevData => {
          return {
            ...prevData,
            data: prevData?.data?.map(user => {
              if (user._id === resp?.data?.data?.user?._id) {
                return {
                  ...user,
                  deletion_request: resp?.data?.data?.user?.deletion_request,
                };
              }
              return { ...user };
            }),
          };
        });
      })
      .catch(error => {
        // console.log(error.response);
        dispatch(fetchError());
        // toast.error('Sorry, something went wrong. Please try again later.');
        toast.error(error?.response?.data?.error?.message);
      });
  };

  const handleCancelRequest = user => {
    dispatch(fetchStart());
    axiosInstance
      .patch(`/admin/user/delete-request/cancel/${user._id}`)
      .then(resp => {
        // console.log(resp?.data);
        fetchUsersAfterCancel();
        dispatch(fetchSuccess());
        closeModal();
        toast.success('Request cancelled successfully.');
      })
      .catch(error => {
        // console.log(error.response);
        dispatch(fetchError());
        // toast.error('Sorry, something went wrong. Please try again later.');
        toast.error(error?.response?.data?.error?.message);
      });
  };

  return (
    <Dialog open={open} onClose={closeModal} className={classes.dialogRoot}>
      <Box className={classes.modalHead}>
        <Typography variant='h4' className={classes.titleHead}>
          Deletion Summery
        </Typography>
        <IconButton onClick={closeModal}>
          <ClearIcon />
        </IconButton>
      </Box>
      <Box className={classes.userInfoRoot}>
        <Box width='100%' mr={3} alignItems={{ xs: 'center', md: 'start' }} flexDirection={{ xs: 'column', md: 'row' }}>
          <Box>
            <Typography className={classes.titleRoot}>Name: {user_name}</Typography>
            <Typography className={classes.titleRoot}>Reason: {ban_information}</Typography>
            <Typography className={classes.titleRoot}>
              Status: {deletion_request === 1 && 'Applied for deletion'} {deletion_request === 2 && 'Code Sent'}
            </Typography>
            <Typography className={classes.titleRoot}>Date: {deletion_request_date}</Typography>
            <Box sx={{ minWidth: 120 }} className={classes.codeStatus}>
              <Typography className={classes.titleRoot} style={{ marginRight: 15, minWidth: 150 }}>
                Code Sent:{' '}
              </Typography>
              <FormControl size='small' fullWidth variant='outlined'>
                <InputLabel id='demo-simple-select-label'>Status</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={codeSentStatus}
                  label='Status'
                  onChange={e => setCodeSentStatus(e.target.value)}
                  disabled={!showActions}
                >
                  <MenuItem value='pending'>Pending</MenuItem>
                  <MenuItem value='done'>Done</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
          <GridContainer style={{ marginBottom: 15 }} />
          <Typography className={classes.titleRoot}>Generated Code: {delete_request_otp}</Typography>
          <GridContainer style={{ marginBottom: 30 }} />
          {showActions && (
            <Box display='flex' justifyContent='space-between' mb={4}>
              <Button onClick={() => handleCancelRequest(selectedUser)} variant='outlined' color='primary'>
                Cancel Request
              </Button>
              <Box ml={2}>
                <Button
                  disabled={codeSentStatus === 'pending'}
                  variant='contained'
                  color='primary'
                  onClick={() => handleCodeSent(selectedUser)}
                >
                  Save
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Dialog>
  );
};

export default memo(DRSummeryComponent);
