import { Button, Popper } from '@material-ui/core';
import React from 'react';
import { CloseOutlined } from '@material-ui/icons';
import moment from 'moment';
import { DatePicker } from '@mantine/dates';

const DatePopover = ({
  id,
  open,
  showPopover,
  setShowPopover,
  dateRange,
  setDateRange,
  handleApplyDateRange,
  placement = 'left-start',
  ...rest
}) => {
  return (
    <Popper placement={placement} id={id} open={open} anchorEl={showPopover} style={{ zIndex: 999 }}>
      <div className='anayltic-popover'>
        <div className='flex justify-end pointer'>
          <CloseOutlined style={{ fill: '#000', width: 30 }} onClick={() => setShowPopover(null)} />
        </div>
        <div className='flex justify-between head'>
          <span>
            {dateRange && dateRange[0] ? (
              moment(dateRange[0]).format('DD MMM, YY')
            ) : (
              <div style={{ color: '#ced4da' }}>start date</div>
            )}
          </span>
          <span>
            {dateRange && dateRange[1] ? (
              moment(dateRange[1]).format('DD MMM, YY')
            ) : (
              <div style={{ color: '#ced4da' }}>end date</div>
            )}
          </span>
        </div>

        <DatePicker value={dateRange} onChange={setDateRange} weekendDays={[]} firstDayOfWeek={0} {...rest} />

        <Button onClick={handleApplyDateRange} variant='contained' color='primary' className='save'>
          Apply
        </Button>
      </div>
    </Popper>
  );
};

export default DatePopover;
