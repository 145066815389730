import React, { useEffect, useState } from 'react';
import useStyles from './index.style';
import { useDispatch } from 'react-redux';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { axiosInstance } from 'utils/axios';
import toast, { Toaster } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import { Delete, Edit, MoreHoriz } from '@material-ui/icons';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import NoRecordFound from 'components/NoRecordFound.Component';
import ConfirmDialog from '@jumbo/components/Common/ConfirmDialog';
import AddEditTutorial from './components/AddEditTutorial.Component';

const GameTutorial = () => {
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();

  const [data, setData] = useState(null);
  const [gameDetails, setGameDetails] = useState(null);
  const [selectedTutorial, setSelectedTutorial] = useState(null);
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [showAddButton, setShowAddButton] = useState(false);

  useEffect(() => {
    fetchData(id);
  }, []);

  const fetchData = id => {
    dispatch(fetchStart());

    axiosInstance
      .get(`/admin/game/training/${id}`)
      .then(resp => {
        // console.log(resp.data);
        setGameDetails(resp.data.game);
        if (resp?.data?.success) {
          setData(resp?.data?.data);
        } else {
          setShowAddButton(true);
          setData(null);
        }
        dispatch(fetchSuccess());
      })
      .catch(error => {
        // console.log(error.response);
        toast.error('Sorry, something went wrong. Please try again later.');
      });
  };

  const handleEditTutorial = tutorial => {
    // console.log(tutorial);
    setShowAddEditModal(true);
    setSelectedTutorial(tutorial);
  };

  const handleDeleteTutorial = tutorial => {
    // console.log(tutorial);
    setShowDeleteModal(true);
    setSelectedTutorial(tutorial);
  };

  const resetAllModal = () => {
    setSelectedTutorial(null);
    setShowAddEditModal(false);
    setShowDeleteModal(false);
  };

  const handleConfirmDelete = () => {
    dispatch(fetchStart());

    axiosInstance
      .delete(`/admin/game/training/delete/${selectedTutorial?._id}`)
      .then(resp => {
        // console.log(resp.data);
        dispatch(fetchSuccess());
        toast.success('Game tutorial deleted successfully.');
        setData(null);
        setShowAddButton(true);
        resetAllModal();
      })
      .catch(error => {
        // console.log(error.response);
        toast.error('Sorry, something went wrong. Please try again later.');
        dispatch(fetchError());
      });
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar className={classes.root} display='flex' style={{ justifyContent: 'space-between' }}>
          <Box display='flex' alignItems='center'>
            <Typography className={classes.title} variant='h4' id='tableTitle' component='div'>
              {gameDetails?.game_title} Tutorial
            </Typography>
            {showAddButton && (
              <Button
                type='submit'
                style={{ marginLeft: 10 }}
                variant='contained'
                color='primary'
                onClick={() => setShowAddEditModal(true)}
              >
                Add new tutorial
              </Button>
            )}
          </Box>
        </Toolbar>

        <TableContainer className={classes.container}>
          <Table stickyHeader>
            <GameTutorialTableHead />
            <TableBody>
              {data ? (
                <GameTutorialTableBody
                  tutorial={data}
                  handleEditTutorial={handleEditTutorial}
                  handleDeleteTutorial={handleDeleteTutorial}
                />
              ) : (
                <TableRow style={{ height: 200 }}>
                  <TableCell colSpan={11} rowSpan={7}>
                    <NoRecordFound>There are no records found.</NoRecordFound>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {showAddEditModal && (
          <AddEditTutorial
            open={showAddEditModal}
            selectedTutorial={selectedTutorial}
            closeModal={resetAllModal}
            gameDetails={gameDetails}
            setData={setData}
            setShowAddButton={setShowAddButton}
          />
        )}

        {showDeleteModal && (
          <ConfirmDialog
            open={showDeleteModal}
            title={`Confirm Delete`}
            content='Are you sure to delete this tutorial?'
            onClose={resetAllModal}
            onConfirm={handleConfirmDelete}
          />
        )}
      </Paper>

      <Toaster position='top-right' toastOptions={{ duration: 3000 }} />
    </div>
  );
};

export default GameTutorial;

const GameTutorialTableBody = React.memo(({ tutorial, handleEditTutorial, handleDeleteTutorial }) => {
  const onTutorialMenuClick = menu => {
    if (menu.action === 'edit') {
      handleEditTutorial(tutorial);
    } else if (menu.action === 'delete') {
      handleDeleteTutorial(tutorial);
    }
  };

  const getTutorialActions = () => {
    const actions = [
      { action: 'edit', label: 'Edit', icon: <Edit /> },
      { action: 'delete', label: 'Delete', icon: <Delete /> },
    ];
    return actions;
  };

  const categoryActions = getTutorialActions(tutorial);

  return (
    <TableRow>
      <TableCell align='left'>{tutorial?.game_title}</TableCell>
      <TableCell align='center'>
        {tutorial.video_urls[0] === '' ? (
          <span className='text-danger'>Not Added</span>
        ) : (
          <span className='text-success'>Added</span>
        )}
      </TableCell>
      <TableCell align='center'>Added</TableCell>
      <TableCell align='center' onClick={e => e.stopPropagation()}>
        <CmtDropdownMenu items={categoryActions} onItemClick={onTutorialMenuClick} TriggerComponent={<MoreHoriz />} />
      </TableCell>
    </TableRow>
  );
});

const GameTutorialTableHead = () => {
  const tableHeader = ['Name', 'Video Tutorial Status', 'Banner Status', 'Actions'];

  return (
    <TableHead>
      <TableRow>
        {tableHeader?.map((d, i) => (
          <TableCell key={i} align={i === 0 ? 'left' : 'center'} padding={'normal'}>
            {d}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
