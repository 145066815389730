import {GET_PRIZES} from "@jumbo/constants/ActionTypes";

const INIT_STATE = {
  all_prizes: [],
  selected_prize: null
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PRIZES: {
      return {
        ...state,
        all_prizes: action.payload
      };
    }
    default:
      return state;
  }
};
