import React, { useEffect, useState } from 'react';
import useStyles from '../index.style';
import { Box, Button, Dialog, DialogContent, IconButton, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import GridContainer from '@jumbo/components/GridContainer';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import CmtAvatar from '@coremat/CmtAvatar';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import { acceptFileType, fileSize } from 'common/functions';
import { axiosInstance } from 'utils/axios';
import toast from 'react-hot-toast';

const ULEdit = ({ open, closeModal, selectedUser, setData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [userName, setUserName] = useState('');
  const [profilePicture, setProfilePicture] = useState('');
  const [newProfilePicture, setNewProfilePicture] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(true);

  useEffect(() => {
    if (newProfilePicture) {
      setSubmitDisabled(false);
      return;
    }

    if (userName !== selectedUser?.user_name) {
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
  }, [userName, newProfilePicture]);

  useEffect(() => {
    // console.log(selectedUser);
    if (selectedUser) {
      setUserName(selectedUser?.user_name);
      setProfilePicture(selectedUser?.profile_picture);
    }
  }, [selectedUser]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setProfilePicture(URL.createObjectURL(acceptedFiles[0]));
      setNewProfilePicture(acceptedFiles[0]);
    },
  });

  const handleUpdate = () => {
    if (!userName) {
      toast.error('Please enter username.');
      return;
    }

    if (userName.length > 30) {
      toast.error('Username must be less than 30 characters');
      return;
    }

    const imgTypes = ['image/png', 'image/jpg', 'image/jpeg'];
    if (newProfilePicture) {
      if (!acceptFileType(newProfilePicture, imgTypes)) {
        toast.error('Profile picture must be png or jpg type.');
        return;
      }
    }

    const size = fileSize(newProfilePicture);
    if (size > 301) {
      toast.error('Profile picture can not be grater than 300 kb.');
      return;
    }

    const postData = {
      user_name: userName,
    };

    dispatch(fetchStart());
    let data = new FormData();
    data.append('data', JSON.stringify(postData));

    if (newProfilePicture) {
      data.append('profile_picture', newProfilePicture, newProfilePicture.name);
    }

    axiosInstance
      .put(`/admin/user/update/${selectedUser?._id}`, data)
      .then(resp => {
        // console.log(resp.data);
        toast.success('User updated successfully.');
        dispatch(fetchSuccess());
        closeModal();

        setData(prevData => {
          return {
            ...prevData,
            data: prevData?.data?.map(user => {
              if (user._id === resp?.data?.data?._id) {
                return {
                  ...user,
                  profile_picture: resp?.data?.data?.photo_url,
                  user_name: resp?.data?.data?.user_name,
                };
              }
              return { ...user };
            }),
          };
        });
      })
      .catch(error => {
        // console.log(error.response);
        toast.error('Sorry, something went wrong. Please try again later.');
        dispatch(fetchError());
      });
  };

  return (
    <Dialog open={open} onClose={closeModal} className={classes.dialogRoot}>
      <Box className={classes.modalHead}>
        <Typography className={classes.titleHead}>Edit User Details</Typography>
        <IconButton onClick={closeModal}>
          <ClearIcon />
        </IconButton>
      </Box>
      <DialogContent dividers>
        <Box display='flex' alignItems={{ xs: 'center', md: 'center' }} flexDirection={{ xs: 'column', md: 'row' }}>
          <Box display='flex' flexDirection={{ xs: 'column', md: 'row' }} alignItems='center' mb={{ xs: 6, md: 5 }}>
            <Box
              {...getRootProps()}
              mr={{ xs: 0, md: 5 }}
              mb={{ xs: 1, md: 0 }}
              className='pointer'
              style={{ position: 'relative' }}
            >
              <input {...getInputProps()} />
              <CmtAvatar size={100} src={profilePicture} />
              <PhotoCameraIcon className={classes.imgIcon} />
            </Box>
          </Box>
          <Box mb={{ xs: 6, md: 5 }} style={{ width: '100%' }}>
            <AppTextInput
              fullWidth
              variant='outlined'
              label='User name'
              value={userName}
              onChange={e => setUserName(e.target.value)}
            />
          </Box>
        </Box>
        <GridContainer style={{ marginBottom: 12 }} />
        <Box display='flex' justifyContent='flex-end' mb={4}>
          <Box ml={2}>
            <Button disabled={submitDisabled} variant='contained' color='primary' onClick={handleUpdate}>
              Update
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ULEdit;
