import { axiosInstance } from 'utils/axios';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import {
  RESET_ANALYTIC_DATA,
  SET_DYNAMIC_LINKS_DATA,
  SET_DYNAMIC_LINKS_PD,
  SET_DYNAMIC_LINKS_TP,
  SET_EVENT_DATA,
  SET_EVENT_PAGINATION_DISABLED,
  SET_EVENT_TOTAL_PAGES,
  SET_PAID_USER_ALL,
  SET_PAID_USER_REGISTERED,
  SET_TOTAL_DATA,
  SET_TOTAL_PAGINATION_DISABLED,
  SET_TOTAL_TOTAL_PAGES,
} from '@jumbo/constants/ActionTypes';
import toast from 'react-hot-toast';

export const resetAnalyticData = () => {
  return async dispatch => {
    const data = {
      totalGames: {
        data: null,
        paginationDisabled: false,
        totalPages: 0,
        currentPage: 1,
      },
      paidUsers: {
        paidUserAll: null,
        paidUserAllDate: [null, null],
        isFilterAppliedAll: false,
        //
        paidUserRegistered: null,
        paidUserRegisteredDate: [null, null],
        isFilterAppliedRegistered: false,
      },
      events: {
        data: null,
        paginationDisabled: false,
        totalPages: 0,
        currentPage: 1,
      },
      dynamicLinks: {
        dynamicLinksData: null, // total data
        dynamicLinksPD: false, //pagination Disabled
        dynamicLinksTP: 0, //total pages
        dynamicLinksCP: 1, // current page
        dynamicLinksDR: [null, null], // date range
        dynamicLinksDRA: false, // date range applied
      },
    };
    dispatch({ type: RESET_ANALYTIC_DATA, payload: data });
  };
};

export const getPaidUserAllTimeData = (startDate, endDate, callbackFun) => {
  return async dispatch => {
    let start = startDate || '';
    let end = endDate || '';

    dispatch(fetchStart());
    await axiosInstance
      .get(`/admin/analytic/retention`, {
        params: {
          fromDate: start,
          toDate: end,
        },
      })
      .then(resp => {
        // console.log(data?.data?.data);
        dispatch(fetchSuccess());
        dispatch({ type: SET_PAID_USER_ALL, payload: resp?.data?.data });
        if (callbackFun) callbackFun(resp.data);
      })
      .catch(error => {
        dispatch(fetchError());
        toast.error('There was something issue in responding server');
      });
  };
};

export const getPaidUserRegisteredData = (startDate, endDate, callbackFun) => {
  return async dispatch => {
    let start = startDate || '';
    let end = endDate || '';

    dispatch(fetchStart());
    await axiosInstance
      .get(`/admin/analytic/retention-date-range`, {
        params: {
          fromDate: start,
          toDate: end,
        },
      })
      .then(resp => {
        // console.log(data?.data?.data);
        const stateData = { ...resp?.data?.data };
        const retention_rate = (resp?.data?.data?.recurring_paid_user / resp?.data?.data?.paid_user) * 100;
        const conversion_rate = (resp?.data?.data?.paid_user / resp?.data?.data?.total_user) * 100;
        stateData.retention_rate = retention_rate ? retention_rate.toFixed(2) : 0;
        stateData.conversion_rate = conversion_rate ? conversion_rate.toFixed(2) : 0;

        // console.log(stateData);
        dispatch(fetchSuccess());
        dispatch({ type: SET_PAID_USER_REGISTERED, payload: stateData });
        if (callbackFun) callbackFun(resp.data);
      })
      .catch(error => {
        dispatch(fetchError());
        toast.error('There was something issue in responding server');
      });
  };
};

export const getDynamicLinks = (pageSize, startDate, endDate, page = 1, callbackFun) => {
  return async dispatch => {
    let start = startDate || '';
    let end = endDate || '';

    dispatch({ type: SET_DYNAMIC_LINKS_PD, payload: true });

    dispatch(fetchStart());
    await axiosInstance
      .get(`/admin/analytic/dynamic-link/`, {
        params: {
          size: pageSize,
          fromDate: start,
          toDate: end,
          page: page,
        },
      })
      .then(resp => {
        // console.log(resp?.data);
        dispatch(fetchSuccess());
        dispatch({ type: SET_DYNAMIC_LINKS_DATA, payload: resp?.data });
        dispatch({ type: SET_DYNAMIC_LINKS_PD, payload: false });
        dispatch({ type: SET_DYNAMIC_LINKS_TP, payload: Math.ceil(resp?.data?.total_count / pageSize) });
        if (callbackFun) callbackFun(resp.data);
      })
      .catch(error => {
        dispatch(fetchError());
        toast.error('There was something issue in responding server');
      });
  };
};

export const getEventData = (pageSize, page = 1, callbackFun) => {
  return async dispatch => {
    dispatch({ type: SET_EVENT_PAGINATION_DISABLED, payload: true });

    dispatch(fetchStart());

    await axiosInstance
      .get('/admin/analytic/event-analytic', {
        params: { page: page, size: pageSize },
      })
      .then(resp => {
        // console.log(resp?.data);
        dispatch(fetchSuccess());
        dispatch({ type: SET_EVENT_DATA, payload: resp?.data });
        dispatch({ type: SET_EVENT_PAGINATION_DISABLED, payload: false });
        dispatch({ type: SET_EVENT_TOTAL_PAGES, payload: Math.ceil(resp?.data?.total_count / pageSize) });
        if (callbackFun) callbackFun(resp.data);
      })
      .catch(error => {
        dispatch(fetchError());
        toast.error('There was something issue in responding server');
      });
  };
};

export const getTotalData = (pageSize, page = 1, callbackFun) => {
  return async dispatch => {
    dispatch({ type: SET_TOTAL_PAGINATION_DISABLED, payload: true });

    dispatch(fetchStart());

    await axiosInstance
      .get('/admin/analytic/game-analytic', {
        params: { page: page, size: pageSize },
      })
      .then(resp => {
        // console.log(resp?.data);
        dispatch(fetchSuccess());
        dispatch({ type: SET_TOTAL_DATA, payload: resp?.data });
        dispatch({ type: SET_TOTAL_PAGINATION_DISABLED, payload: false });
        dispatch({ type: SET_TOTAL_TOTAL_PAGES, payload: Math.ceil(resp?.data?.total_count / pageSize) });
        if (callbackFun) callbackFun(resp.data);
      })
      .catch(error => {
        dispatch(fetchError());
        toast.error('There was something issue in responding server');
      });
  };
};
