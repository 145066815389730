import axios from 'axios';

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token') ?? '';

// Add a request interceptor
axiosInstance.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    // console.log('i ran before request');
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axiosInstance.interceptors.response.use(
  response => response,
  error => {
    // if (error.response && error.response.status === 401) {
    //   // Handle 401 error
    //   console.log('Unauthorized. Logging out...');
    //   // Perform logout action here, such as clearing user session or redirecting to a logout page
    // }
    // console.log(error.response);
    return Promise.reject(error);
  }
);
