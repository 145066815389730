import React, { useEffect } from 'react';
import useStyles from '../index.style';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import moment from 'moment';
import Axios from 'axios';
import DateTimePickerInput from 'components/DateTimePickerInput.Component';

const OPCAddEditModal = ({
  open,
  handleCloseModal,
  eventsAndGoods,
  getAllReferrals,
  setCurrentPage,
  editData,
  setDiscountData,
}) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [offerName, setOfferName] = useState('');
  const [offerType, setOfferType] = useState('discount');
  const [point, setPoint] = useState('');
  const [eventId, setEventId] = useState('');
  const [percentage, setPercentage] = useState('');
  const [physicalGoodId, setPhysicalGoodId] = useState('');
  const [physicalGoodName, setPhysicalGoodName] = useState('');
  const [claimStartDate, setClaimStartDate] = useState('');
  const [claimEndDate, setClaimEndDate] = useState('');
  const [useEndDate, setUseEndDate] = useState('');

  useEffect(() => {
    if (editData) {
      // console.log(editData);
      setOfferName(editData?.title);
      setOfferType(editData?.category === 'discount_in_event' ? 'discount' : 'physical');
      setPoint(editData?.required_point?.toString());

      // const cst = moment(editData?.claim_start_date).format('YYYY-MM-DDTHH:mm');
      // const cen = moment(editData?.claim_end_date).format('YYYY-MM-DDTHH:mm');

      setClaimStartDate(new Date(editData?.claim_start_date));
      setClaimEndDate(new Date(editData?.claim_end_date));

      if (editData?.category === 'physical_good') {
        setPhysicalGoodId(editData?.gift?.gift_type);
        setPhysicalGoodName(editData?.gift?.gift_id);
      }

      if (editData?.category === 'discount_in_event') {
        // const uen = moment(editData?.use_end_date).format('YYYY-MM-DDTHH:mm');
        // setUseEndDate(uen);
        setUseEndDate(new Date(editData?.use_end_date));
        setEventId(editData?.gift?.gift_id);
        setPercentage(editData?.discount_percentage);
      }
    }
  }, [editData]);

  const handleSave = () => {
    const eventDetails = eventsAndGoods?.discount_in_event?.find(e => e?._id === eventId);
    const esd = moment(eventDetails?.prize_event_start_time).format('YYYY-MM-DDTHH:mm');
    const eed = moment(eventDetails?.prize_event_end_time).format('YYYY-MM-DDTHH:mm');

    const csd = moment(claimStartDate).format('YYYY-MM-DDTHH:mm');
    const ced = moment(claimEndDate).format('YYYY-MM-DDTHH:mm');
    const ued = moment(useEndDate).format('YYYY-MM-DDTHH:mm');

    const checkClaimStartDateEvent = moment(csd).isSameOrAfter(esd);
    const checkClaimEndDateEvent = moment(ced).isBefore(eed);
    const checkUseEndDateEvent = moment(ued).isSameOrBefore(eed);

    const checkClaimEndDate = moment(ced).isAfter(csd);
    const checkUseEndDate = moment(ued).isAfter(ced);

    if (!offerName) {
      dispatch(fetchError('Please enter offer name.'));
      return;
    }

    if (!offerType) {
      dispatch(fetchError('Please select offer type.'));
      return;
    }

    if (offerType === 'discount' && !eventId) {
      dispatch(fetchError('Please select prize event.'));
      return;
    }

    if (offerType === 'discount' && !percentage) {
      dispatch(fetchError('Please enter percentage.'));
      return;
    }

    if (offerType === 'discount' && percentage !== '' && (percentage < 0 || percentage > 100)) {
      dispatch(fetchError('Percentage must be in between 0 to 100.'));
      return;
    }

    if (offerType === 'physical' && !physicalGoodId) {
      dispatch(fetchError('Please select physical good category.'));
      return;
    }

    if (offerType === 'physical' && !physicalGoodName) {
      dispatch(fetchError('Please enter physical good name.'));
      return;
    }

    if (!point) {
      dispatch(fetchError('Please enter required point.'));
      return;
    }

    if (point < 0) {
      dispatch(fetchError('Required point can not be less than zero.'));
      return;
    }

    if (!claimStartDate) {
      dispatch(fetchError('Please select claim start date.'));
      return;
    }

    if (offerType === 'discount' && eventId !== 'any' && !checkClaimStartDateEvent) {
      dispatch(fetchError('Claim start date must be equal or greater than event start date.'));
      return;
    }

    if (!claimEndDate) {
      dispatch(fetchError('Please select claim end date.'));
      return;
    }

    if (offerType === 'discount' && eventId !== 'any' && !checkClaimEndDateEvent) {
      dispatch(fetchError('Claim end date must be less than event end date.'));
      return;
    }

    if (!checkClaimEndDate) {
      dispatch(fetchError('Claim start date must be less than claim end date.'));
      return;
    }

    if (offerType === 'discount' && !useEndDate) {
      dispatch(fetchError('Please select use end date.'));
      return;
    }

    if (offerType === 'discount' && !checkUseEndDate) {
      dispatch(fetchError('Claim end date must be less than use end date.'));
      return;
    }

    if (offerType === 'discount' && eventId !== 'any' && !checkUseEndDateEvent) {
      dispatch(fetchError('Use end date must be equal or less than event end date.'));
      return;
    }

    const postData = {
      title: offerName,
      category: offerType !== 'discount' ? 'physical_good' : 'discount_in_event',
      type: offerType !== 'discount' ? physicalGoodId : 'prize_event',
      required_point: point,

      claim_start_date: moment(claimStartDate).format(),
      claim_end_date: moment(claimEndDate).format(),
      gift: {
        gift_id: offerType !== 'discount' ? physicalGoodName : eventId,
        gift_type: offerType !== 'discount' ? physicalGoodId : 'prize_event',
      },
      discount_percentage: percentage,
    };

    if (offerType === 'discount') {
      postData.discount_percentage = percentage;
      postData.use_end_date = moment(useEndDate).format();
    }

    // console.log(postData);
    // return;

    if (editData) {
      // console.log(editData._id, postData);
      putRef(editData._id, postData, () => {
        handleCloseModal();
      });
    } else {
      postRef(postData, () => {
        handleCloseModal();
        getAllReferrals();
        setCurrentPage(1);
      });
    }
  };

  const postRef = (data, callback) => {
    dispatch(fetchStart());
    Axios.post(`${process.env.REACT_APP_BASE_URL}/admin/referral/create-offer`, data)
      .then(resp => {
        // console.log(resp?.data);
        dispatch(fetchSuccess('Offer added successfully.'));
        if (callback && typeof callback === 'function') callback();
      })
      .catch(error => {
        // console.log(error.response);
        dispatch(fetchError('Sorry, something went wrong please try again later.'));
      });
  };

  const putRef = (id, data, callback) => {
    dispatch(fetchStart());
    Axios.put(`${process.env.REACT_APP_BASE_URL}/admin/referral/update-offer/${id}`, data)
      .then(resp => {
        // console.log(resp?.data);
        dispatch(fetchSuccess('Offer updated successfully.'));
        if (callback && typeof callback === 'function') callback();

        const checkDateIsBefore = moment(resp?.data?.data?.claim_end_date).isBefore(new Date());
        // console.log(checkDateIsBefore);
        if (checkDateIsBefore) {
          getAllReferrals();
          setCurrentPage(1);
        } else {
          setDiscountData(prevData => {
            return {
              ...prevData,
              data: prevData.data.map(d => {
                if (d._id === resp.data.data._id) {
                  return resp.data.data;
                }
                return d;
              }),
            };
          });
        }
      })
      .catch(error => {
        // console.log(error.response);
        dispatch(fetchError('Sorry, something went wrong please try again later.'));
      });
  };

  return (
    <Dialog open={open} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>{editData ? 'Edit' : 'Create'} Referral Offer</DialogTitle>
      <DialogContent dividers>
        <Box mb={{ xs: 4, md: 3 }}>
          <FormLabel>Offer Name</FormLabel>
          <TextField
            fullWidth
            multiline
            minRows={3}
            variant='outlined'
            // label='Offer Name'
            value={offerName}
            onChange={e => setOfferName(e.target.value)}
          />
        </Box>

        <Box mb={{ xs: 4, md: 3 }}>
          <Typography className={classes.title} variant='h4' id='tableTitle' component='div'>
            Offer Type
          </Typography>
          <FormControl component='fieldset'>
            <RadioGroup row value={offerType} onChange={e => setOfferType(e.target.value)}>
              <FormControlLabel value='discount' control={<Radio color='primary' />} label='Discount Percent' />
              <FormControlLabel value='physical' control={<Radio color='primary' />} label='Physical Goods' />
            </RadioGroup>
          </FormControl>
        </Box>

        {offerType === 'discount' && (
          <>
            <Box mb={{ xs: 4, md: 3 }}>
              <FormLabel>Prize Event</FormLabel>
              <FormControl size='small' fullWidth variant='outlined'>
                {/* <InputLabel id='p_e_123'>Prize Event</InputLabel> */}
                <Select
                  labelId='p_e_123'
                  id='demo-simple-select'
                  value={eventId}
                  label='Select Game'
                  onChange={e => setEventId(e.target.value)}
                >
                  <MenuItem value='any'>Any</MenuItem>
                  {[...eventsAndGoods?.discount_in_event]?.map(event => (
                    <MenuItem key={event._id} value={event._id}>
                      {event.prize_event_title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box mb={{ xs: 4, md: 3 }}>
              <FormLabel>Percentage (%)</FormLabel>
              <AppTextInput
                fullWidth
                variant='outlined'
                // label='Percentage (%)'
                value={percentage}
                onChange={e => setPercentage(e.target.value)}
                type={'number'}
                InputProps={{ inputProps: { min: 0, max: 100 } }}
              />
            </Box>
          </>
        )}

        {offerType === 'physical' && (
          <>
            <Box mb={{ xs: 4, md: 3 }}>
              <FormLabel>Physical Good Category</FormLabel>
              <FormControl size='small' fullWidth variant='outlined'>
                <Select
                  labelId='p_g_123'
                  id='demo-simple-select'
                  value={physicalGoodId}
                  // label='Physical Good Category'
                  onChange={e => setPhysicalGoodId(e.target.value)}
                >
                  {[...eventsAndGoods?.physical_good]?.map((good, i) => (
                    <MenuItem key={i} value={good}>
                      {good}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            <Box mb={{ xs: 4, md: 3 }}>
              <FormLabel>Physical Good Name</FormLabel>
              <AppTextInput
                fullWidth
                variant='outlined'
                // label='Physical Good Name'
                value={physicalGoodName}
                onChange={e => setPhysicalGoodName(e.target.value)}
              />
            </Box>
          </>
        )}

        <Box mb={{ xs: 4, md: 3 }}>
          <FormLabel>Req. Point (For claim offer)</FormLabel>
          <AppTextInput
            fullWidth
            variant='outlined'
            // label='Req. Point (For claim offer)'
            value={point}
            onChange={e => setPoint(e.target.value)}
            type={'number'}
            InputProps={{ inputProps: { min: 0 } }}
          />
        </Box>

        <Box mb={{ xs: 5, md: 4 }}>
          <DateTimePickerInput
            position='top'
            value={claimStartDate}
            onChange={setClaimStartDate}
            placeholder='DD-MM-YYYY HH:MM'
            label='Claim Start Date'
          />
        </Box>

        <Box mb={{ xs: 5, md: 4 }}>
          <DateTimePickerInput
            position='top'
            value={claimEndDate}
            onChange={setClaimEndDate}
            placeholder='DD-MM-YYYY HH:MM'
            label='Claim End Date'
          />
        </Box>

        {offerType === 'discount' && (
          <Box mb={{ xs: 6, md: 5 }}>
            <DateTimePickerInput
              position='top'
              value={useEndDate}
              onChange={setUseEndDate}
              placeholder='DD-MM-YYYY HH:MM'
              label='Use End Date'
            />
          </Box>
        )}

        <Box display='flex' justifyContent='flex-end' mb={4}>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Box ml={2}>
            <Button variant='contained' color='primary' onClick={handleSave}>
              {editData ? 'Update' : 'Launch'}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default OPCAddEditModal;
