import React, { useState } from 'react';
import useStyles from '../index.style';
import { Box, Button, Dialog, DialogContent, IconButton, Typography } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import GridContainer from '@jumbo/components/GridContainer';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import { useDispatch } from 'react-redux';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import { axiosInstance } from 'utils/axios';
import { toast } from 'react-hot-toast';

const ULBan = ({ open, closeModal, selectedUser, fetchUsersAfterBan }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [banReason, setBanReason] = useState('');

  const handleSubmit = () => {
    if (banReason.trim() === '') {
      dispatch(fetchError('Ban reason is required.'));
      return;
    }

    const data = {
      ban_reason: banReason,
      ban_type: 'ban',
    };

    dispatch(fetchStart());

    axiosInstance
      .post(`/admin/user/ban-unban/${selectedUser._id}`, data)
      .then(resp => {
        // console.log(resp.data);
        dispatch(fetchSuccess());
        fetchUsersAfterBan();
        toast.success('User banned successfully.');
      })
      .catch(error => {
        // console.log(error.response);
        toast.error('Sorry, something went wrong. Please try again later.');
        dispatch(fetchError());
        closeModal();
      });

    // console.log(selectedUser);
    // console.log(data);
  };

  return (
    <Dialog open={open} onClose={closeModal} className={classes.dialogRoot}>
      <Box className={classes.modalHead}>
        <Typography className={classes.titleHead}>Ban User</Typography>
        <IconButton onClick={closeModal}>
          <ClearIcon />
        </IconButton>
      </Box>
      <DialogContent dividers>
        <Box display='flexx' alignItems={{ xs: 'center', md: 'center' }} flexDirection={{ xs: 'column', md: 'row' }}>
          <Typography className={classes.titleRoot}>Condition Ref: 06</Typography>
          <GridContainer style={{ marginBottom: 20 }} />
          <p>
            To know details, click
            <a href='https://jeeto.online/terms-conditions/' target='_blank' rel='noopener noreferrer'>
              {' '}
              Terms and Conditions
            </a>
          </p>
          <GridContainer style={{ marginBottom: 20 }} />
          <Box mb={{ xs: 6, md: 5 }}>
            <AppTextInput
              fullWidth
              variant='outlined'
              label='Ban Reason'
              value={banReason}
              onChange={e => setBanReason(e.target.value)}
            />
          </Box>
        </Box>
        <GridContainer style={{ marginBottom: 12 }} />
        <Box display='flex' justifyContent='flex-end' mb={4}>
          <Box ml={2}>
            <Button variant='contained' color='primary' onClick={handleSubmit}>
              Confirm
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ULBan;
