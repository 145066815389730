import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import IntlMessages from '../../../utils/IntlMessages';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import { Box, CircularProgress } from '@material-ui/core';
import { AuhMethods } from '../../../../services/auth';
import ContentLoader from '../../ContentLoader';
import { alpha, makeStyles } from '@material-ui/core/styles';
import CmtImage from '../../../../@coremat/CmtImage';
import Typography from '@material-ui/core/Typography';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import AuthWrapper from './AuthWrapper';
import Eye from '../../../../assets/img/eye.svg';
import EyeOff from '../../../../assets/img/eye-off.svg';
import { fetchError, fetchSuccess } from 'redux/actions';
import AppTextInput from '../formElements/AppTextInput';
import { axiosInstance } from 'utils/axios';
import axios from 'axios';
import JWTAuth from 'services/auth/jwt';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  formcontrolLabelRoot: {
    '& .MuiFormControlLabel-label': {
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
  },
}));

const SignIn = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');
  const dispatch = useDispatch();
  const classes = useStyles({ variant });

  const [showLoginForm, setShowLoginForm] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(59);
  const [otpCountdownStart, setOtpCountdownStart] = useState(false);

  const [csmsPhone, setCsmsPhone] = useState(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (otpCountdownStart) {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }

        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(interval);
          } else {
            setSeconds(59);
            setMinutes(minutes - 1);
          }
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [seconds, minutes, otpCountdownStart]);

  const onSubmit = e => {
    e.preventDefault();
    let numberPattern = /^01\d{9}$/;

    if (phone.trim() === '') {
      dispatch(fetchError('Phone number can not be empty.'));
      return;
    } else if (!numberPattern.test(phone)) {
      dispatch(fetchError('Phone number must be 11 digit.'));
      return;
    }

    if (password.trim() === '') {
      dispatch(fetchError('Password can not be empty.'));
      return;
    }

    const data = {
      phone_number: phone,
      password: password,
    };

    setLoading(true);

    axiosInstance
      .post(`/admin/login/`, data)
      .then(resp => {
        // console.log(resp?.data);
        // if (resp.data.message) {
        //   dispatch(fetchError(resp.data.message));
        //   setLoading(false);
        //   return;
        // }

        if (resp?.data?.data?.login_with_otp) {
          setLoading(false);
          setOtpCountdownStart(true);
          setCsmsPhone(resp.data);
          setShowLoginForm(false);
          dispatch(fetchSuccess('OTP sent successfully.'));
        } else {
          if (resp?.data?.data?.user) {
            localStorage.setItem('token', resp?.data?.data?.token);
            localStorage.setItem('user_name', resp?.data?.data?.user.name);
            localStorage.setItem('user_id', resp?.data?.data?.user._id);
            //console.log(data);
            localStorage.setItem('email', resp?.data?.data?.user.email);
            localStorage.setItem('phone_number', resp?.data?.data?.user.phone_number);
            localStorage.setItem('userRole', JSON.stringify(resp?.data?.data?.user.role));
            // axios.defaults.headers.common['Authorization'] = 'Bearer ' + resp?.data?.data?.token;
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + resp?.data?.data?.token;
            axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + resp?.data?.data?.token;

            setTimeout(() => {
              dispatch(fetchSuccess());
              dispatch(JWTAuth.getAuthUser(true, resp?.data?.data?.token));
            }, 500);
          } else {
            dispatch(fetchError(data.error));
          }
        }
      })
      .catch(error => {
        // console.log(error.response);
        setLoading(false);
        dispatch(fetchError(error?.response?.data?.error?.message));
      });
  };

  const handleLogin = e => {
    e.preventDefault();

    if (otp.trim() === '') {
      dispatch(fetchError('OTP can not be empty.'));
      return;
    }

    const data = {
      phone_number: csmsPhone?.data?.phone_number,
      csms_id: csmsPhone?.data?.csms_id,
      otp: otp,
    };

    dispatch(AuhMethods[method].onLogin(data, resetForm));
    // setShowLoginForm(false);
  };

  const resendOTP = () => {
    const data = {
      phone_number: phone,
      password: password,
    };
    setLoading(true);
    axiosInstance
      .post(`/admin/login/`, data)
      .then(resp => {
        // console.log(resp.data);
        // if (resp.data.message) {
        //   dispatch(fetchError(resp.data.message));
        //   setLoading(false);
        //   return;
        // }
        setLoading(false);
        setOtp('');
        setCsmsPhone(resp.data);
        dispatch(fetchSuccess('OTP sent successfully.'));
        setMinutes(1);
        setSeconds(59);
      })
      .catch(error => {
        setLoading(false);
        // console.log(error.response);
        dispatch(fetchError(error?.response?.data?.error?.message));
      });
  };

  const resetForm = () => {
    setShowLoginForm(true);
    setOtpCountdownStart(true);
    setMinutes(true);
    setSeconds(true);
    setCsmsPhone(null);
    dispatch(fetchError('Sorry, please try again.'));

    setOtp('');
    setMinutes(1);
    setSeconds(59);
    setOtpCountdownStart(false);
  };

  const handleOtpInput = e => {
    if (e.target.value.length > 4) {
      const nnn = e.target.value.slice(0, 4);
      // console.log(nnn);
      setOtp(nnn);
    } else {
      setOtp(e.target.value);
    }
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <Box className={classes.authThumb}>
          <CmtImage src={'/images/auth/login-img.png'} />
        </Box>
      ) : null}
      <Box className={classes.authContent}>
        <Box mb={5}>
          <CmtImage src={'/images/addition/logo.png'} />
        </Box>
        <Typography component='div' variant='h1' className={classes.titleRoot}>
          Login
        </Typography>
        {showLoginForm ? (
          <form onSubmit={onSubmit}>
            <Box mb={2} className='hide-number-append'>
              <AppTextInput
                fullWidth
                variant='outlined'
                label='Phone Number'
                value={phone}
                onChange={e => setPhone(e.target.value)}
                type={'number'}
                size='medium'
                onKeyDown={e => {
                  if (e.keyCode === 38 || e.keyCode === 40) e.preventDefault();
                }}
              />
            </Box>
            <Box mb={2} className='login-password'>
              <TextField
                type={showPassword ? 'text' : 'password'}
                label={<IntlMessages id='appModule.password' />}
                fullWidth
                onChange={event => setPassword(event.target.value)}
                defaultValue={password}
                margin='normal'
                variant='outlined'
                className={classes.textFieldRoot}
              />
              <div className='hide-unhide'>
                {showPassword ? (
                  <img onClick={() => setShowPassword(false)} src={EyeOff} alt='' />
                ) : (
                  <img onClick={() => setShowPassword(true)} src={Eye} alt='' />
                )}
              </div>
            </Box>
            <Box display='flex' alignItems='center' justifyContent='space-between' mb={5}></Box>

            <Box display='flex' alignItems='center' mb={5}>
              <Button disabled={loading} type='submit' variant='contained' color='primary'>
                <IntlMessages id='appModule.signIn' />
              </Button>
              <span style={{ marginRight: 10 }}></span>
              {loading && <CircularProgress size={25} />}
            </Box>
          </form>
        ) : (
          <form onSubmit={handleLogin}>
            <Box mb={3}>
              <AppTextInput
                fullWidth
                variant='outlined'
                label='Enter OTP'
                value={otp}
                onChange={e => handleOtpInput(e)}
                type={'number'}
                size='medium'
              />
            </Box>

            <div className='countdown-text'>
              {seconds > 0 || minutes > 0 ? (
                <p>
                  Time Remaining: {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                </p>
              ) : (
                <p>Didn't recieve code?</p>
              )}

              <button
                disabled={seconds > 0 || minutes > 0}
                style={{
                  color: seconds > 0 || minutes > 0 ? '#DFE3E8' : '#FF5630',
                }}
                onClick={resendOTP}
                type='button'
              >
                Resend OTP
              </button>
            </div>

            <Box display='flex' alignItems='center' justifyContent='space-between' mb={5}>
              <Button disabled={loading} type='submit' variant='contained' color='primary'>
                Submit
              </Button>
              <span style={{ marginRight: 10 }}></span>
              {loading && <CircularProgress size={25} />}
            </Box>
          </form>
        )}

        {dispatch(AuhMethods[method].getSocialMediaIcons())}

        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export default SignIn;
