import React, { memo, useEffect, useState } from 'react';
import { Box, Dialog, IconButton, Switch, Typography } from '@material-ui/core';
import useStyles from '../index.style';
import ClearIcon from '@material-ui/icons/Clear';
import { toast } from 'react-hot-toast';
import { axiosInstance } from 'utils/axios';
import { useDispatch } from 'react-redux';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import moment from 'moment';

const BUSummery = ({ showDetailsModal, resetModal, selectedUser, setData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user_name, ban_information, ban_date } = selectedUser;
  const [checkbox, setCheckbox] = useState(false);

  useEffect(() => {
    if (selectedUser) {
      setCheckbox(selectedUser?.applied_for_unban);
    }
  }, [selectedUser]);

  const handleAppliedForBanUnban = user => {
    setCheckbox(!checkbox);

    // console.log(data);

    dispatch(fetchStart());
    axiosInstance
      .patch(`/admin/user/applied-for-unban/${user._id}`)
      .then(resp => {
        // console.log(resp?.data);
        dispatch(fetchSuccess());
        resetModal();
        toast.success('Data updated successfully.');

        setData(prevData => {
          return {
            ...prevData,
            data: prevData?.data?.map(user => {
              if (user._id === resp?.data?.data?.user?._id) {
                return {
                  ...user,
                  applied_for_unban: resp?.data?.data?.user?.applied_for_unban,
                };
              }
              return { ...user };
            }),
          };
        });
      })
      .catch(error => {
        // console.log(error.response);
        dispatch(fetchError());
        toast.error('Sorry, something went wrong. Please try again later.');
      });
  };

  return (
    <Dialog open={showDetailsModal} onClose={resetModal} className={classes.dialogRoot}>
      <Box className={classes.modalHead}>
        <Typography variant='h4' className={classes.titleHead}>
          Ban Summery
        </Typography>
        <IconButton onClick={resetModal}>
          <ClearIcon />
        </IconButton>
      </Box>
      <Box className={classes.userInfoRoot}>
        <Box width='100%' mr={3} alignItems={{ xs: 'center', md: 'start' }} flexDirection={{ xs: 'column', md: 'row' }}>
          <Box>
            <Typography className={classes.titleRoot}>Name: {user_name}</Typography>
            <Typography className={classes.titleRoot}>Condition Ref: 06</Typography>
            <Typography className={classes.titleRoot}>Ban Reason: {ban_information}</Typography>
            <Typography className={classes.titleRoot}>
              {/* Status: Applied to unban */}
              Status: Banned
            </Typography>
            <Typography className={classes.titleRoot}>
              Date: {ban_date ? moment(ban_date).format('hh:mm A, DD MMM YY') : ''}
            </Typography>
            <Typography className={classes.titleRoot}>
              Applied for unban:
              <Switch
                checked={checkbox}
                onChange={() => handleAppliedForBanUnban(selectedUser)}
                name='appliedForUnban'
                inputProps={{ 'aria-label': 'secondary checkbox' }}
              />
            </Typography>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default memo(BUSummery);
