import React, { useCallback, useEffect, useState } from 'react';
import useStyles from './index.style';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import CmtSearch from '@coremat/CmtSearch';
import { axiosInstance } from 'utils/axios';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import { Pagination } from '@material-ui/lab';
import NoRecordFound from 'components/NoRecordFound.Component';
import { Delete, Edit, MoreHoriz } from '@material-ui/icons';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
// import AddEditModal from './components/AddEditModal.Compoent';
import { Toaster, toast } from 'react-hot-toast';
import ConfirmDialog from '@jumbo/components/Common/ConfirmDialog';
import AddEditAdmin from './components/AddEditAdmin.Component';
// import PEView from './components/PrizeView.Component';

const pageSize = 20;

const AdminUsers = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [search, setSearch] = useState('');

  const [data, setData] = useState(null);
  const [paginationDisabled, setPaginationDisabled] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedAdmin, setSelectedAdmin] = useState(null);
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = (search, page = 1) => {
    dispatch(fetchStart());
    setPaginationDisabled(true);
    const searchTxt = search || '';

    axiosInstance
      .get(`/admin/list`, {
        params: { page: page, size: pageSize, search: searchTxt },
      })
      .then(resp => {
        // console.log(resp.data);
        setTotalPages(Math.ceil(resp?.data?.total_count / pageSize));
        setData(resp?.data);
        dispatch(fetchSuccess());
        setPaginationDisabled(false);
      })
      .catch(error => {
        // console.log(error.response);
        setPaginationDisabled(false);
        dispatch(fetchError('Sorry, something went wrong. Please try again later.'));
      });
  };

  const handlePagination = (e, page) => {
    setCurrentPage(page);
    fetchData(search, page);
    // console.log(dateRange);
  };

  const handleSearch = e => {
    e.preventDefault();

    // console.log(search);
    setCurrentPage(1);
    fetchData(search, 1);
  };

  const handleEditEvent = useCallback(event => {
    // console.log('ban user', user);
    setSelectedAdmin(event);
    setShowAddEditModal(true);
  }, []);

  const handleDeleteEvent = useCallback(event => {
    // console.log('ban user', user);
    setSelectedAdmin(event);
    setShowDeleteModal(true);
  }, []);

  const resetModals = () => {
    setShowDeleteModal(false);
    setShowAddEditModal(false);
    setSelectedAdmin(null);
  };

  const handleConfirmDelete = () => {
    // console.log(selectedAdmin);

    dispatch(fetchStart());
    axiosInstance
      .delete(`/admin/delete/${selectedAdmin?._id}`)
      .then(resp => {
        // console.log(resp.data);
        dispatch(fetchSuccess());
        fetchDataAfterActions();
        resetModals();
        toast.success('Admin deleted successfully.');
      })
      .catch(error => {
        // console.log(error.response);
        toast.error('Sorry, something went wrong. Please try again later.');
        dispatch(fetchError());
      });
  };

  const fetchDataAfterActions = () => {
    fetchData(search, currentPage);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar className={classes.root} display='flex' style={{ justifyContent: 'space-between' }}>
          <Box display='flex' alignItems='center'>
            <Typography className={classes.title} variant='h4' id='tableTitle' component='div'>
              Admin Users: {data?.total_count || 0}
            </Typography>
            <Button
              type='submit'
              style={{ marginLeft: 10 }}
              variant='contained'
              color='primary'
              onClick={() => setShowAddEditModal(true)}
            >
              Add new admin
            </Button>
          </Box>

          <form onSubmit={handleSearch}>
            <Box display='flex'>
              <CmtSearch
                placeholder='name, phone'
                onChange={e => setSearch(e.target.value)}
                value={search}
                border={true}
                onlyIcon={false}
              />
              <Button type='submit' style={{ marginLeft: 10 }} variant='contained' color='primary'>
                Search
              </Button>
            </Box>
          </form>
        </Toolbar>

        <TableContainer className={classes.container}>
          <Table stickyHeader>
            <AdminTableHead />
            {data && (
              <TableBody>
                {data?.data?.length > 0 ? (
                  data?.data?.map(admin => (
                    <AdminTableBody
                      key={admin?._id}
                      admin={admin}
                      handleEditEvent={handleEditEvent}
                      handleDeleteEvent={handleDeleteEvent}
                    />
                  ))
                ) : (
                  <TableRow style={{ height: 200 }}>
                    <TableCell colSpan={11} rowSpan={7}>
                      <NoRecordFound>There are no records found.</NoRecordFound>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        {totalPages > 1 && (
          <Box px={4} py={5} display='flex' justifyContent='flex-end'>
            <Pagination
              disabled={paginationDisabled}
              page={currentPage}
              count={totalPages}
              color='primary'
              onChange={handlePagination}
            />
          </Box>
        )}

        {showAddEditModal && (
          <AddEditAdmin
            open={showAddEditModal}
            closeModal={resetModals}
            selectedAdmin={selectedAdmin}
            fetchDataAfterActions={fetchDataAfterActions}
            setData={setData}
          />
        )}

        {showDeleteModal && (
          <ConfirmDialog
            open={showDeleteModal}
            title='Confirm Delete'
            content='Are you sure to delete this admin?'
            onClose={resetModals}
            onConfirm={handleConfirmDelete}
          />
        )}
      </Paper>
      <Toaster position='top-right' toastOptions={{ duration: 3000 }} />
    </div>
  );
};

export default AdminUsers;

const AdminTableBody = React.memo(({ admin, handleEditEvent, handleDeleteEvent }) => {
  const onUserMenuClick = menu => {
    if (menu.action === 'edit') {
      handleEditEvent(admin);
    } else if (menu.action === 'delete') {
      handleDeleteEvent(admin);
    }
  };

  const getUserActions = () => {
    let actions = [{ action: 'delete', label: 'Delete', icon: <Delete /> }];

    if (admin?.level !== 0) {
      actions = [{ action: 'edit', label: 'Edit', icon: <Edit /> }, ...actions];
    }

    return actions;
  };

  const userActions = getUserActions(admin);

  return (
    <TableRow>
      <TableCell align='left'>{admin?.name}</TableCell>
      <TableCell align='left'>{admin?.role?.type || '-'}</TableCell>
      <TableCell>{admin?.email}</TableCell>
      <TableCell>{admin?.phone_number}</TableCell>
      {localStorage.getItem('user_id') === admin?._id ? (
        <TableCell>
          <span></span>
        </TableCell>
      ) : (
        <TableCell align='center' onClick={event => event.stopPropagation()}>
          <CmtDropdownMenu items={userActions} onItemClick={onUserMenuClick} TriggerComponent={<MoreHoriz />} />
        </TableCell>
      )}
    </TableRow>
  );
});

const AdminTableHead = React.memo(() => {
  const tableHeader = ['Name', 'Role', 'Email', 'Phone', 'Actions'];

  return (
    <TableHead>
      <TableRow>
        {tableHeader?.map((d, i) => (
          <TableCell key={i} align='left' padding={'normal'}>
            {d}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
});
