import { C_S_D_SET_ALL_EVENTS, C_S_D_SET_GAMES, C_S_D_SET_GAME_CATEGORIES } from '@jumbo/constants/ActionTypes';

const INIT_STATE = {
  games: null,
  gameCategory: null,
  allEvents: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case C_S_D_SET_GAMES:
      return {
        ...state,
        games: action.payload,
      };

    case C_S_D_SET_GAME_CATEGORIES:
      return {
        ...state,
        gameCategory: action.payload,
      };

    case C_S_D_SET_ALL_EVENTS:
      return {
        ...state,
        allEvents: action.payload,
      };

    default:
      return state;
  }
};
