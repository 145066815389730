import React from 'react';
import useStyles from '../index.style';
import AnalyticRefereeReferred from '../components/AnalyticRefereeReferred.Component';
import AnalyticPointEarnBurn from '../components/AnalyticPointEarnBurn.Component';
import AnalyticOfferClaim from '../components/AnalyticOfferClaim.component';

const RfeAnalytic = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <h2>Referral - Data Analytics</h2>
      <br />
      <AnalyticRefereeReferred />
      <AnalyticPointEarnBurn title='Point Earn Analytics - Data Table' url='earn' />
      <AnalyticPointEarnBurn title='Point Burn Analytics - Data Table' url='burn' />
      <AnalyticOfferClaim />
    </div>
  );
};

export default RfeAnalytic;
