import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Toolbar,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import useStyles from './index.style';
import { axiosInstance } from 'utils/axios';
import toast, { Toaster } from 'react-hot-toast';

const UpdateMaintenance = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [updateData, setUpdateData] = useState({
    android_min_version: '',
    android_max_version: '',
    ios_min_version: '',
    ios_max_version: '',

    android_start_time: '',
    android_end_time: '',
    android_maintenance_type: '',
    android_message: '',

    ios_start_time: '',
    ios_end_time: '',
    ios_maintenance_type: '',
    ios_message: '',

    android_bypass: false,
    ios_bypass: false,
  });

  useEffect(() => {
    fetchData();
  }, []);

  const dateFields = ['android_start_time', 'android_end_time', 'ios_start_time', 'ios_end_time'];

  const fetchData = () => {
    dispatch(fetchStart());

    axiosInstance
      .get('/admin/update-and-maintenance/get')
      .then(resp => {
        // console.log(resp.data?.data);

        if (resp?.data?.data) {
          for (const key in resp.data?.data) {
            // console.log(key, resp.data?.data[key]);

            if (resp.data?.data[key] !== '') {
              if (dateFields.includes(key)) {
                setUpdateData(prevState => {
                  return {
                    ...prevState,
                    [key]: moment(resp.data?.data[key], 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm'),
                  };
                });
              } else {
                setUpdateData(prevState => {
                  return {
                    ...prevState,
                    [key]: resp.data?.data[key],
                  };
                });
              }
            }
          }
        }

        dispatch(fetchSuccess());
      })
      .catch(error => {
        // console.log(error.response);
        toast.error('Sorry, something went wrong. Please try again later.');
      });
  };

  const putUpdateMaintenance = data => {
    dispatch(fetchStart());

    axiosInstance
      .put('/admin/update-and-maintenance/update', data)
      .then(resp => {
        // console.log(resp.data);
        dispatch(fetchSuccess());
        toast.success('Updated successfully.');
      })
      .catch(error => {
        // console.log(error.response);
        dispatch(fetchError());
        toast.error('Sorry, something went wrong. Please try again later.');
      });
  };

  const handleInputChange = e => {
    const { name, value } = e.target;
    // console.log(name);
    // console.log(value);

    setUpdateData(prevState => {
      return {
        ...prevState,
        [name]: value,
      };
    });
  };

  const handleUpdateData = () => {
    for (const key in updateData) {
      if (updateData[key] === '') {
        toast.error(`${key.split('_').join(' ')} error.`);
        return;
      }
    }

    const putData = {};

    for (const key in updateData) {
      if (dateFields.includes(key)) {
        putData[key] = moment(updateData[key]).format('DD/MM/YYYY HH:mm');
      } else {
        putData[key] = updateData[key];
      }
    }

    // delete putData?._id;
    // delete putData?.updatedAt;

    // console.log(putData);

    putUpdateMaintenance(putData);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar>
          <Typography className={classes.title} variant='h1' component='div'>
            Update and Maintenance
          </Typography>
        </Toolbar>
        <div className='container-p'>
          <Typography variant='h2' component='div'>
            Update
          </Typography>

          <br />

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography>Android</Typography>
            </Grid>
            <Grid item xs={6}>
              <Box mb={{ xs: 6, md: 2 }}>
                <AppTextInput
                  fullWidth
                  variant='outlined'
                  label='Min Version'
                  name='android_min_version'
                  value={updateData?.android_min_version}
                  onChange={handleInputChange}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box mb={{ xs: 6, md: 2 }}>
                <AppTextInput
                  fullWidth
                  variant='outlined'
                  label='Max Version'
                  name='android_max_version'
                  value={updateData?.android_max_version}
                  onChange={handleInputChange}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography>IOS</Typography>
            </Grid>
            <Grid item xs={6}>
              <Box mb={{ xs: 6, md: 2 }}>
                <AppTextInput
                  fullWidth
                  variant='outlined'
                  label='Min Version'
                  name='ios_min_version'
                  value={updateData?.ios_min_version}
                  onChange={handleInputChange}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box mb={{ xs: 6, md: 2 }}>
                <AppTextInput
                  fullWidth
                  variant='outlined'
                  label='Max Version'
                  name='ios_max_version'
                  value={updateData?.ios_max_version}
                  onChange={handleInputChange}
                />
              </Box>
            </Grid>
          </Grid>

          <Divider style={{ marginTop: 25 }} />
        </div>

        <div className='container-p'>
          <Typography variant='h2' component='div'>
            Maintenance Mode
          </Typography>

          <br />

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography>Android</Typography>
            </Grid>
            <Grid item xs={4}>
              <Box mb={{ xs: 6, md: 5 }}>
                <TextField
                  fullWidth
                  variant='outlined'
                  label='Start Date'
                  type='datetime-local'
                  name='android_start_time'
                  value={updateData?.android_start_time}
                  onChange={handleInputChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size='small'
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box mb={{ xs: 6, md: 5 }}>
                <TextField
                  fullWidth
                  variant='outlined'
                  label='End Date'
                  type='datetime-local'
                  name='android_end_time'
                  value={updateData?.android_end_time}
                  onChange={handleInputChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size='small'
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box mb={{ xs: 6, md: 2 }}>
                <FormControl size='small' fullWidth variant='outlined'>
                  <InputLabel id='demo-simple-select-label'>Select Type</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    label='Select Type'
                    name='android_maintenance_type'
                    value={updateData?.android_maintenance_type}
                    onChange={handleInputChange}
                  >
                    <MenuItem value='forced'>Forced</MenuItem>
                    <MenuItem value='relaxed'>Relaxed</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box mb={{ xs: 6, md: 2 }}>
                <AppTextInput
                  fullWidth
                  variant='outlined'
                  label='Android Maintenance Message'
                  name='android_message'
                  value={updateData?.android_message}
                  onChange={handleInputChange}
                />
              </Box>
            </Grid>
          </Grid>

          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography>IOS</Typography>
            </Grid>
            <Grid item xs={4}>
              <Box mb={{ xs: 6, md: 2 }}>
                <TextField
                  fullWidth
                  variant='outlined'
                  label='Start Date'
                  type='datetime-local'
                  name='ios_start_time'
                  value={updateData?.ios_start_time}
                  onChange={handleInputChange}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  size='small'
                />
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box mb={{ xs: 6, md: 2 }}>
                <Box mb={{ xs: 6, md: 2 }}>
                  <TextField
                    fullWidth
                    variant='outlined'
                    label='End Date'
                    type='datetime-local'
                    name='ios_end_time'
                    value={updateData?.ios_end_time}
                    onChange={handleInputChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    size='small'
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box mb={{ xs: 6, md: 2 }}>
                <FormControl size='small' fullWidth variant='outlined'>
                  <InputLabel id='demo-simple-select-label'>Select Type</InputLabel>
                  <Select
                    labelId='demo-simple-select-label'
                    id='demo-simple-select'
                    label='Select Type'
                    name='ios_maintenance_type'
                    value={updateData?.ios_maintenance_type}
                    onChange={handleInputChange}
                  >
                    <MenuItem value='forced'>Forced</MenuItem>
                    <MenuItem value='relaxed'>Relaxed</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box mb={{ xs: 6, md: 5 }}>
                <AppTextInput
                  fullWidth
                  variant='outlined'
                  label='IOS Maintenance Message'
                  name='ios_message'
                  value={updateData?.ios_message}
                  onChange={handleInputChange}
                />
              </Box>
            </Grid>
          </Grid>

          <Button
            onClick={handleUpdateData}
            variant='contained'
            size='large'
            color='primary'
            style={{ marginBottom: 25, paddingLeft: 30, paddingRight: 30 }}
          >
            Push
          </Button>
        </div>
      </Paper>

      <Toaster position='top-right' toastOptions={{ duration: 3000 }} />
    </div>
  );
};

export default UpdateMaintenance;
