import {
  GAMES_CURRENT_PAGE,
  GAMES_PAGINATION_DISABLED,
  GAMES_SET_SEARCH,
  GAMES_TOTAL_PAGES,
  SET_ALL_GAMES,
} from '@jumbo/constants/ActionTypes';

const INIT_STATE = {
  games: {
    data: null,
    paginationDisabled: false,
    totalPages: 0,
    currentPage: 1,
    search: '',
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_ALL_GAMES:
      return {
        ...state,
        games: {
          ...state.games,
          data: action.payload,
        },
      };

    case GAMES_PAGINATION_DISABLED:
      return {
        ...state,
        games: {
          ...state.games,
          paginationDisabled: action.payload,
        },
      };

    case GAMES_TOTAL_PAGES:
      return {
        ...state,
        games: {
          ...state.games,
          totalPages: action.payload,
        },
      };

    case GAMES_CURRENT_PAGE:
      return {
        ...state,
        games: {
          ...state.games,
          currentPage: action.payload,
        },
      };

    case GAMES_SET_SEARCH:
      return {
        ...state,
        games: {
          ...state.games,
          search: action.payload,
        },
      };

    default:
      return state;
  }
};
