import {
  SET_CATEGORIES,
  SET_CATEGORY_CURRENT_PAGE,
  SET_CATEGORY_P_D,
  SET_CATEGORY_SEARCH,
  SET_CATEGORY_TOTAL_PAGES,
  SET_SELECTED_CATEGORY,
} from '@jumbo/constants/ActionTypes';

const INIT_STATE = {
  categories: null,
  paginationDisabled: false,
  totalPages: 0,
  currentPage: 1,
  search: '',
  selected_category: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_CATEGORIES: {
      return {
        ...state,
        categories: action.payload,
      };
    }

    case SET_SELECTED_CATEGORY: {
      return {
        ...state,
        selected_category: action.payload,
      };
    }

    case SET_CATEGORY_P_D: {
      return {
        ...state,
        paginationDisabled: action.payload,
      };
    }

    case SET_CATEGORY_TOTAL_PAGES: {
      return {
        ...state,
        totalPages: action.payload,
      };
    }

    case SET_CATEGORY_CURRENT_PAGE: {
      return {
        ...state,
        currentPage: action.payload,
      };
    }

    case SET_CATEGORY_SEARCH: {
      return {
        ...state,
        search: action.payload,
      };
    }

    default:
      return state;
  }
};
