import {
  SET_HISTORY_NOTIFICATIONS,
  SET_SCHEDULED_NOTIFICATIONS,
  HISTORY_NOTIFICATIONS_PAGINATION_DISABLED,
  HISTORY_NOTIFICATIONS_TOTAL_PAGES,
  SET_EDIT_NOTIFICATIONS_DATA,
  SET_SCHEDULED_NOTIFICATIONS_PAGINATION_DISABLED,
  SET_SCHEDULED_NOTIFICATIONS_TOTAL_PAGES,
  SET_SCHEDULED_NOTIFICATIONS_IS_FETCHED,
  SET_HISTORY_NOTIFICATIONS_IS_FETCHED,
} from '@jumbo/constants/ActionTypes';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import { axiosInstance } from 'utils/axios';

export const getScheduledNotification = (pageSize = 20, page = 1, startDate, endDate, callback) => {
  let start = startDate || '';
  let end = endDate || '';

  return dispatch => {
    dispatch(fetchStart());
    dispatch({ type: SET_SCHEDULED_NOTIFICATIONS_PAGINATION_DISABLED, payload: true });

    axiosInstance
      .get(`/notification/scheduled`, {
        params: { page: page, size: pageSize, start_time: start, end_time: end },
      })
      .then(resp => {
        dispatch({ type: SET_SCHEDULED_NOTIFICATIONS, payload: resp?.data?.data?.data });
        dispatch({ type: SET_SCHEDULED_NOTIFICATIONS_IS_FETCHED, payload: true });
        dispatch({
          type: SET_SCHEDULED_NOTIFICATIONS_TOTAL_PAGES,
          payload: Math.ceil(resp?.data?.data?.total_count / pageSize),
        });
        // console.log(resp.data);
        if (callback) callback();
        // console.log(bannedUsers);
        dispatch(fetchSuccess());
        dispatch({ type: SET_SCHEDULED_NOTIFICATIONS_PAGINATION_DISABLED, payload: false });
      })
      .catch(error => {
        dispatch(fetchError('There was something issue in responding server'));
        dispatch({ type: SET_SCHEDULED_NOTIFICATIONS_PAGINATION_DISABLED, payload: false });
      });
  };
};

export const fetchHistoryNotifications = (pageSize = 20, page = 1, startDate, endDate, callback) => {
  let start = startDate || '';
  let end = endDate || '';

  return dispatch => {
    dispatch(fetchStart());
    dispatch({ type: HISTORY_NOTIFICATIONS_PAGINATION_DISABLED, payload: true });

    axiosInstance
      .get(`/notification/history`, {
        params: { page: page, size: pageSize, start_time: start, end_time: end },
      })
      .then(resp => {
        // console.log(resp.data);
        // setHistoryData(resp.data.data);
        // setHistoryLength(resp.data.data_length);
        dispatch({ type: SET_HISTORY_NOTIFICATIONS_IS_FETCHED, payload: true });
        dispatch({ type: SET_HISTORY_NOTIFICATIONS, payload: resp.data.data });
        dispatch({
          type: HISTORY_NOTIFICATIONS_TOTAL_PAGES,
          payload: Math.ceil(resp?.data?.data?.total_count / pageSize),
        });
        dispatch({ type: HISTORY_NOTIFICATIONS_PAGINATION_DISABLED, payload: false });
        dispatch(fetchSuccess());
        if (callback && typeof callback === 'function') callback();
      })
      .catch(error => {
        // console.log(error.response);
        dispatch({ type: HISTORY_NOTIFICATIONS_PAGINATION_DISABLED, payload: false });
        dispatch(fetchError('Sorry, something went wrong. Please try again later.'));
      });
  };
};

export const setEditNotificationData = data => {
  return dispatch => {
    dispatch({ type: SET_EDIT_NOTIFICATIONS_DATA, payload: data });
  };
};
