import React from 'react';
import useStyles from '../index.style';
import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { useState } from 'react';
import Tabs from 'components/Tabs.Component';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { convertSeconds } from 'common/functions';
import moment from 'moment';
import NoRecordFound from 'components/NoRecordFound.Component';

const tabs = ['Earned', 'Used'];

const RefUserModal = ({ open, handleCloseModal, modalData }) => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(tabs[0]);

  return (
    <Dialog open={open} className={classes.dialogRootUser} onClose={handleCloseModal}>
      <DialogContent>
        <Box mb={{ xs: 6, md: 5 }}>
          <Box display='flex' alignItems='center' justifyContent='space-between' mb={2}>
            <Box display='flex' alignItems='center' className='pointer' onClick={handleCloseModal}>
              <ArrowBackIcon color='primary' style={{ fontSize: 20, marginRight: 5 }} />
              <p className='fw-600 text-primary'>Back to List</p>
            </Box>
            <Box display='flex' alignItems='center'>
              <p className='fw-600'>Ban User from Referral</p>
              <Switch color='primary' />
            </Box>
          </Box>

          <Box display='flex' alignItems='center' justifyContent='space-between'>
            <Box display='flex' alignItems='center'>
              <img
                style={{ borderRadius: 50, marginRight: 10 }}
                width={100}
                src={modalData?.userData?.profile_picture}
                alt=''
              />
              <Box>
                <p className='fw-600' style={{ fontSize: 25 }}>
                  {modalData?.userData?.user_name}
                </p>
                <p>
                  <span className='fw-600'>Sign Up Date as Referrer: </span>
                  {modalData?.apiData?.entry_date_time &&
                    moment(modalData?.apiData?.entry_date_time).format('hh:mm A; DD MMMM YYYY')}
                </p>
                <p>
                  <span className='fw-600'>Referral Link for User: </span>
                  <br />
                  <a
                    href={modalData?.apiData?.referral_link}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='text-primary'
                  >
                    {modalData?.apiData?.referral_link}
                  </a>
                </p>
                <p>
                  <span className='fw-600'>Login no: </span>
                  {modalData?.userData?.phone}
                </p>
              </Box>
            </Box>
            <Box display='flex' alignItems='center' style={{ gap: 25 }}>
              <Box display='flex' alignItems='center' flexDirection='column' style={{ gap: 5 }}>
                <p className='fw-600' style={{ fontSize: 25 }}>
                  {modalData?.apiData?.reg_user_count}
                </p>
                <p>USER REGISTERED</p>
              </Box>
              <Box display='flex' alignItems='center' flexDirection='column' style={{ gap: 5 }}>
                <p className='fw-600' style={{ fontSize: 25 }}>
                  {modalData?.apiData?.purchased_event_count}
                </p>
                <p>PURCHASED</p>
              </Box>
              <Box display='flex' alignItems='center' flexDirection='column' style={{ gap: 5 }}>
                <p className='fw-600' style={{ fontSize: 25 }}>
                  {modalData?.apiData?.total_gametime_count && convertSeconds(modalData?.apiData?.total_gametime_count)}
                </p>
                <p>GAMEPLAY HOURS</p>
              </Box>
            </Box>
          </Box>
        </Box>
        <Divider />
        {/* Tabs */}
        <Box mb={{ xs: 6, md: 5 }}>
          <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
          <br />
          {activeTab === 'Earned' && (
            <>
              <span className='fw-600'>Total Earned Points: {modalData?.apiData?.total_rp}</span>
              <EarnedTable classes={classes} earned={modalData?.apiData?.earn_history} />
            </>
          )}
          {activeTab === 'Used' && (
            <>
              <span className='fw-600'>
                Total Claimed Points: {modalData?.apiData?.total_rp - modalData?.apiData?.current_rp}
              </span>
              <UsedTable classes={classes} used={modalData?.apiData?.burn_history} />
            </>
          )}
        </Box>

        {/* CLose */}
        {/* <Box display='flex' justifyContent='flex-end' mb={1}>
          <Button variant='contained' color='primary' onClick={handleCloseModal}>
            Close
          </Button>
        </Box> */}
      </DialogContent>
    </Dialog>
  );
};

export default RefUserModal;

const EarnedTable = ({ classes, earned }) => {
  const tableHeader = ['Points Earning Subject', 'Earned Points', 'Points Receive Date'];

  const getEarningSubject = data => {
    if (data?.activity_type === 'PURCHASING_EVENT') {
      return 'User Purchased Prize Event';
    }

    if (data?.activity_type === 'REG_USER') {
      return 'User Registered';
    }

    return data?.title;
  };

  return (
    <TableContainer className={classes.container} style={{ marginTop: 15, height: 300 }}>
      <Table
        className='export_paid_user_table1 analytic_table1'
        aria-labelledby='tableTitle'
        aria-label='sticky enhanced table '
        stickyHeader
      >
        <TableHead style={{ background: '#f4f4f7' }}>
          <TableRow>
            {tableHeader?.map((d, i) => (
              <TableCell key={i} align='left' padding={'normal'}>
                {d}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {earned?.length > 0 ? (
            earned?.map((data, i) => (
              <TableRow key={i}>
                <TableCell align='left' padding={'normal'}>
                  {`${i + 1}. ${getEarningSubject(data)}`}
                </TableCell>
                <TableCell align='left' padding={'normal'}>
                  +{data?.point || '0'}
                </TableCell>
                <TableCell align='left' padding={'normal'}>
                  {data?.time && moment(data?.time).format('hh:mm A; DD MMMM YYYY')}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={3} rowSpan={3}>
                <NoRecordFound>There are no records found.</NoRecordFound>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const UsedTable = ({ classes, used }) => {
  const tableHeader = ['Points Earning Subject', 'Points Used'];

  return (
    <TableContainer className={classes.container} style={{ marginTop: 15, height: 300 }}>
      <Table
        className='export_paid_user_table1 analytic_table1'
        aria-labelledby='tableTitle'
        aria-label='sticky enhanced table'
        stickyHeader
      >
        <TableHead style={{ background: '#f4f4f7' }}>
          <TableRow>
            {tableHeader?.map((d, i) => (
              <TableCell key={i} align='left' padding={'normal'}>
                {d}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {used?.length > 0 ? (
            used?.map((data, i) => (
              <TableRow key={i}>
                <TableCell align='left' padding={'normal'}>
                  {`${i + 1}. ${data?.title}`}
                </TableCell>
                <TableCell align='left' padding={'normal'}>
                  -{data?.required_point || '0'}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={3} rowSpan={3}>
                <NoRecordFound>There are no records found.</NoRecordFound>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
