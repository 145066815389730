import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import Axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import Popover from 'components/DateTimePopover.Component';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import moment from 'moment';
import { getScheduledNotification, setEditNotificationData } from 'redux/actions/Notifications';
import { checkImgRatio, getImgName } from 'common/functions';
import { DateTimePicker } from '@mantine/dates';
import { commonFetchAllEvents } from 'redux/actions/commonDataFetch';

const notificationsCategory = {
  default: 'all',
  events: 'prize_event',
};

const GeneralNotification = ({ classes }) => {
  const dispatch = useDispatch();

  let { editData } = useSelector(({ notificationReducer }) => notificationReducer);
  const { allEvents: events } = useSelector(({ CommonSharedData }) => CommonSharedData);

  const [showDateTimePopover, setShowDateTimePopover] = useState(null);

  const [notificationTitle, setNotificationTitle] = useState('');
  const [notificationDescription, setNotificationDescription] = useState('');
  const [dateTime, setDateTime] = useState(null);
  const [imgFile, setImgFile] = useState(null);
  const [imageName, setImageName] = useState(null);
  const [categoryRadio, setCategoryRadio] = useState('default');
  const [purchaseStatus, setPurchaseStatus] = useState('yes');
  const [eventId, setEventId] = useState('');

  useEffect(() => {
    // fetchEvents();
    if (!events) {
      dispatch(commonFetchAllEvents());
    }

    return () => {
      dispatch(setEditNotificationData(null));
    };
  }, []);

  useEffect(() => {
    if (editData) {
      // console.log(editData);
      setNotificationTitle(editData?.data?.title);
      setNotificationDescription(editData?.data?.body);
      setDateTime(new Date(editData?.time));
      setCategoryRadio(editData?.data?.notification_details?.participants_type !== 'all' ? 'events' : 'default');
      setPurchaseStatus(editData?.data?.notification_details?.participants_status ? 'yes' : 'no');
      setEventId(editData?.data?.segment_details?.id);
      // return;

      if (editData?.data?.image_url) {
        // setImageName('name.jpg');
        const nameArray = (editData?.data?.image_url).split('/');
        // console.log(nameArray);
        const name = nameArray[nameArray.length - 1];
        setImageName(getImgName(name));
        // console.log(name);
      } else {
        setImageName(null);
      }
    }
  }, [editData]);

  const resetForm = () => {
    setNotificationTitle('');
    setNotificationDescription('');
    setDateTime(null);
    setImgFile(null);
    setImageName(null);
    setCategoryRadio('default');
    setPurchaseStatus('yes');
    setEventId('');
  };

  const postNotifications = (data, type) => {
    dispatch(fetchStart());
    Axios.post(`${process.env.REACT_APP_BASE_URL}/notification/send`, data)
      // Axios.post(`https://eoekh8ntt7mi6wg.m.pipedream.net`, data)
      .then(resp => {
        // console.log(resp.data);
        resetForm();
        if (type === 'save') dispatch(getScheduledNotification());
        dispatch(fetchSuccess('Notification added successfully.'));
      })
      .catch(error => {
        // console.log(error.response);
        dispatch(fetchError('Sorry, something went wrong. Please try again later.'));
      });
  };

  const putNotifications = (id, data) => {
    dispatch(fetchStart());
    Axios.put(`${process.env.REACT_APP_BASE_URL}/notification/update/${id}`, data)
      // Axios.put(`https://eokrk7vaaxonx75.m.pipedream.net`, data)
      .then(resp => {
        // console.log(resp.data);
        dispatch(fetchSuccess('Notification updated successfully.'));
        dispatch(getScheduledNotification());
        resetForm();
        dispatch(setEditNotificationData(null));
      })
      .catch(error => {
        // console.log(error.response);
        dispatch(fetchError('Sorry, something went wrong. Please try again later.'));
      });
  };

  const handleFileUpload = async e => {
    const file = e.target.files[0];
    const fileArray = file.name.split('.');
    const fileExtension = fileArray[fileArray.length - 1];

    const s = await checkImgRatio(file, 512, 1024);
    if (!s) {
      dispatch(fetchError('Notification image must be 1024x512.'));
      return;
    }

    const imgFiles = ['png', 'jpg', 'jpeg'];

    if (imgFiles.includes(fileExtension.toLowerCase())) {
      setImgFile(file);
      setImageName(getImgName(file.name));
    } else {
      dispatch(fetchError('Please select a png, jpg or jpeg image file.'));
    }
  };

  const handlePostNotification = type => {
    // const t = moment(dateTime).format();
    // console.log(t);
    // console.log('2023-05-15T10:54:21.114+06:00');
    // console.log(dateTime);
    // return;
    if (!notificationTitle) {
      dispatch(fetchError('Please enter notification title.'));
      return;
    }

    if (!notificationDescription) {
      dispatch(fetchError('Please enter notification body.'));
      return;
    }

    if (type === 'save' && !dateTime) {
      dispatch(fetchError('Please select a date time.'));
      return;
    }

    if (categoryRadio === 'events' && !eventId) {
      dispatch(fetchError('Please select an event.'));
      return;
    }

    const getEditImgName = () => {
      if (!imageName) {
        return '';
      }

      if (imgFile) {
        return imageName;
      } else {
        return editData?.data?.image_url;
      }
    };

    const postData = {
      title: notificationTitle,
      body: notificationDescription,
      time: moment(type === 'save' ? dateTime : new Date()).format(),
      segment_id: categoryRadio === 'default' ? '' : eventId,
      participants_status: purchaseStatus === 'yes' ? true : false,
      image_url: editData ? getEditImgName() : '',
      // image_url: '',
      participants_type: notificationsCategory[categoryRadio],
    };

    let formData = new FormData();
    formData.append('data', JSON.stringify(postData));

    if (imgFile) {
      formData.append('image', imgFile, imgFile.name);
    }

    if (editData) {
      // console.log(postData);
      putNotifications(editData?._id, formData);
    } else {
      postNotifications(formData, type);
    }
  };

  const handleCancleEdit = () => {
    dispatch(setEditNotificationData(null));
    resetForm();
  };

  return (
    <Paper className={classes.paper}>
      <Box style={{ margin: '0 15px', paddingTop: 15 }}>
        <Grid container spacing={5}>
          <Grid item xs={7}>
            <Box mb={3}>
              <Typography className={classes.title} variant='h4' id='tableTitle' component='div'>
                General Notification
              </Typography>
            </Box>
            <Box mb={3}>
              <Typography variant='h5' id='tableTitle' component='div' style={{ marginBottom: 5 }}>
                Notification Title
              </Typography>
              <AppTextInput
                fullWidth
                variant='outlined'
                value={notificationTitle}
                onChange={e => setNotificationTitle(e.target.value)}
              />
            </Box>
            <Box mb={3}>
              <Typography variant='h5' id='tableTitle' component='div' style={{ marginBottom: 5 }}>
                Notification Body
              </Typography>
              <TextField
                fullWidth
                multiline
                minRows={3}
                variant='outlined'
                value={notificationDescription}
                onChange={e => setNotificationDescription(e.target.value)}
              />
            </Box>
            <Box mb={3} className='input-box'>
              <h5>
                <span> Add Image</span> (Optional):
              </h5>

              {imageName ? (
                <div className='file-change'>
                  <div className='name'>{imageName}</div>
                  <div className='buttons'>
                    <div className='file-chose-input'>
                      <label htmlFor='push-img' className='pointer'>
                        Change
                      </label>
                      <input type='file' id='push-img' accept='.jpg,.jpeg,.png' onChange={handleFileUpload} />
                    </div>
                    <span
                      className='pointer'
                      onClick={() => {
                        setImgFile(null);
                        setImageName(null);
                      }}
                    >
                      Remove
                    </span>
                  </div>
                </div>
              ) : (
                <div className='file-chose-input'>
                  <label htmlFor='push-img1' className='pointer'>
                    Chose
                  </label>
                  <input type='file' id='push-img1' accept='.jpg,.jpeg,.png' onChange={handleFileUpload} />
                </div>
              )}
            </Box>

            <Box display='flex' alignItems='center' justifyContent='space-between' mb={2}>
              <div className='left'>
                {/* <Button
                  variant='contained'
                  color='default'
                  onClick={event => {
                    setShowDateTimePopover(showDateTimePopover ? null : event.currentTarget);
                    window.scrollTo({
                      top: 230,
                      behavior: 'smooth',
                    });
                  }}
                >
                  <AccessTimeIcon style={{ fontSize: 16, marginRight: 5 }} /> Select Date & Time
                </Button>
                {dateTime && <div style={{ margin: 5 }}>{moment(dateTime).format('hh:mm A - DD.MM.YYYY')}</div>} */}

                <DateTimePicker
                  value={dateTime}
                  onChange={setDateTime}
                  placeholder='Select Date & Time'
                  maw={400}
                  minDate={new Date()}
                  valueFormat='hh:mm A - DD.MM.YYYY'
                  clearable
                  popoverProps={{ position: 'right' }}
                  className='notifiaction-datepicker'
                  variant='unstyled'
                />
              </div>
              <div className='right'>
                {editData ? (
                  <Button variant='contained' style={{ marginRight: 10 }} color='default' onClick={handleCancleEdit}>
                    Cancel
                  </Button>
                ) : (
                  <Button
                    variant='contained'
                    color='primary'
                    onClick={() => handlePostNotification('pushNow')}
                    style={{ marginRight: 10 }}
                  >
                    Push Now
                  </Button>
                )}

                <Button variant='contained' color='primary' onClick={() => handlePostNotification('save')}>
                  {editData ? 'Update' : 'Save'}
                </Button>
              </div>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <div>
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <Typography className={classes.title} variant='h4' id='tableTitle' component='div'>
                    Category
                  </Typography>
                  <Typography variant='h5' id='tableTitle' component='div' style={{ color: '#fff' }}>
                    .
                  </Typography>
                  <FormControl component='fieldset'>
                    <RadioGroup value={categoryRadio} onChange={e => setCategoryRadio(e.target.value)}>
                      <FormControlLabel value='default' control={<Radio color='primary' />} label='Default' />
                      <FormControlLabel value='events' control={<Radio color='primary' />} label='Events' />
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item xs={8}>
                  <Typography className={classes.title} variant='h4' id='tableTitle' component='div'>
                    Condition
                  </Typography>
                  <Typography variant='h5' id='tableTitle' component='div' style={{ color: '#fff' }}>
                    .
                  </Typography>
                  {categoryRadio === 'default' ? (
                    <FormControl component='fieldset'>
                      <RadioGroup value={'all_user'}>
                        <FormControlLabel value='all_user' control={<Radio color='primary' />} label='All User' />
                      </RadioGroup>
                    </FormControl>
                  ) : (
                    <Box mt={3}>
                      <Typography className={classes.title} variant='h4' id='tableTitle' component='div'>
                        Event Name
                      </Typography>
                      <Box mb={{ xs: 6, md: 4 }} mt={2}>
                        <FormControl size='small' fullWidth variant='outlined'>
                          <InputLabel id='demo-simple-select-label'>Select Event</InputLabel>
                          <Select
                            labelId='demo-simple-select-label'
                            id='demo-simple-select'
                            label='Select Event'
                            value={eventId}
                            onChange={e => setEventId(e.target.value)}
                          >
                            {events &&
                              events.map(event => (
                                <MenuItem value={event._id} key={event._id}>
                                  {event.prize_event_title}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </Box>
                      <Box>
                        <Typography className={classes.title} variant='h4' id='tableTitle' component='div'>
                          Purchase Status
                        </Typography>
                        <FormControl component='fieldset'>
                          <RadioGroup row value={purchaseStatus} onChange={e => setPurchaseStatus(e.target.value)}>
                            <FormControlLabel value='yes' control={<Radio color='primary' />} label='Yes' />
                            <FormControlLabel value='no' control={<Radio color='primary' />} label='No' />
                          </RadioGroup>
                        </FormControl>
                      </Box>
                    </Box>
                  )}
                </Grid>
              </Grid>
            </div>
          </Grid>
        </Grid>
      </Box>

      <Popover
        value={dateTime}
        setValue={setDateTime}
        showPopover={showDateTimePopover}
        setShowPopover={setShowDateTimePopover}
      />
    </Paper>
  );
};

export default GeneralNotification;
