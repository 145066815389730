import { TableCell, TableRow } from '@material-ui/core';
import moment from 'moment';
import React from 'react';
import WatchLaterSharpIcon from '@material-ui/icons/WatchLaterSharp';
import { Delete, Edit } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { setEditNotificationData } from 'redux/actions/Notifications';
import ImageViewer from 'components/ImageViewer.Component';
import { getImgName } from 'common/functions';

const HistoryTableRow = ({ row, addEdit, handleDeleteItem }) => {
  const dispatch = useDispatch();

  const handleEdit = data => {
    // console.log(data);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
    dispatch(setEditNotificationData(data));
  };

  return (
    <TableRow hover role='checkbox' tabIndex={-1} key={row._id} className='notification_his_table_row'>
      <TableCell>
        <div>
          <p className='title'>{row?.data?.title}</p>
          <p className='desc'>{row?.data?.body}</p>
          {row?.data?.image_url && (
            <div className='image'>
              Image: <ImageViewer name={getImgName(row?.data?.image_url)} src={row?.data?.image_url} />
            </div>
          )}
        </div>
      </TableCell>
      <TableCell>
        <div>
          {row?.data?.notification_details?.participants_type === 'prize_event' ? (
            <>
              -- {row?.data?.segment_details?.title} Event <br /> -- Purchase:{' '}
              {row?.data?.notification_details?.participants_status ? 'Yes' : 'No'}
            </>
          ) : (
            <>-- All User -</>
          )}
        </div>
      </TableCell>
      <TableCell>
        <div style={{ display: 'flex' }}>
          <WatchLaterSharpIcon color='primary' style={{ fontSize: 20, marginRight: 5 }} />
          {moment(row?.time).format('hh:mm A - DD.MM.YYYY')}
        </div>
      </TableCell>
      {addEdit && (
        <TableCell>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Edit style={{ cursor: 'pointer' }} color='primary' onClick={() => handleEdit(row)} />
            <Delete style={{ cursor: 'pointer' }} color='error' onClick={() => handleDeleteItem(row)} />
          </div>
        </TableCell>
      )}
    </TableRow>
  );
};

export default HistoryTableRow;
