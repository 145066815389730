import { axiosInstance } from 'utils/axios';
import toast from 'react-hot-toast';
import { C_S_D_SET_ALL_EVENTS, C_S_D_SET_GAMES, C_S_D_SET_GAME_CATEGORIES } from '@jumbo/constants/ActionTypes';

export const commonFetchGames = () => {
  return async dispatch => {
    await axiosInstance
      .get(`/admin/game/game-list`)
      .then(resp => {
        // console.log(resp?.data);
        dispatch({ type: C_S_D_SET_GAMES, payload: resp?.data?.data });
      })
      .catch(error => {
        toast.error('There was something issue in responding server');
      });
  };
};

export const commonFetchAllEvents = () => {
  return async dispatch => {
    await axiosInstance
      .get(`/admin/event/event-list`)
      .then(resp => {
        // console.log(resp?.data);
        dispatch({ type: C_S_D_SET_ALL_EVENTS, payload: resp?.data?.data });
      })
      .catch(error => {
        toast.error('There was something issue in responding server');
      });
  };
};

export const commonFetchGameCategory = (pageSize = 300, page = 1) => {
  return async dispatch => {
    await axiosInstance
      .get(`/admin/game/category/list`, {
        params: { page: page, size: pageSize },
      })
      .then(resp => {
        // console.log(resp?.data);
        dispatch({ type: C_S_D_SET_GAME_CATEGORIES, payload: resp?.data?.data });
      })
      .catch(error => {
        toast.error('There was something issue in responding server');
      });
  };
};
