import React from 'react';
import { DatePickerInput } from '@mantine/dates';

const CustomDatePickerInput = ({ value, setValue, type, clearable, placeholder = 'Pick dates range', minWidth }) => {
  return (
    <div className='custom-datepicker-input'>
      <DatePickerInput
        clearable={clearable}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={setValue}
        mx='auto'
        miw={minWidth}
        allowSingleDateInRange={true}
      />
    </div>
  );
};

export default CustomDatePickerInput;
