import React, { useState } from 'react';
import useStyles from '../index.style';
import { Box, Paper, Toolbar, Typography } from '@material-ui/core';
import ShowingDate from 'components/ShowingDate.Component';
import { useDispatch } from 'react-redux';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import moment from 'moment';
import { DateRangeOutlined } from '@material-ui/icons';
import DatePopover from 'components/OnlydatePopover.Component';
import OPCSingleCard from './OPCSingleCard.Component';
import { Pagination } from '@material-ui/lab';
import Axios from 'axios';
import { useEffect } from 'react';

const pageSize = 10;

const OPCPastOffer = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [showPopover, setShowPopover] = useState(null);
  const open = Boolean(showPopover);
  const id = open ? 'simple-popper' : undefined;

  const [data, setData] = useState(null);
  const [dateRange, setDateRange] = useState([null, null]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationDisabled, setPaginationDisabled] = useState(false);

  useEffect(() => {
    getPastReferrals();
  }, []);

  const getPastReferrals = (startDate, endDate, page = 1, callback) => {
    dispatch(fetchStart());
    setPaginationDisabled(true);
    const start = startDate || '';
    const end = endDate || '';

    Axios.get(`${process.env.REACT_APP_BASE_URL}/admin/referral/history`, {
      params: {
        page: page,
        size: pageSize,
        start_time: start,
        end_time: end,
      },
    })
      .then(resp => {
        // console.log(resp?.data);
        setTotalPages(Math.ceil(resp?.data?.total_count / pageSize));
        setData(resp?.data);
        dispatch(fetchSuccess());
        setPaginationDisabled(false);
        if (callback && typeof callback === 'function') callback();
      })
      .catch(error => {
        dispatch(fetchError(error?.response?.data?.message || 'Sorry, something went wrong. Please try again later.'));
        setPaginationDisabled(false);
        // console.log(error?.response);
      });
  };

  const handleApplyDateRange = () => {
    setCurrentPage(1);

    if (!dateRange[0]) {
      dispatch(fetchError('Please select start date.'));
      return;
    }

    if (!dateRange[1]) {
      dispatch(fetchError('Please select end date.'));
      return;
    }

    const startDateFormat = moment(dateRange[0]).format('YYYY-MM-DDT00:00:00+06:00');
    const endDateFormat = moment(dateRange[1]).format('YYYY-MM-DDT23:59:59+06:00');

    getPastReferrals(startDateFormat, endDateFormat, 1, () => {
      setIsFilterApplied(true);
      setShowPopover(null);
    });
  };

  const handleDateClear = () => {
    setCurrentPage(1);
    getPastReferrals();
    // fetchData('', '', setIsFilterApplied(false));
    setIsFilterApplied(false);
    setDateRange([null, null]);
  };

  const handlePagination = (e, page) => {
    setCurrentPage(page);
    // console.log(dateRange);
    const startDate = dateRange[0] ? moment(dateRange[0]).format('YYYY-MM-DDT00:00:00+06:00') : '';
    const endDate = dateRange[1] ? moment(dateRange[1]).format('YYYY-MM-DDT23:59:59+06:00') : '';
    getPastReferrals(startDate, endDate, page);
  };

  return (
    <div className='past-offer'>
      <Paper className={classes.paper}>
        <Toolbar className={classes.root} style={{ justifyContent: 'space-between' }}>
          <Box>
            <Typography className={classes.title} variant='h4' id='tableTitle' component='div'>
              Past Offers: {data?.total_count}
            </Typography>

            {isFilterApplied && <ShowingDate dateRange={dateRange} handleClear={handleDateClear} />}
          </Box>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <button
              aria-describedby={id}
              className='date-range-picker'
              onClick={event => setShowPopover(showPopover ? null : event.currentTarget)}
            >
              <DateRangeOutlined style={{ fill: '#fff', width: 20 }} />
            </button>
          </div>
        </Toolbar>

        <DatePopover
          id={id}
          open={open}
          showPopover={showPopover}
          setShowPopover={setShowPopover}
          dateRange={dateRange}
          setDateRange={setDateRange}
          handleApplyDateRange={handleApplyDateRange}
          type='range'
          allowSingleDateInRange
          maxDate={new Date()}
        />
      </Paper>

      {data && data?.data?.length > 0 ? (
        data?.data?.map(data => <OPCSingleCard data={data} key={data?._id} />)
      ) : (
        <Paper className={classes.paper}>
          <Box padding={3}>
            <Typography>There are no records found.</Typography>
          </Box>
        </Paper>
      )}

      {totalPages > 1 && (
        <Box p={3} display='flex' justifyContent='end'>
          <Pagination
            disabled={paginationDisabled}
            page={currentPage}
            count={totalPages}
            color='primary'
            onChange={handlePagination}
          />
        </Box>
      )}
    </div>
  );
};

export default OPCPastOffer;
