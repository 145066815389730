import React, { useState } from 'react';
import useStyles from '../index.style';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Box } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import PGModal from './PGModal.Component';
import NoRecordFound from 'components/NoRecordFound.Component';

const tableHeader = ['User Name', 'Login no.', 'Offer Claimed', 'Status', 'Category', 'Action'];

const PGTable = ({ currentPage, paginationDisabled, totalPages, data, handlePagination, fetchInitial }) => {
  const classes = useStyles();

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  function handleViewModal(data) {
    setModalData(data);
    setShowModal(true);
  }

  function handleCloseModal() {
    setModalData(null);
    setShowModal(false);
  }

  function getCategoryName(name) {
    return name.split('_').join(' ');
  }

  function getStatusColor(status) {
    if (status === 'PENDING') {
      return 'text-danger';
    }

    if (status === 'RELEASED') {
      return 'text-warning';
    }

    if (status === 'DELIVERED') {
      return 'text-success';
    }

    return '';
  }

  return (
    <>
      <TableContainer className={classes.container}>
        <Table className='' stickyHeader aria-labelledby='tableTitle' aria-label='sticky enhanced table '>
          <TableHead>
            <TableRow>
              {tableHeader?.map((d, i) => (
                <TableCell key={i} align='left' padding={'normal'} style={{ background: '#fff' }}>
                  {d}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {data && (
            <TableBody>
              {data?.data?.length > 0 ? (
                data.data.map(user => (
                  <TableRow key={user?._id}>
                    {/* <TableCell align='left' padding={'normal'}>
                      {1}
                    </TableCell> */}
                    <TableCell align='left' padding={'normal'}>
                      {user?.user_info?.user_name}
                    </TableCell>
                    <TableCell align='left' padding={'normal'}>
                      {user?.user_info?.phone}
                    </TableCell>
                    <TableCell align='left' padding={'normal'}>
                      {user?.referral_gift_info?.title}
                    </TableCell>
                    <TableCell align='left' padding={'normal'}>
                      <div className={getStatusColor(user?.delivery_status)}>{user?.delivery_status}</div>
                    </TableCell>
                    <TableCell align='left' padding={'normal'} className='text-capitalize'>
                      {getCategoryName(user?.referral_gift_info?.type)}
                    </TableCell>
                    <TableCell
                      align='left'
                      padding={'normal'}
                      className='pointer'
                      onClick={() => handleViewModal(user)}
                    >
                      <div className='text-primary'>View</div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow style={{ height: 53 * 6 }}>
                  <TableCell colSpan={11} rowSpan={7}>
                    <NoRecordFound>There are no records found.</NoRecordFound>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      {totalPages > 1 && (
        <Box p={3} display='flex' justifyContent='end' style={{ backgroundColor: '#fff' }}>
          <Pagination
            disabled={paginationDisabled}
            page={currentPage}
            count={totalPages}
            color='primary'
            onChange={handlePagination}
          />
        </Box>
      )}

      <PGModal
        open={showModal}
        handleCloseModal={handleCloseModal}
        data={modalData}
        getCategoryName={getCategoryName}
        fetchInitial={fetchInitial}
      />
    </>
  );
};

export default PGTable;
