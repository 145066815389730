import React, { useCallback, useEffect, useState } from 'react';
import useStyles from './index.style';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core';
import CmtSearch from '@coremat/CmtSearch';
import { SET_CATEGORY_CURRENT_PAGE, SET_CATEGORY_SEARCH, SET_SELECTED_CATEGORY } from '@jumbo/constants/ActionTypes';
import { deleteCategory, getAllCategories } from 'redux/actions/Categories';
import NoRecordFound from 'components/NoRecordFound.Component';
import { Delete, Edit, MoreHoriz } from '@material-ui/icons';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import { Pagination } from '@material-ui/lab';
import ConfirmDialog from '@jumbo/components/Common/ConfirmDialog';
import { Toaster } from 'react-hot-toast';
import AddEditCategory from './components/AddEditCategory.Component';
import { commonFetchGameCategory } from 'redux/actions/commonDataFetch';

const pageSize = 20;

const Category = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { categories, search, totalPages, selected_category, paginationDisabled, currentPage } = useSelector(
    ({ categoryReducer }) => categoryReducer
  );

  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    if (!categories) {
      dispatch(getAllCategories(pageSize));
    }
  }, [dispatch]);

  const handleSearch = e => {
    e.preventDefault();

    // console.log(search);
    // setCurrentPage(1);
    dispatch({ type: SET_CATEGORY_CURRENT_PAGE, payload: 1 });
    dispatch(getAllCategories(pageSize, 1, search));
  };

  const handleEditCategory = useCallback(
    category => {
      dispatch({ type: SET_SELECTED_CATEGORY, payload: category });
      setShowAddEditModal(true);
    },
    [dispatch]
  );

  const handleDeleteCategory = useCallback(
    category => {
      dispatch({ type: SET_SELECTED_CATEGORY, payload: category });
      setShowDeleteModal(true);
    },
    [dispatch]
  );

  const handlePagination = (e, page) => {
    // setCurrentPage(page);
    // fetchData(search, page);
    // console.log(page);
    dispatch({ type: SET_CATEGORY_CURRENT_PAGE, payload: page });
    dispatch(getAllCategories(pageSize, page, search));
  };

  const resetAllModal = () => {
    dispatch({ type: SET_SELECTED_CATEGORY, payload: null });
    setShowAddEditModal(false);
    setShowDeleteModal(false);
  };

  const fetchAfterActions = () => {
    dispatch(getAllCategories(pageSize, currentPage, search));
    resetAllModal();
  };

  const handleConfirmDelete = () => {
    dispatch(
      deleteCategory(selected_category?._id, () => {
        fetchAfterActions();
        dispatch(commonFetchGameCategory());
      })
    );
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar className={classes.root} display='flex' style={{ justifyContent: 'space-between' }}>
          <Box display='flex' alignItems='center'>
            <Typography className={classes.title} variant='h4' id='tableTitle' component='div'>
              Total Category: {categories?.total_count || 0}
            </Typography>
            <Button
              type='submit'
              style={{ marginLeft: 10 }}
              variant='contained'
              color='primary'
              onClick={() => setShowAddEditModal(true)}
            >
              Add new category
            </Button>
          </Box>

          <form onSubmit={handleSearch}>
            <Box display='flex'>
              <CmtSearch
                onChange={e => dispatch({ type: SET_CATEGORY_SEARCH, payload: e.target.value })}
                value={search}
                border={true}
                onlyIcon={false}
              />
              <Button type='submit' style={{ marginLeft: 10 }} variant='contained' color='primary'>
                Search
              </Button>
            </Box>
          </form>
        </Toolbar>

        <TableContainer className={classes.container}>
          <Table stickyHeader>
            <CategoryTableHead />
            {categories && (
              <TableBody>
                {categories?.data?.length > 0 ? (
                  categories?.data?.map(category => (
                    <CategoryTableBody
                      key={category?._id}
                      category={category}
                      handleEditCategory={handleEditCategory}
                      handleDeleteCategory={handleDeleteCategory}
                    />
                  ))
                ) : (
                  <TableRow style={{ height: 200 }}>
                    <TableCell colSpan={11} rowSpan={7}>
                      <NoRecordFound>There are no records found.</NoRecordFound>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        {totalPages > 1 && (
          <Box px={4} py={5} display='flex' justifyContent='flex-end'>
            <Pagination
              disabled={paginationDisabled}
              page={currentPage}
              count={totalPages}
              color='primary'
              onChange={handlePagination}
            />
          </Box>
        )}

        {showAddEditModal && (
          <AddEditCategory
            open={showAddEditModal}
            closeModal={resetAllModal}
            fetchAfterActions={fetchAfterActions}
            // selectedGame={selectedGame}
            // fetchDataAfterActions={fetchDataAfterActions}
          />
        )}

        {showDeleteModal && (
          <ConfirmDialog
            open={showDeleteModal}
            title={`Confirm Delete ${selected_category?.category_name}`}
            content='Are you sure to delete this category?'
            onClose={resetAllModal}
            onConfirm={handleConfirmDelete}
          />
        )}
      </Paper>

      <Toaster position='top-right' toastOptions={{ duration: 3000 }} />
    </div>
  );
};

export default Category;

const CategoryTableBody = React.memo(({ category, handleEditCategory, handleDeleteCategory }) => {
  const onCategoryMenuClick = menu => {
    if (menu.action === 'edit') {
      handleEditCategory(category);
    } else if (menu.action === 'delete') {
      handleDeleteCategory(category);
    }
  };

  const getCategoryActions = () => {
    const actions = [
      { action: 'edit', label: 'Edit', icon: <Edit /> },
      { action: 'delete', label: 'Delete', icon: <Delete /> },
    ];
    return actions;
  };

  const categoryActions = getCategoryActions(category);

  return (
    <TableRow>
      <TableCell align='left'>
        <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
          <img
            style={{ borderRadius: 50 }}
            width={40}
            height={40}
            src={category?.category_image_url}
            alt={category?.category_name}
          />
          {category?.category_name}
        </div>
      </TableCell>
      <TableCell align='center'>
        {category?.gameList?.map((game, i, array) => (
          <p key={i}>
            {game?.game_title}
            {array.length !== i + 1 && ', '}
          </p>
        ))}
      </TableCell>
      <TableCell align='center' onClick={e => e.stopPropagation()}>
        <CmtDropdownMenu items={categoryActions} onItemClick={onCategoryMenuClick} TriggerComponent={<MoreHoriz />} />
      </TableCell>
    </TableRow>
  );
});

const CategoryTableHead = React.memo(() => {
  const tableHeader = ['Category Name', 'Assigned Game', 'Actions'];

  return (
    <TableHead>
      <TableRow>
        {tableHeader?.map((d, i) => (
          <TableCell key={i} align={i === 0 ? 'left' : 'center'} padding={'normal'}>
            {d}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
});
