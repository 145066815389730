import React from 'react';
import Viewer from 'react-viewer';

const ImageViewer = ({ src = 'https://via.placeholder.com/600/92c952', name = 'view' }) => {
  const [visible, setVisible] = React.useState(false);

  return (
    <div>
      <button
        className='pointer image--view--btn'
        onClick={() => setVisible(true)}
        style={{ border: 'none', outline: 'none', background: 'none' }}
      >
        {name}
      </button>
      <Viewer
        visible={visible}
        drag={false}
        zoomable={false}
        showTotal={false}
        loop={false}
        disableMouseZoom={true}
        noFooter={true}
        noToolbar={true}
        onClose={() => setVisible(false)}
        images={[
          {
            src: src,
            alt: name,
          },
        ]}
      />
    </div>
  );
};

export default ImageViewer;
