import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core';
import useStyles from '../index.style';
import NoRecordFound from 'components/NoRecordFound.Component';
import { Pagination } from '@material-ui/lab';
import { convertSeconds } from 'common/functions';
import { handleDownloadUserCSV } from '../functions';
import { useDispatch, useSelector } from 'react-redux';
import { getTotalData } from 'redux/actions/Analytic';
import { SET_TOTAL_CURRENT_PAGE } from '@jumbo/constants/ActionTypes';

const pageSize = 20;

const AllGames = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let { totalGames } = useSelector(({ analyticReducer }) => analyticReducer);

  useEffect(() => {
    if (!totalGames?.data) {
      dispatch(getTotalData(pageSize));
    }
  }, [dispatch]);

  const handlePagination = (e, page) => {
    // setCurrentPageTotal(page);
    // getTotalData(page);
    // console.log(dateRange);
    dispatch({ type: SET_TOTAL_CURRENT_PAGE, payload: page });
    dispatch(getTotalData(pageSize, page));
  };

  return (
    <Paper className={classes.paper}>
      <Toolbar className={classes.root} style={{ justifyContent: 'space-between' }}>
        <div>
          <Typography className={classes.title} variant='h4' id='tableTitle' component='div'>
            Total Users: {totalGames?.data?.total_user_count}
          </Typography>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            onClick={() => handleDownloadUserCSV('export_user_table', 'user_table')}
            variant='contained'
            color='primary'
          >
            Export
          </Button>
        </div>
      </Toolbar>

      <TableContainer className={classes.container}>
        <Table stickyHeader className='export_user_table'>
          <TotalTableHead />
          {totalGames && (
            <TableBody>
              {totalGames?.data?.data?.length > 0 ? (
                totalGames?.data?.data?.map((game, i) => <AllTableBody key={game?._id} game={game} />)
              ) : (
                <TableRow style={{ height: 200 }}>
                  <TableCell colSpan={11} rowSpan={7}>
                    <NoRecordFound>There are no records found.</NoRecordFound>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      <TotalRow totalData={totalGames?.data} classes={classes} />

      {totalGames?.totalPages > 1 && (
        <Box px={4} py={5} display='flex' justifyContent='flex-end'>
          <Pagination
            disabled={totalGames?.paginationDisabled}
            page={totalGames?.currentPage}
            count={totalGames?.totalPages}
            color='primary'
            onChange={handlePagination}
          />
        </Box>
      )}
    </Paper>
  );
};

export default AllGames;

const AllTableBody = React.memo(({ game }) => {
  return (
    <TableRow>
      <TableCell align='left' padding={'normal'} style={{ paddingLeft: 20, width: '50%' }}>
        {game?.game_title}
      </TableCell>
      <TableCell align='center' padding={'normal'} style={{ width: '15%' }}>
        {game?.game_total_player || 0}
      </TableCell>
      <TableCell align='center' padding={'normal'} style={{ width: '13%' }}>
        {game?.total_game_play_count || 0}
      </TableCell>
      <TableCell align='center' padding={'normal'} style={{ width: '22%' }}>
        {game?.total_session_time ? convertSeconds(game?.total_session_time) : '0d 0h 0m 0s'}
      </TableCell>
    </TableRow>
  );
});

const TotalTableHead = React.memo(() => {
  const tableHeader = ['Game Name', 'Player Count', 'Play Count', 'Session Time'];

  return (
    <TableHead>
      <TableRow>
        {tableHeader?.map((d, i) => (
          <TableCell key={i} align={i === 0 ? 'left' : 'center'} padding={'normal'}>
            {d}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
});

const TotalRow = React.memo(({ classes, totalData }) => {
  return (
    <TableContainer className={classes.container}>
      <Table
        stickyHeader
        className='export_user_table analytic_table'
        aria-labelledby='tableTitle'
        aria-label='sticky enhanced table'
      >
        <TableBody>
          <TableRow className='analytic-footer-total'>
            <TableCell align='left' className={classes.total} style={{ width: '50%' }}>
              Total
            </TableCell>
            <TableCell align='center' className={classes.total} style={{ width: '15%' }}>
              {totalData?.total_player_count}
            </TableCell>
            <TableCell align='center' className={classes.total} style={{ width: '13%' }}>
              {totalData?.total_play_count}
            </TableCell>
            <TableCell align='center' className={classes.total} style={{ width: '22%' }}>
              {totalData?.total_session_time ? convertSeconds(totalData?.total_session_time) : '0d 0h 0m 0s'}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
});
