import { Box, Paper } from '@material-ui/core';
import React from 'react';
import useStyles from '../index.style';
import ConfirmDialog from '@jumbo/components/Common/ConfirmDialog';
import { useState } from 'react';
import Axios from 'axios';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import { useDispatch } from 'react-redux';
import moment from 'moment';

const OPCSingleCard = ({ isEditable, setOpenModal, data, setEditData, setDiscountData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [showConfirm, setShowConfirm] = useState(false);

  const [showClaimedBy, setShowClaimedBy] = useState(false);
  const [claimedBy, setClaimedBy] = useState(null);

  const handleCancelDisabled = () => {
    setShowConfirm(false);
  };

  const disabledOffer = id => {
    dispatch(fetchStart());
    Axios.patch(`${process.env.REACT_APP_BASE_URL}/admin/referral/toggle-offer/${id}`)
      .then(resp => {
        // console.log(resp?.data);
        // console.log(discountData);
        setShowConfirm(false);
        dispatch(fetchSuccess('Offer updated successfully.'));
        setDiscountData(prevData => {
          return {
            ...prevData,
            data: prevData.data.map(d => {
              if (d._id === resp.data.data._id) {
                return {
                  ...d,
                  is_enabled: resp.data.data.is_enabled,
                };
              }
              return {
                ...d,
              };
            }),
          };
        });
      })
      .catch(error => {
        // console.log(error.response);
        dispatch(fetchError('Sorry, something went wrong please try again later.'));
        setShowConfirm(false);
      });
  };

  const handleConfirmDisabled = () => {
    // setShowConfirm(false);
    // console.log(data);
    disabledOffer(data?._id);
  };

  const handleDisable = () => {
    setShowConfirm(true);
  };

  const handleEditModal = data => {
    setEditData(data);
    setOpenModal(true);
  };

  const getCategoryName = data => {
    // console.log(data);
    if (data?.category === 'physical_good') {
      return `Product (${data?.gift?.gift_type})`;
    } else if (data?.category === 'discount_in_event') {
      if (data?.gift?.gift_title) {
        // return `${data?.gift?.gift_title} Event`;
        return `Specific Event (${data?.gift?.gift_title})`;
      } else {
        return 'Any Event';
      }
    } else {
      return 'Any Event';
    }
  };

  const handleShowClaimed = id => {
    // console.log(id);

    Axios.get(`${process.env.REACT_APP_BASE_URL}/admin/referral/claim-count/${id}`)
      .then(resp => {
        // console.log(resp?.data?.data);
        // setEventsAndGoods(resp?.data?.data);
        setClaimedBy(resp?.data?.data);
        setShowClaimedBy(true);
      })
      .catch(error => {
        // console.log(error.response);
      });
  };

  return (
    <Paper className={classes.paper + ' card'}>
      <Box p={3} display='flex' justifyContent='space-between'>
        <div className='left'>
          <p>
            <span className='fw-600'>Offer Name: </span>
            {data?.title}
          </p>
          <p>
            <span className='fw-600'>Offer Category: </span>
            {getCategoryName(data)}
          </p>
          {data?.category === 'physical_good' && (
            <p>
              <span className='fw-600'>Product: </span>
              <span className='text-capitalize'>{data?.gift?.gift_id}</span>
            </p>
          )}
          {data?.category === 'discount_in_event' && (
            <p>
              <span className='fw-600'>Offer Discount: </span>
              {data?.discount_percentage}%
            </p>
          )}
          <p>
            <span className='fw-600'>Required Points: </span>
            {data?.required_point}
          </p>
          <p>
            <span className='fw-600'>Offer Timeline: </span>
            {moment(data?.claim_start_date).format('hh.mm A, DD MMM YY')} -{' '}
            {moment(data?.claim_end_date).format('hh.mm A, DD MMM YY')}
          </p>
          {data?.category === 'discount_in_event' && (
            <p>
              <span className='fw-600'>Offer Expire: </span>
              {moment(data?.use_end_date).format('hh.mm A, DD MMM YY')}
            </p>
          )}
        </div>
        <div className='right'>
          <p style={{ minWidth: 200 }}>
            {showClaimedBy ? (
              <>
                <span className='fw-600'>Offer Claimed By: </span>
                {claimedBy || 0}
              </>
            ) : (
              <span className='fw-600 text-primary pointer' onClick={() => handleShowClaimed(data?._id)}>
                View
              </span>
            )}
          </p>
          {isEditable && (
            <>
              <p>
                <span className='fw-600'>Action: </span>
              </p>
              <Box>
                <span
                  className='fw-600 text-primary pointer'
                  style={{ marginRight: 10 }}
                  onClick={() => handleEditModal(data)}
                >
                  Edit
                </span>
                <span
                  className='fw-600 pointer'
                  style={{ color: data?.is_enabled ? 'gray' : '#4caf50' }}
                  onClick={handleDisable}
                >
                  {data?.is_enabled ? 'Disable' : 'Enable'}
                </span>
              </Box>
            </>
          )}
        </div>
      </Box>

      <ConfirmDialog
        open={showConfirm}
        title={'Disable Discount Offer'}
        content={'Are you sure you want to disable the offer?'}
        onClose={handleCancelDisabled}
        onConfirm={handleConfirmDisabled}
      />
    </Paper>
  );
};

export default OPCSingleCard;
