import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchError } from 'redux/actions';
import useStyles from '../index.style';
import DatePopover from 'components/OnlydatePopover.Component';

import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core';
import ShowingDate from 'components/ShowingDate.Component';
import { DateRangeOutlined } from '@material-ui/icons';
import {
  SET_PAID_USER_ALL_DATE,
  SET_PAID_USER_ALL_FILTER,
  SET_PAID_USER_REGISTERED_DATE,
  SET_PAID_USER_REGISTERED_FILTER,
} from '@jumbo/constants/ActionTypes';
import { getPaidUserAllTimeData, getPaidUserRegisteredData } from 'redux/actions/Analytic';

const PaidUsers = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let { paidUsers } = useSelector(({ analyticReducer }) => analyticReducer);

  // console.log(paidUsers);

  useEffect(() => {
    if (!paidUsers?.paidUserAll) {
      dispatch(getPaidUserAllTimeData());
    }

    if (!paidUsers?.paidUserRegistered) {
      dispatch(getPaidUserRegisteredData());
    }
  }, [dispatch]);

  return (
    <>
      <PaidUserAll dispatch={dispatch} classes={classes} data={paidUsers} />
      <PaidUserRegistered dispatch={dispatch} classes={classes} data={paidUsers} />
    </>
  );
};

export default PaidUsers;

const PaidUserAll = React.memo(({ dispatch, classes, data }) => {
  const [showPopover, setShowPopover] = useState(null);
  const open = Boolean(showPopover);
  const id = open ? 'simple-popper' : undefined;

  const handleApplyDateRange = () => {
    if (!data?.paidUserAllDate[0]) {
      dispatch(fetchError('Please select start date.'));
      return;
    }

    if (!data?.paidUserAllDate[1]) {
      dispatch(fetchError('Please select end date.'));
      return;
    }

    const startDateFormat = moment(data?.paidUserAllDate[0]).format('YYYY-MM-DDT00:00:00+06:00');
    const endDateFormat = moment(data?.paidUserAllDate[1]).format('YYYY-MM-DDT23:59:59+06:00');

    dispatch(
      getPaidUserAllTimeData(startDateFormat, endDateFormat, () => {
        dispatch({ type: SET_PAID_USER_ALL_FILTER, payload: true });
      })
    );

    setShowPopover(null);
  };

  const handleDateClear = () => {
    dispatch(
      getPaidUserAllTimeData('', '', () => {
        dispatch({ type: SET_PAID_USER_ALL_FILTER, payload: false });
      })
    );
    setDateRange([null, null]);
  };

  const tableHeader = ['New Users', 'Paid Users', 'Recurring User'];

  const setDateRange = value => {
    dispatch({ type: SET_PAID_USER_ALL_DATE, payload: value });
  };

  return (
    <Paper className={classes.paper}>
      <Toolbar className={classes.root} style={{ justifyContent: 'space-between' }}>
        <Box>
          <Typography className={classes.title} variant='h4' id='tableTitle' component='div'>
            Paid User Data Report (All Time Registered)
          </Typography>

          {data?.isFilterAppliedAll && <ShowingDate dateRange={data?.paidUserAllDate} handleClear={handleDateClear} />}
        </Box>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <button
            aria-describedby={id}
            className='date-range-picker'
            onClick={event => setShowPopover(showPopover ? null : event.currentTarget)}
          >
            <DateRangeOutlined style={{ fill: '#fff', width: 20 }} />
          </button>
        </div>
      </Toolbar>

      <TableContainer className={classes.container}>
        <Table
          className='export_paid_user_table1 analytic_table1'
          aria-labelledby='tableTitle'
          aria-label='sticky enhanced table '
        >
          <TableHead style={{ background: '#f4f4f7' }}>
            <TableRow>
              {tableHeader?.map((d, i) => (
                <TableCell key={i} align='center' padding={'normal'}>
                  {d}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align='center' padding={'normal'}>
                {data?.paidUserAll?.total_user || 0}
              </TableCell>
              <TableCell align='center' padding={'normal'}>
                {data?.paidUserAll?.paid_user || 0}
              </TableCell>
              <TableCell align='center' padding={'normal'}>
                {data?.paidUserAll?.recurring_paid_user || 0}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <DatePopover
        id={id}
        open={open}
        showPopover={showPopover}
        setShowPopover={setShowPopover}
        dateRange={data?.paidUserAllDate}
        setDateRange={setDateRange}
        handleApplyDateRange={handleApplyDateRange}
        type='range'
        allowSingleDateInRange
      />
    </Paper>
  );
});

const PaidUserRegistered = React.memo(({ dispatch, classes, data }) => {
  const [showPopover, setShowPopover] = useState(null);
  const open = Boolean(showPopover);
  const id = open ? 'simple-popper' : undefined;

  // console.log(data);

  const tableHeader = ['Registered User', 'Paid Users', 'Recurring User', 'Retention Rate', 'Conversion Rate'];

  const handleApplyDateRange = () => {
    if (!data?.paidUserRegisteredDate[0]) {
      dispatch(fetchError('Please select start date.'));
      return;
    }

    if (!data?.paidUserRegisteredDate[1]) {
      dispatch(fetchError('Please select end date.'));
      return;
    }

    const startDateFormat = moment(data?.paidUserRegisteredDate[0]).format('YYYY-MM-DDT00:00:00+06:00');
    const endDateFormat = moment(data?.paidUserRegisteredDate[1]).format('YYYY-MM-DDT23:59:59+06:00');

    dispatch(
      getPaidUserRegisteredData(startDateFormat, endDateFormat, () => {
        dispatch({ type: SET_PAID_USER_REGISTERED_FILTER, payload: true });
      })
    );

    // console.log(startDateFormat);
    // console.log(endDateFormat);
    setShowPopover(null);
  };

  const handleDateClear = () => {
    dispatch(
      getPaidUserRegisteredData('', '', () => {
        dispatch({ type: SET_PAID_USER_REGISTERED_FILTER, payload: false });
      })
    );
    setDateRange([null, null]);
  };

  const setDateRange = value => {
    dispatch({ type: SET_PAID_USER_REGISTERED_DATE, payload: value });
  };

  return (
    <Paper className={classes.paper}>
      <Toolbar className={classes.root} style={{ justifyContent: 'space-between' }}>
        <Box>
          <Typography className={classes.title} variant='h4' id='tableTitle' component='div'>
            Paid User Data Report (Within Date Registered)
          </Typography>

          {data?.isFilterAppliedRegistered && (
            <ShowingDate dateRange={data?.paidUserRegisteredDate} handleClear={handleDateClear} />
          )}
        </Box>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <button
            aria-describedby={id}
            className='date-range-picker'
            onClick={event => setShowPopover(showPopover ? null : event.currentTarget)}
          >
            <DateRangeOutlined style={{ fill: '#fff', width: 20 }} />
          </button>
        </div>
      </Toolbar>

      <TableContainer className={classes.container}>
        <Table
          className='export_paid_user_table1 analytic_table1'
          aria-labelledby='tableTitle'
          aria-label='sticky enhanced table '
        >
          <TableHead style={{ background: '#f4f4f7' }}>
            <TableRow>
              {tableHeader?.map((d, i) => (
                <TableCell key={i} align='center' padding={'normal'}>
                  {d}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell align='center' padding={'normal'}>
                {data?.paidUserRegistered?.total_user || 0}
              </TableCell>
              <TableCell align='center' padding={'normal'}>
                {data?.paidUserRegistered?.paid_user || 0}
              </TableCell>
              <TableCell align='center' padding={'normal'}>
                {data?.paidUserRegistered?.recurring_paid_user || 0}
              </TableCell>
              <TableCell align='center' padding={'normal'}>
                {data?.paidUserRegistered?.retention_rate || 0}%
              </TableCell>
              <TableCell align='center' padding={'normal'}>
                {data?.paidUserRegistered?.conversion_rate || 0}%
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <DatePopover
        id={id}
        open={open}
        showPopover={showPopover}
        setShowPopover={setShowPopover}
        dateRange={data?.paidUserRegisteredDate}
        setDateRange={setDateRange}
        handleApplyDateRange={handleApplyDateRange}
        type='range'
        allowSingleDateInRange
      />
    </Paper>
  );
});
