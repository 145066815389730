import React, { useCallback, useEffect, useState } from 'react';
import useStyles from './index.style';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import CmtSearch from '@coremat/CmtSearch';
import { axiosInstance } from 'utils/axios';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import { Pagination } from '@material-ui/lab';
import NoRecordFound from 'components/NoRecordFound.Component';
import moment from 'moment';
import { Delete, Edit, Visibility, MoreHoriz } from '@material-ui/icons';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import AddEditModal from './components/AddEditModal.Compoent';
import { Toaster, toast } from 'react-hot-toast';
import ConfirmDialog from '@jumbo/components/Common/ConfirmDialog';
import PEView from './components/PrizeView.Component';
import { commonFetchAllEvents, commonFetchGames } from 'redux/actions/commonDataFetch';

const pageSize = 20;

const EventList = () => {
  const classes = useStyles();
  const { games: gameList } = useSelector(({ CommonSharedData }) => CommonSharedData);
  const dispatch = useDispatch();

  const [search, setSearch] = useState('');

  const [data, setData] = useState(null);
  const [paginationDisabled, setPaginationDisabled] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showViewModal, setShowViewModal] = useState(false);
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    fetchData();

    if (!gameList) {
      dispatch(commonFetchGames());
    }
  }, []);

  const fetchData = (search, page = 1) => {
    dispatch(fetchStart());
    setPaginationDisabled(true);
    const searchTxt = search || '';

    axiosInstance
      .get(`/admin/event/running-and-upcoming`, {
        params: { page: page, size: pageSize, search: searchTxt },
      })
      .then(resp => {
        // console.log(resp.data);
        setTotalPages(Math.ceil(resp?.data?.total_count / pageSize));
        setData(resp?.data);
        dispatch(fetchSuccess());
        setPaginationDisabled(false);
      })
      .catch(error => {
        // console.log(error.response);
        setPaginationDisabled(false);
        dispatch(fetchError('Sorry, something went wrong. Please try again later.'));
      });
  };

  const handlePagination = (e, page) => {
    setCurrentPage(page);
    fetchData(search, page);
    // console.log(dateRange);
  };

  const handleSearch = e => {
    e.preventDefault();

    // console.log(search);
    setCurrentPage(1);
    fetchData(search, 1);
  };

  const handleEditEvent = useCallback(event => {
    // console.log('ban user', user);
    setSelectedEvent(event);
    setShowAddEditModal(true);
  }, []);

  const handleDeleteEvent = useCallback(event => {
    // console.log('ban user', user);
    setSelectedEvent(event);
    setShowDeleteModal(true);
  }, []);

  const resetModals = () => {
    setShowDeleteModal(false);
    setShowAddEditModal(false);
    setShowViewModal(false);
    setSelectedEvent(null);
  };

  const fetchEventsAfterBan = () => {
    fetchData(search, currentPage);
  };

  const handleConfirmDelete = () => {
    // console.log(selectedEvent);

    dispatch(fetchStart());
    axiosInstance
      .delete(`/admin/event/delete/${selectedEvent?._id}`)
      .then(resp => {
        // console.log(resp.data);
        dispatch(fetchSuccess());
        fetchEventsAfterBan();
        resetModals();
        toast.success('Event deleted successfully.');
        dispatch(commonFetchAllEvents());
      })
      .catch(error => {
        // console.log(error.response);
        toast.error('Sorry, something went wrong. Please try again later.');
        dispatch(fetchError());
      });
  };

  const handleViewEvent = useCallback(event => {
    dispatch(fetchStart());

    axiosInstance
      .get(`/admin/event/leaderboard`, {
        params: {
          prize_event_id: event._id,
          winner_limit: event?.prizes?.length,
        },
      })
      .then(resp => {
        // console.log(resp?.data);
        setSelectedEvent({ ...event, winners: resp.data?.data });
        // console.log({ ...event, winners: resp.data?.data });
        setShowViewModal(true);
        dispatch(fetchSuccess());
      })
      .catch(error => {
        dispatch(fetchError());
        // console.log(error.response);
        toast.error('Sorry, something went wrong. Please try again later.');
      });
  }, []);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar className={classes.root} display='flex' style={{ justifyContent: 'space-between' }}>
          <Box display='flex' alignItems='center'>
            <Typography className={classes.title} variant='h4' id='tableTitle' component='div'>
              Total Events: {data?.total_count || 0}
            </Typography>
            <Button
              type='submit'
              style={{ marginLeft: 10 }}
              variant='contained'
              color='primary'
              onClick={() => setShowAddEditModal(true)}
            >
              Add new event
            </Button>
          </Box>

          <form onSubmit={handleSearch}>
            <Box display='flex'>
              <CmtSearch onChange={e => setSearch(e.target.value)} value={search} border={true} onlyIcon={false} />
              <Button type='submit' style={{ marginLeft: 10 }} variant='contained' color='primary'>
                Search
              </Button>
            </Box>
          </form>
        </Toolbar>

        <TableContainer className={classes.container}>
          <Table stickyHeader>
            <EventTableHead />
            {data && (
              <TableBody>
                {data?.data?.length > 0 ? (
                  data?.data?.map(event => (
                    <EventTableBody
                      key={event?._id}
                      event={event}
                      handleViewEvent={handleViewEvent}
                      handleEditEvent={handleEditEvent}
                      handleDeleteEvent={handleDeleteEvent}
                    />
                  ))
                ) : (
                  <TableRow style={{ height: 200 }}>
                    <TableCell colSpan={11} rowSpan={7}>
                      <NoRecordFound>There are no records found.</NoRecordFound>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        {totalPages > 1 && (
          <Box px={4} py={5} display='flex' justifyContent='flex-end'>
            <Pagination
              disabled={paginationDisabled}
              page={currentPage}
              count={totalPages}
              color='primary'
              onChange={handlePagination}
            />
          </Box>
        )}

        {showViewModal && (
          <PEView open={showViewModal} closeModal={resetModals} selectedEvent={selectedEvent} setData={setData} />
        )}

        {showAddEditModal && (
          <AddEditModal
            open={showAddEditModal}
            closeModal={resetModals}
            selectedEvent={selectedEvent}
            gameList={gameList}
            fetchEventsAfterBan={fetchEventsAfterBan}
            setData={setData}
          />
        )}

        {showDeleteModal && (
          <ConfirmDialog
            open={showDeleteModal}
            title='Confirm Delete'
            content='Are you sure to delete this event?'
            onClose={resetModals}
            onConfirm={handleConfirmDelete}
          />
        )}
      </Paper>
      <Toaster position='top-right' toastOptions={{ duration: 3000 }} />
    </div>
  );
};

export default EventList;

const EventTableBody = React.memo(({ event, handleViewEvent, handleEditEvent, handleDeleteEvent }) => {
  const onUserMenuClick = menu => {
    if (menu.action === 'edit') {
      handleEditEvent(event);
    } else if (menu.action === 'delete') {
      handleDeleteEvent(event);
    } else if (menu.action === 'view') {
      handleViewEvent(event);
    }
  };

  const getUserActions = () => {
    const actions = [
      { action: 'view', label: 'View', icon: <Visibility /> },
      { action: 'edit', label: 'Edit', icon: <Edit /> },
      { action: 'delete', label: 'Delete', icon: <Delete /> },
    ];
    return actions;
  };

  const userActions = getUserActions(event);

  return (
    <TableRow>
      <TableCell align='left' style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
        <img
          style={{ borderRadius: 50, objectFit: 'cover' }}
          width={40}
          height={40}
          src={event?.prize_event_banner_image}
          alt={event?.prize_event_title}
        />
        {event?.prize_event_title}
      </TableCell>
      <TableCell align='left'>
        {moment(event?.prize_event_start_time, 'DD/MM/YYYY HH:mm').format('DD MMM YYYY; hh:mm A')}
      </TableCell>
      <TableCell align='left'>
        {moment(event?.prize_event_end_time, 'DD/MM/YYYY HH:mm').format('DD MMM YYYY; hh:mm A')}
      </TableCell>
      <TableCell align='left' className='text-capitalize'>
        {(event?.status === 'active' || event?.status === 'running') && (
          <span className='text-success'>{event?.status}</span>
        )}
        {event?.status === 'inactive' && <span className='text-danger'>{event?.status}</span>}
        {event?.status === 'upcomming' && <span className='text-warning'>upcoming</span>}
      </TableCell>
      <TableCell>{event?.prize_event_entry_cost}</TableCell>
      <TableCell align='left' onClick={e => e.stopPropagation()}>
        <CmtDropdownMenu items={userActions} onItemClick={onUserMenuClick} TriggerComponent={<MoreHoriz />} />
      </TableCell>
    </TableRow>
  );
});

const EventTableHead = React.memo(() => {
  const tableHeader = ['Event Name', 'Start Date', 'End Date', 'Status', 'Price', 'Actions'];

  return (
    <TableHead>
      <TableRow>
        {tableHeader?.map((d, i) => (
          <TableCell key={i} align='left' padding={'normal'}>
            {d}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
});
