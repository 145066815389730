import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  IconButton,
  Paper,
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import useStyles from '../index.style';
import GridContainer from '@jumbo/components/GridContainer';
import ClearIcon from '@material-ui/icons/Clear';
import CmtAvatar from '@coremat/CmtAvatar';
import moment from 'moment';

const ULDetails = ({ open, closeModal, selectedUser }) => {
  const classes = useStyles();

  const {
    user_name,
    phone,
    registration_date,
    played_events,
    // eventWonDetails,
    eventDetails,
    profile_picture,
  } = selectedUser;

  const [anchorEl, setAnchorEl] = useState(null);

  // const handlePopoverClick = event => {
  //   setAnchorEl(event.currentTarget);
  // };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open1 = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Dialog open={open} onClose={closeModal} className={classes.dialogRoot}>
      <Box className={classes.modalHead}>
        <Typography variant='h4' className={classes.titleHead}>
          User Details
        </Typography>
        <IconButton onClick={closeModal}>
          <ClearIcon />
        </IconButton>
      </Box>
      <Box className={classes.userInfoRoot}>
        <Box
          mr={3}
          display='flex'
          alignItems={{ xs: 'center', md: 'start' }}
          flexDirection={{ xs: 'column', md: 'row' }}
        >
          <Box className={classes.avatarView} mr={{ xs: 4, md: 6 }}>
            <CmtAvatar size={100} src={profile_picture} alt={user_name} />
          </Box>

          <Box mt={-2}>
            <Box>
              <Typography className={classes.titleRoot}>Name: {user_name}</Typography>
              <Typography className={classes.titleRoot}>Phone: {phone}</Typography>
              <Typography className={classes.titleRoot}>
                Sign up Date: {moment(registration_date).format('hh.mm A, DD MMM YY')}
              </Typography>
              {/* <Typography className={classes.titleRoot}>
                Status: {!phone || phone === 'N/A' ? 'Inactive' : 'Active'}
              </Typography> */}
              <Typography className={classes.titleRoot}>Events Played: {played_events}</Typography>
              {/* <Typography className={classes.titleRoot}>Prize Won: {eventWonDetails?.length}</Typography> */}
              <Popover
                id={id}
                open={open1}
                anchorEl={anchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <PopoverTable
                  classes={classes}
                  eventWonDetails={eventDetails}
                  handlePopoverClose={handlePopoverClose}
                />
              </Popover>
            </Box>
          </Box>
        </Box>
      </Box>
      <GridContainer style={{ marginBottom: 12 }} />
      <Box display='flex' justifyContent='flex-end' mt={4} mb={4} mr={3}>
        <Box ml={2}>
          {/* <Button variant='contained' color='primary' onClick={handlePopoverClick}>
            Event Details
          </Button> */}
          <Button variant='contained' color='primary' onClick={closeModal}>
            Close
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default ULDetails;

const PopoverTable = ({ eventWonDetails, handlePopoverClose, classes }) => (
  <div>
    <Box ml={4} mr={4} mt={3} mb={2}>
      <Typography style={{ textAlign: 'center' }} variant='h4' className={classes.titleHead}>
        Prize Details
      </Typography>
      <IconButton onClick={handlePopoverClose} style={popoverCloseStyle}>
        <ClearIcon />
      </IconButton>
    </Box>
    <TableContainer component={Paper} className='popover-Table'>
      <Table aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell size='small'>SL</TableCell>
            <TableCell size='small'>Event Name</TableCell>
            <TableCell size='small' align='right'>
              Prize Won
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {eventWonDetails?.map((row, i) => (
            <TableRow key={i}>
              <TableCell size='small' component='th' scope='row'>
                {i + 1}
              </TableCell>
              <TableCell size='small'>{row.event_details.name}</TableCell>
              <TableCell size='small' align='right'>
                {row.is_ended && row.event_details.amount}
                {/* {row.is_ended ? 'true' : 'false'} */}
              </TableCell>
            </TableRow>
          ))}
          {/* <TableRow>
            <TableCell size="small" component="th" scope="row">
              1
            </TableCell>
            <TableCell size="small" component="th" scope="row">
              ASASASASASASASAS
            </TableCell>
            <TableCell size="small" align="right">
              300 Taka
            </TableCell>
          </TableRow> */}
        </TableBody>
      </Table>
    </TableContainer>
  </div>
);

const popoverCloseStyle = {
  position: 'absolute',
  top: 0,
  right: 0,
};
