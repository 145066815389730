import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core';
import useStyles from '../index.style';
import NoRecordFound from 'components/NoRecordFound.Component';
import { Pagination } from '@material-ui/lab';
import { convertSeconds } from 'common/functions';
import { handleDownloadUserCSV } from '../functions';
import { useDispatch, useSelector } from 'react-redux';
import { getEventData } from 'redux/actions/Analytic';
import { SET_EVENT_CURRENT_PAGE } from '@jumbo/constants/ActionTypes';

const pageSize = 20;

const AllEvents = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let { events } = useSelector(({ analyticReducer }) => analyticReducer);

  useEffect(() => {
    if (!events?.data) {
      dispatch(getEventData(pageSize));
    }
  }, [dispatch]);

  const handlePagination = (e, page) => {
    // setCurrentPageEvent(page);
    dispatch({ type: SET_EVENT_CURRENT_PAGE, payload: page });
    dispatch(getEventData(pageSize, page));
    // getAllEventsData(page);
    // console.log(dateRange);
  };

  return (
    <Paper className={classes.paper}>
      <Toolbar className={classes.root} style={{ justifyContent: 'space-between' }}>
        <div>
          <Typography className={classes.title} variant='h4' id='tableTitle' component='div'>
            Total Events: {events?.data?.total_count}
          </Typography>
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button
            onClick={() => handleDownloadUserCSV('export_event_table', 'event_table')}
            variant='contained'
            color='primary'
          >
            Export
          </Button>
        </div>
      </Toolbar>

      <TableContainer className={classes.container}>
        <Table stickyHeader className='export_event_table'>
          <EventTableHead />
          {events && (
            <TableBody>
              {events?.data?.data?.length > 0 ? (
                events?.data?.data?.map(event => <EventTableBody key={event?._id} event={event} />)
              ) : (
                <TableRow style={{ height: 200 }}>
                  <TableCell colSpan={11} rowSpan={7}>
                    <NoRecordFound>There are no records found.</NoRecordFound>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      <TotalRow eventData={events?.data} classes={classes} />

      {events?.totalPages > 1 && (
        <Box px={4} py={5} display='flex' justifyContent='flex-end'>
          <Pagination
            disabled={events?.paginationDisabled}
            page={events?.currentPage}
            count={events?.totalPages}
            color='primary'
            onChange={handlePagination}
          />
        </Box>
      )}
    </Paper>
  );
};

export default AllEvents;

const EventTableHead = React.memo(() => {
  return (
    <TableHead style={{ background: '#f4f4f7' }}>
      <TableRow>
        <TableCell align='left'>Event Name</TableCell>
        <TableCell align='center'>Player Count</TableCell>
        <TableCell align='center'>Play Count</TableCell>
        <TableCell align='center'>Session Time</TableCell>
      </TableRow>
    </TableHead>
  );
});

const EventTableBody = React.memo(({ event }) => {
  return (
    <TableRow>
      <TableCell align='left' style={{ paddingLeft: 20, width: '50%' }} padding={'normal'}>
        {event?.event_title || '-'}
      </TableCell>
      <TableCell align='center' padding={'normal'} style={{ width: '15%' }}>
        {event?.event_total_player || 0}
      </TableCell>
      <TableCell align='center' padding={'normal'} style={{ width: '13%' }}>
        {event?.event_game_play_count || 0}
      </TableCell>
      <TableCell align='center' padding={'normal'} style={{ width: '22%' }}>
        {event?.event_session_time ? convertSeconds(event?.event_session_time) : '0d 0h 0m 0s'}
      </TableCell>
    </TableRow>
  );
});

const TotalRow = React.memo(({ classes, eventData }) => {
  return (
    <TableContainer className={classes.container}>
      <Table
        stickyHeader
        className='export_event_table'
        aria-labelledby='tableTitle'
        aria-label='sticky enhanced table'
      >
        <TableBody>
          <TableRow className='analytic-footer-total'>
            <TableCell align='left' className={classes.total} style={{ width: '50%' }}>
              Total
            </TableCell>
            <TableCell align='center' className={classes.total} style={{ width: '15%' }}>
              {eventData?.total_player}
            </TableCell>
            <TableCell align='center' className={classes.total} style={{ width: '13%' }}>
              {eventData?.total_game_play_count}
            </TableCell>
            <TableCell align='center' className={classes.total} style={{ width: '22%' }}>
              {eventData?.total_session_time ? convertSeconds(eventData?.total_session_time) : '0d 0h 0m 0s'}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
});
