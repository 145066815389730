import React from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';
import makeStyles from '@material-ui/core/styles/makeStyles';

import CmtVertical from '../../../../../@coremat/CmtNavigation/Vertical';
// import SidebarButtons from './SIdebarButtons';
import { sidebarNavs, sidebarNavsAdmin, sidebarNavsSuperAdmin, sidebarNavsFinanceAdmin } from '../menus';
import { getLocalUserRole } from 'common/userRoleAccess';

const useStyles = makeStyles(() => ({
  perfectScrollbarSidebar: {
    height: '100%',
    transition: 'all 0.3s ease',
    '.Cmt-sidebar-fixed &, .Cmt-Drawer-container &': {
      height: 'calc(100% - 167px)',
    },
    '.Cmt-modernLayout &': {
      height: 'calc(100% - 72px)',
    },
    '.Cmt-miniLayout &': {
      height: 'calc(100% - 91px)',
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:hover &': {
      height: 'calc(100% - 167px)',
    },
  },
}));

const SideBar = () => {
  const classes = useStyles();

  const role = getLocalUserRole();
  // console.log(role);

  return (
    <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
      {role ? (
        <>
          {role?.type === 'super_admin' && <CmtVertical menuItems={sidebarNavsSuperAdmin} />}
          {role?.type === 'admin' && <CmtVertical menuItems={sidebarNavsAdmin} />}
          {role?.type === 'finance_admin' && <CmtVertical menuItems={sidebarNavsFinanceAdmin} />}
        </>
      ) : (
        <CmtVertical menuItems={sidebarNavs} />
      )}
      {/* <SidebarButtons /> */}
    </PerfectScrollbar>
  );
};

export default SideBar;
