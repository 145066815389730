import React, { useEffect } from 'react';
import useStyles from '../index.style';
import { useState } from 'react';
import Tabs from 'components/Tabs.Component';
import { Box } from '@material-ui/core';
import CmtSearch from '@coremat/CmtSearch';
import PGTable from '../components/PGTable.Component';
import Axios from 'axios';
import { useDispatch } from 'react-redux';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import { useDebounce } from '@jumbo/utils/commonHelper';

const tabs = ['Claim', 'Disburse'];

const pageSize = 50;

const RefPhysicalGoods = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(tabs[0]);

  const [searchClaim, setSearchClaim] = useState('');
  const [paginationDisabledClaim, setPaginationDisabledClaim] = useState(false);
  const [totalPagesClaim, setTotalPagesClaim] = useState(0);
  const [currentPageClaim, setCurrentPageClaim] = useState(1);
  const [claimedData, setClaimedData] = useState(null);

  const [searchDisburse, setSearchDisburse] = useState('');
  const [paginationDisabledDisbursed, setPaginationDisabledDisbursed] = useState(false);
  const [totalPagesDisbursed, setTotalPagesDisbursed] = useState(0);
  const [currentPageDisbursed, setCurrentPageDisbursed] = useState(1);
  const [disbursedData, setDisbursedData] = useState(null);

  const debouncedSearchClaim = useDebounce(searchClaim, 500);
  const debouncedSearchDisbursed = useDebounce(searchDisburse, 500);

  useEffect(() => {
    setCurrentPageClaim(1);
    fetchClaimed(debouncedSearchClaim, 1);
  }, [debouncedSearchClaim]);

  useEffect(() => {
    setCurrentPageDisbursed(1);
    fetchDisburse(debouncedSearchDisbursed, 1);
  }, [debouncedSearchDisbursed]);

  const fetchClaimed = (search, page = 1) => {
    dispatch(fetchStart());
    setPaginationDisabledClaim(true);
    const searchTxt = search || '';

    Axios.get(`${process.env.REACT_APP_BASE_URL}/admin/physical-good/user/`, {
      params: {
        page: page,
        size: pageSize,
        search: searchTxt,
        status: 'PENDING',
      },
    })
      .then(resp => {
        // console.log(resp.data);
        setTotalPagesClaim(Math.ceil(resp?.data?.total_count / pageSize));
        setClaimedData(resp.data);
        dispatch(fetchSuccess());
        setPaginationDisabledClaim(false);
      })
      .catch(error => {
        // console.log(error.response);
        setPaginationDisabledClaim(false);
        dispatch(fetchError('Sorry, something went wrong. Please try again later.'));
      });
  };

  const fetchDisburse = (search, page = 1) => {
    dispatch(fetchStart());
    setPaginationDisabledDisbursed(true);
    const searchTxt = search || '';

    Axios.get(`${process.env.REACT_APP_BASE_URL}/admin/physical-good/user/`, {
      params: {
        page: page,
        size: pageSize,
        search: searchTxt,
        status: 'NOT_PENDING',
      },
    })
      .then(resp => {
        // console.log(resp.data);
        setTotalPagesDisbursed(Math.ceil(resp?.data?.total_count / pageSize));
        setDisbursedData(resp.data);
        dispatch(fetchSuccess());
        setPaginationDisabledDisbursed(false);
      })
      .catch(error => {
        // console.log(error.response);
        setPaginationDisabledDisbursed(false);
        dispatch(fetchError('Sorry, something went wrong. Please try again later.'));
      });
  };

  const handleClaimPagination = (e, page) => {
    setCurrentPageClaim(page);
    fetchClaimed(debouncedSearchClaim, page);
    // console.log(dateRange);
  };

  const handleDisbursePagination = (e, page) => {
    setCurrentPageDisbursed(page);
    fetchDisburse(debouncedSearchDisbursed, page);
    // console.log(dateRange);
  };

  const fetchInitial = () => {
    setCurrentPageClaim(1);
    setCurrentPageDisbursed(1);
    fetchClaimed();
    fetchDisburse();
    setSearchClaim('');
    setSearchDisburse('');
  };

  return (
    <div className={classes.root}>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
        {activeTab === 'Claim' && (
          <div>
            <CmtSearch onChange={e => setSearchClaim(e.target.value)} value={searchClaim} />
          </div>
        )}
        {activeTab === 'Disburse' && (
          <div>
            <CmtSearch onChange={e => setSearchDisburse(e.target.value)} value={searchDisburse} />
          </div>
        )}
      </Box>

      {activeTab === 'Claim' && (
        <PGTable
          currentPage={currentPageClaim}
          paginationDisabled={paginationDisabledClaim}
          totalPages={totalPagesClaim}
          data={claimedData}
          handlePagination={handleClaimPagination}
          fetchInitial={fetchInitial}
        />
      )}

      {activeTab === 'Disburse' && (
        <PGTable
          currentPage={currentPageDisbursed}
          paginationDisabled={paginationDisabledDisbursed}
          totalPages={totalPagesDisbursed}
          data={disbursedData}
          handlePagination={handleDisbursePagination}
          fetchInitial={fetchInitial}
        />
      )}
    </div>
  );
};

export default RefPhysicalGoods;
