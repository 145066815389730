import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import { Box, Button, Grid, Paper, Snackbar, Toolbar, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import useStyles from '../index.style';
import { useDispatch } from 'react-redux';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import { Alert } from '@material-ui/lab';
import moment from 'moment';
import { axiosInstance } from 'utils/axios';
import toast, { Toaster } from 'react-hot-toast';

const SearchTransaction = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const [bkashResponse, setBkashResponse] = useState(null);

  const [openToastr, setOpenToastr] = useState(false);
  const [toastMessage, setToastMessage] = useState('');

  const fetchTrxDetails = id => {
    if (id.trim() === '') {
      setOpenToastr(true);
      setToastMessage('Transaction id can not be empty.');
      return;
    }

    dispatch(fetchStart());

    axiosInstance
      .get(`/admin/bkash/search-transaction/${id}`)
      .then(resp => {
        // console.log(resp.data);
        if (resp?.data?.success) {
          const mainResponse = resp?.data?.data;
          const bkashResponse = resp?.data?.data?.bkash_response;
          mainResponse['bkash_response'] = JSON.parse(bkashResponse);
          setBkashResponse(mainResponse);
          dispatch(fetchSuccess());
        } else {
          toast.error('Sorry, no data found.');
          dispatch(fetchError());
          setBkashResponse(null);
        }
      })
      .catch(error => {
        // console.log(error.response);
        // dispatch(fetchError());
        toast.error('Sorry, something went wrong!');
      });
  };

  const handleSearch = e => {
    e.preventDefault();
    // console.log(searchValue);
    fetchTrxDetails(searchValue);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar>
          <Typography className={classes.title} variant='h1' component='div'>
            Search Transaction
          </Typography>
        </Toolbar>
        <div className='container-p'>
          <form onSubmit={handleSearch}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Box mb={{ xs: 6, md: 4 }}>
                  <AppTextInput
                    fullWidth
                    variant='outlined'
                    label='Transaction Number'
                    value={searchValue}
                    onChange={e => setSearchValue(e.target.value)}
                  />
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Button type='submit' size='large' variant='contained' color='primary'>
                  Search
                </Button>
              </Grid>
            </Grid>
          </form>
          {bkashResponse && (
            <Grid container spacing={3}>
              <Grid item xs={12} style={{ marginBottom: 15, marginTop: -5 }}>
                <Typography className={classes.titleRoot}>
                  Amount: {bkashResponse?.bkash_response?.amount} {bkashResponse?.bkash_response?.currency}
                </Typography>
                <Typography className={classes.titleRoot}>
                  Completed time:{' '}
                  {moment(bkashResponse?.bkash_response?.completedTime, 'YYYY-MM-DDTHH:nn;ss').format(
                    'DD MMM YYYY; hh:mm A'
                  )}
                </Typography>
                <Typography className={classes.titleRoot}>
                  Customer MSISDN: {bkashResponse?.bkash_response?.customerMsisdn}
                </Typography>
                <Typography className={classes.titleRoot}>
                  Initiation time:{' '}
                  {moment(bkashResponse?.bkash_response?.initiationTime, 'YYYY-MM-DDTHH:nn;ss').format(
                    'DD MMM YYYY; hh:mm A'
                  )}
                </Typography>
                <Typography className={classes.titleRoot}>
                  Transaction Status: {bkashResponse?.bkash_response?.transactionStatus}
                </Typography>
                <Typography className={classes.titleRoot}>
                  Transaction Type: {bkashResponse?.bkash_response?.transactionType}
                </Typography>
                {/* <Typography className={classes.titleRoot}>
                  Transaction Reference: {bkashResponse?.event_name}
                </Typography> */}
                <Typography className={classes.titleRoot}>Invoice ID: {bkashResponse?.invoice_id}</Typography>
                <Typography className={classes.titleRoot}>
                  Phone number (login): {bkashResponse?.phone_number}
                </Typography>
              </Grid>
            </Grid>
          )}
        </div>
      </Paper>

      <Snackbar
        anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
        open={openToastr}
        autoHideDuration={3000}
        onClose={() => setOpenToastr(false)}
      >
        <Alert onClose={() => setOpenToastr(false)} severity='error'>
          {toastMessage}
        </Alert>
      </Snackbar>

      <Toaster position='top-right' toastOptions={{ duration: 4000 }} />
    </div>
  );
};

export default SearchTransaction;
