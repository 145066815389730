import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import { setAuthUser, setForgetPassMailSent, setUserRole, updateLoadUser } from '../../../redux/actions/Auth';
import React from 'react';
// import axios from './config';
import axios from 'axios';
import { axiosInstance } from 'utils/axios';

const JWTAuth = {
  onRegister: ({ name, email, password }) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('auth/register', {
          email: email,
          password: password,
          name: name,
        })
        .then(({ data }) => {
          if (data.result) {
            localStorage.setItem('token', data.token.access_token);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token.access_token;
            dispatch(fetchSuccess());
            dispatch(JWTAuth.getAuthUser(true, data.token.access_token));
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },

  onLogin: (data, callback) => {
    return dispatch => {
      const messages = ['no turn left', 'time expired', 'go back and try again'];

      try {
        dispatch(fetchStart());
        // console.log(data);
        axiosInstance
          .post(`/admin/otp`, data)
          .then(resp => {
            // console.log(resp.data);
            if (messages.includes(resp.data.message)) {
              callback();
              return;
            }

            if (resp.data.message) {
              dispatch(fetchError(resp.data.message));
              return;
            }
            if (resp?.data?.data?.user) {
              localStorage.setItem('token', resp?.data?.data?.token);
              localStorage.setItem('user_name', resp?.data?.data?.user.name);
              localStorage.setItem('user_id', resp?.data?.data?.user._id);
              //console.log(data);
              localStorage.setItem('email', resp?.data?.data?.user.email);
              localStorage.setItem('phone_number', resp?.data?.data?.user.phone_number);
              localStorage.setItem('userRole', JSON.stringify(resp?.data?.data?.user.role));
              // axios.defaults.headers.common['Authorization'] = 'Bearer ' + resp?.data?.data?.token;
              axios.defaults.headers.common['Authorization'] = 'Bearer ' + resp?.data?.data?.token;
              axiosInstance.defaults.headers.common['Authorization'] = 'Bearer ' + resp?.data?.data?.token;

              setTimeout(() => {
                dispatch(fetchSuccess());
                dispatch(JWTAuth.getAuthUser(true, resp?.data?.data?.token));
              }, 500);
            } else {
              dispatch(fetchError(data.error));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error?.response?.data?.error?.message));
            // console.log(error.message);
            // console.log(error?.response?.data?.error);
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },

  onLogout: () => {
    // return dispatch => {
    //   dispatch(fetchStart());
    //   axios
    //     .post('auth/logout')
    //     .then(({ data }) => {
    //       if (data.result) {
    //         dispatch(fetchSuccess());
    //         localStorage.removeItem('token');
    //         dispatch(setAuthUser(null));
    //       } else {
    //         dispatch(fetchError(data.error));
    //       }
    //     })
    //     .catch(function(error) {
    //       dispatch(fetchError(error.message));
    //     });
    // };

    return dispatch => {
      dispatch(fetchStart());

      setTimeout(() => {
        dispatch(fetchSuccess());
        // localStorage.removeItem('token');
        // localStorage.removeItem('user_id');
        localStorage.clear();
        dispatch(setAuthUser(null));
      }, 300);
    };
  },

  getAuthUser: (loaded = false, token) => {
    return dispatch => {
      if (!token) {
        const token = localStorage.getItem('token');
        axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
      }
      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));
      axios
        .post(`${process.env.REACT_APP_BASE_URL}/auth-user/admin/`)
        .then(({ data }) => {
          data = {
            user: localStorage.getItem('user_name'),
            token: localStorage.getItem('token'),
            email: localStorage.getItem('email'),
          };
          if (data.user) {
            const userRole = localStorage.getItem('userRole');
            dispatch(fetchSuccess());
            dispatch(setAuthUser(data.user));
            dispatch(setUserRole(JSON.parse(userRole)));
          } else {
            dispatch(updateLoadUser(true));
          }
        })
        .catch(function(error) {
          dispatch(updateLoadUser(true));

          if (error.response.status === 401) {
            // localStorage.clear()
            if (error.response.data.error === 'You are inactive for more than 7 days, please re-login') {
              dispatch(fetchError(error.response.data.error));
              localStorage.clear();
              dispatch(setAuthUser(null));
              // setTimeout(() => {
              //   window.location.reload();
              // }, 3000);
            }
          }
        });
    };
  },

  onForgotPassword: () => {
    return dispatch => {
      dispatch(fetchStart());

      setTimeout(() => {
        dispatch(setForgetPassMailSent(true));
        dispatch(fetchSuccess());
      }, 300);
    };
  },
  getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>;
  },
};

export default JWTAuth;
