import {
  SET_HISTORY_NOTIFICATIONS,
  SET_SCHEDULED_NOTIFICATIONS,
  HISTORY_NOTIFICATIONS_CURRENT_PAGE,
  HISTORY_NOTIFICATIONS_PAGINATION_DISABLED,
  HISTORY_NOTIFICATIONS_TOTAL_PAGES,
  SET_EDIT_NOTIFICATIONS_DATA,
  SET_HISTORY_NOTIFICATIONS_DATE,
  SET_SCHEDULED_NOTIFICATIONS_TOTAL_PAGES,
  SET_SCHEDULED_NOTIFICATIONS_CURRENT_PAGE,
  SET_SCHEDULED_NOTIFICATIONS_DATE,
  SET_SCHEDULED_NOTIFICATIONS_PAGINATION_DISABLED,
  SET_SCHEDULED_NOTIFICATIONS_DATE_APPLIED,
  SET_SCHEDULED_NOTIFICATIONS_IS_FETCHED,
  SET_EDIT_NOTIFICATIONS_DATA_APPLIED,
  SET_HISTORY_NOTIFICATIONS_DATE_APPLIED,
  SET_HISTORY_NOTIFICATIONS_IS_FETCHED,
  RESET_NOTIFICATIONS_DATA,
} from '@jumbo/constants/ActionTypes';

const INIT_STATE = {
  scheduledNotifications: {
    data: null,
    pageSize: 20,
    totalPages: 0,
    currentPage: 1,
    dateRange: [null, null],
    dateRangeApplied: false,
    paginationDisabled: false,
    isDataFetched: false,
  },
  historyNotifications: {
    data: null,
    pageSize: 20,
    totalPages: 0,
    currentPage: 1,
    dateRange: [null, null],
    dateRangeApplied: false,
    paginationDisabled: false,
    isDataFetched: false,
  },
  editData: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_SCHEDULED_NOTIFICATIONS: {
      return {
        ...state,
        scheduledNotifications: {
          ...state.scheduledNotifications,
          data: action.payload,
        },
      };
    }
    case SET_SCHEDULED_NOTIFICATIONS_TOTAL_PAGES: {
      return {
        ...state,
        scheduledNotifications: {
          ...state.scheduledNotifications,
          totalPages: action.payload,
        },
      };
    }
    case SET_SCHEDULED_NOTIFICATIONS_CURRENT_PAGE: {
      return {
        ...state,
        scheduledNotifications: {
          ...state.scheduledNotifications,
          currentPage: action.payload,
        },
      };
    }
    case SET_SCHEDULED_NOTIFICATIONS_DATE: {
      return {
        ...state,
        scheduledNotifications: {
          ...state.scheduledNotifications,
          dateRange: action.payload,
        },
      };
    }
    case SET_SCHEDULED_NOTIFICATIONS_DATE_APPLIED: {
      return {
        ...state,
        scheduledNotifications: {
          ...state.scheduledNotifications,
          dateRangeApplied: action.payload,
        },
      };
    }
    case SET_SCHEDULED_NOTIFICATIONS_PAGINATION_DISABLED: {
      return {
        ...state,
        scheduledNotifications: {
          ...state.scheduledNotifications,
          paginationDisabled: action.payload,
        },
      };
    }
    case SET_SCHEDULED_NOTIFICATIONS_IS_FETCHED: {
      return {
        ...state,
        scheduledNotifications: {
          ...state.scheduledNotifications,
          isDataFetched: action.payload,
        },
      };
    }

    case SET_HISTORY_NOTIFICATIONS: {
      return {
        ...state,
        historyNotifications: {
          ...state.historyNotifications,
          data: action.payload,
        },
      };
    }
    case HISTORY_NOTIFICATIONS_TOTAL_PAGES: {
      return {
        ...state,
        historyNotifications: {
          ...state.historyNotifications,
          totalPages: action.payload,
        },
      };
    }
    case HISTORY_NOTIFICATIONS_CURRENT_PAGE: {
      return {
        ...state,
        historyNotifications: {
          ...state.historyNotifications,
          currentPage: action.payload,
        },
      };
    }
    case SET_HISTORY_NOTIFICATIONS_DATE: {
      return {
        ...state,
        historyNotifications: {
          ...state.historyNotifications,
          dateRange: action.payload,
        },
      };
    }
    case SET_HISTORY_NOTIFICATIONS_DATE_APPLIED: {
      return {
        ...state,
        historyNotifications: {
          ...state.historyNotifications,
          dateRangeApplied: action.payload,
        },
      };
    }
    case SET_HISTORY_NOTIFICATIONS_IS_FETCHED: {
      return {
        ...state,
        historyNotifications: {
          ...state.historyNotifications,
          isDataFetched: action.payload,
        },
      };
    }
    case HISTORY_NOTIFICATIONS_PAGINATION_DISABLED: {
      return {
        ...state,
        historyNotifications: {
          ...state.historyNotifications,
          paginationDisabled: action.payload,
        },
      };
    }

    case RESET_NOTIFICATIONS_DATA: {
      return {
        scheduledNotifications: {
          data: null,
          pageSize: 20,
          totalPages: 0,
          currentPage: 1,
          dateRange: [null, null],
          dateRangeApplied: false,
          paginationDisabled: false,
          isDataFetched: false,
        },
        historyNotifications: {
          data: null,
          pageSize: 20,
          totalPages: 0,
          currentPage: 1,
          dateRange: [null, null],
          dateRangeApplied: false,
          paginationDisabled: false,
          isDataFetched: false,
        },
        editData: null,
      };
    }

    case SET_EDIT_NOTIFICATIONS_DATA: {
      return {
        ...state,
        editData: action.payload,
      };
    }

    default:
      return state;
  }
};
