import React, { useState, useEffect, useCallback } from 'react';
import { Box, Button, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import useStyles from '../index.style';
import PaymentTable from './PaymentTable.Component';
import { useDispatch, useSelector } from 'react-redux';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import CustomDatePickerInput from 'components/CustomDatePickerInput.Component';
import moment from 'moment';
import PdfForm from './PdfForm.Component';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { axiosInstance } from 'utils/axios';

const pageSize = 800;

const PaymentHistory = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { allEvents: events } = useSelector(({ CommonSharedData }) => CommonSharedData);

  const [paymentData, setPaymentData] = useState(null);
  const [eventId, setEventId] = useState('');
  const [dateRange, setDateRange] = useState([null, null]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pdfData, setPdfData] = useState(null);
  const [paginationDisabled, setPaginationDisabled] = useState(false);

  const [selectedCheckbox, setSelectedCheckbox] = useState([0]);
  const [allChecked, setAllChecked] = useState(false);

  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    fetchPaymentHistory();
  }, []);

  const handleSearch = e => {
    e.preventDefault();

    // console.log(search);
    setCurrentPage(1);
    const event = eventId;
    const startDate = dateRange[0] ? moment(dateRange[0]).format('YYYY-MM-DDT00:00:00+06:00') : '';
    const endDate = dateRange[1] ? moment(dateRange[1]).format('YYYY-MM-DDT23:59:59+06:00') : '';
    fetchPaymentHistory(startDate, endDate, event, 1, searchTerm);
  };

  const fetchPaymentHistory = (startDate, endDate, eventId, page = 1, search) => {
    dispatch(fetchStart());
    setPaginationDisabled(true);
    const start = startDate || '';
    const end = endDate || '';
    const id = eventId || '';
    const searchTxt = search || '';

    axiosInstance
      .get(`/admin/vat-tax`, {
        params: {
          page: page,
          size: pageSize,
          start_time: start,
          end_time: end,
          prize_event_id: id,
          search: searchTxt,
        },
      })
      .then(resp => {
        // console.log(resp.data);
        setTotalPages(Math.ceil(resp?.data?.total_count / pageSize));
        setPaymentData(resp.data);
        dispatch(fetchSuccess());
        setPaginationDisabled(false);
      })
      .catch(error => {
        // console.log(error.response);
        setPaginationDisabled(false);
        dispatch(fetchError('Sorry, something went wrong. Please try again later.'));
      });
  };

  const handleApplyFilter = () => {
    setCurrentPage(1);
    const event = eventId?.trim();
    const startDate = dateRange[0] ? moment(dateRange[0]).format('YYYY-MM-DDT00:00:00+06:00') : '';
    const endDate = dateRange[1] ? moment(dateRange[1]).format('YYYY-MM-DDT23:59:59+06:00') : '';

    fetchPaymentHistory(startDate, endDate, event, 1, searchTerm);

    // console.log(event);
    // console.log(startDate);
    // console.log(endDate);
  };

  const handleDownloadPdf = useCallback(async (data, type) => {
    // console.log({ ...data, type });
    await setPdfData({ ...data, type });
    await html2canvas(document.querySelector('#pdfForm')).then(canvas => {
      let image = canvas.toDataURL('image/png');
      const doc = new jsPDF('p', 'mm', 'a4', true);
      const width = doc.internal.pageSize.getWidth() - 10;
      const height = (canvas.height * width) / canvas.width;
      doc.addImage(image, 'PNG', 5, 10, width, height, '', 'FAST');
      doc.save(`${type}_copy_${data._id}.pdf`);
    });
  }, []);

  const handleDownloadCsv = (target, fileName, isDownloadAll = true) => {
    const targetTable = `.${target} tr`;

    // Variable to store the final csv data
    let csv_data = [];

    // Get each row data
    // let rows = document.getElementsByTagName('tr');
    let rows = document.querySelectorAll(targetTable);
    for (let i = 0; i < rows.length; i++) {
      // Get each column data
      let cols = rows[i].querySelectorAll('td,th');

      if (isDownloadAll) {
        // Stores each csv row data
        let csvrow = [];
        for (let j = 1; j < cols.length - 2; j++) {
          // Get the text data of each cell
          // of a row and push it to csvrow
          csvrow.push(`${cols[j].innerHTML.toString()}`);
        }

        // Combine each column value with comma
        csv_data.push(csvrow.join(','));
      } else {
        if (selectedCheckbox.includes(i)) {
          // Stores each csv row data
          let csvrow = [];
          for (let j = 1; j < cols.length - 2; j++) {
            // Get the text data of each cell
            // of a row and push it to csvrow
            csvrow.push(cols[j].innerHTML);
          }

          // Combine each column value with comma
          csv_data.push(csvrow.join(','));
        }
      }
    }

    csv_data = [...csv_data, '', 'Jeeto.Online - System Generated'];

    // Combine each row data with new line character
    csv_data = csv_data.join('\n');

    // Call this function to download csv file
    downloadCSVFile(csv_data, fileName);
  };

  const handleExportSelected = (target, fileName) => {
    // console.log(target);

    if (selectedCheckbox.length > 1) {
      handleDownloadCsv(target, fileName, false);
    } else {
      dispatch(fetchError('Please select row and then try to export.'));
    }
  };

  const downloadCSVFile = (csv_data, fileName) => {
    // Create CSV file object and feed
    // our csv_data into it
    let CSVFile;

    CSVFile = new Blob([csv_data], {
      type: 'text/csv',
    });

    // Create to temporary link to initiate
    // download process
    let temp_link = document.createElement('a');

    // Download csv file
    temp_link.download = `${fileName}${currentPage}.csv`;
    let url = window.URL.createObjectURL(CSVFile);
    temp_link.href = url;

    // This link should not be displayed
    temp_link.style.display = 'none';
    document.body.appendChild(temp_link);

    // Automatically click the link to
    // trigger download
    temp_link.click();
    document.body.removeChild(temp_link);
  };

  const handlePagination = (e, page) => {
    setCurrentPage(page);
    const event = eventId;
    const startDate = dateRange[0] ? moment(dateRange[0]).format('YYYY-MM-DDT00:00:00+06:00') : '';
    const endDate = dateRange[1] ? moment(dateRange[1]).format('YYYY-MM-DDT23:59:59+06:00') : '';
    fetchPaymentHistory(startDate, endDate, event, page, searchTerm);
    // console.log(dateRange);
  };

  const resetFilter = () => {
    setDateRange([null, null]);
    setEventId('');
    setCurrentPage(1);
    setSelectedCheckbox([0]);
    setSearchTerm('');
    // const event = eventId;
    // const startDate = dateRange[0] ? moment(dateRange[0]).format('YYYY-MM-DDT00:00:00+06:00') : '';
    // const endDate = dateRange[1] ? moment(dateRange[1]).format('YYYY-MM-DDT23:59:59+06:00') : '';
    fetchPaymentHistory();
  };

  return (
    <div className={classes.root}>
      <div className='finance-header'>
        <Box display='flex' alignItems='center' className='left' style={{ flexWrap: 'wrap', gap: 10 }}>
          <Box display='flex' alignItems='center' style={{ gap: 10 }}>
            <Box>
              <CustomDatePickerInput
                value={dateRange}
                setValue={setDateRange}
                placeholder='Select Date Range'
                type='range'
                clearable={true}
                minWidth={270}
              />
            </Box>
            <Box style={{ background: '#fff' }}>
              <FormControl size='small' fullWidth variant='outlined' style={{ width: 200 }}>
                <InputLabel>Select Prize Event</InputLabel>
                <Select
                  labelId='Select Prize Event'
                  label='Select Prize Event'
                  value={eventId}
                  onChange={e => setEventId(e.target.value)}
                  size='lg'
                >
                  <MenuItem value=' '>All</MenuItem>
                  {events?.map(event => (
                    <MenuItem value={event._id} key={event._id}>
                      {event.prize_event_title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box>
            <Button variant='contained' color='primary' onClick={handleApplyFilter}>
              Apply Filter
            </Button>
          </Box>
          <Box>
            <Button variant='contained' color='secondary' onClick={resetFilter}>
              Clear Filter
            </Button>
          </Box>
        </Box>

        <Box display='flex' alignItems='center' style={{ flexWrap: 'wrap', gap: 10 }}>
          <Button
            variant='contained'
            color='primary'
            onClick={() => handleExportSelected('finance_table', 'export_selection_page_')}
          >
            Export Selection
          </Button>
          <Button
            variant='contained'
            color='primary'
            onClick={() => handleDownloadCsv('finance_table', 'export_csv_page_')}
          >
            Export csv
          </Button>
        </Box>
      </div>

      <PaymentTable
        paginationDisabled={paginationDisabled}
        totalPages={totalPages}
        paymentData={paymentData}
        handleDownloadPdf={handleDownloadPdf}
        handlePagination={handlePagination}
        currentPage={currentPage}
        selected={selectedCheckbox}
        setSelected={setSelectedCheckbox}
        allChecked={allChecked}
        setAllChecked={setAllChecked}
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        handleSearch={handleSearch}
      />

      <PdfForm pdfData={pdfData} />
    </div>
  );
};

export default PaymentHistory;
