import React, { useEffect, useState } from 'react';
import PushNotofication from './components/PushNotofication.Component';
import History from './components/History.Component';
import useStyles from './index.style';
import { useDispatch } from 'react-redux';
import { RESET_NOTIFICATIONS_DATA } from '@jumbo/constants/ActionTypes';

const tabs = ['Push Notification', 'History'];

const Notification = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(tabs[0]);

  useEffect(() => {
    return () => {
      dispatch({ type: RESET_NOTIFICATIONS_DATA, payload: false });
    };
  }, []);

  return (
    <div className={[classes.root + ' notifications']}>
      <div className='analyticTabs'>
        {tabs.map((tab, i) => (
          <button onClick={() => setActiveTab(tab)} className={activeTab === tab ? 'active' : ''} key={i}>
            {tab}
          </button>
        ))}
      </div>

      {activeTab === 'Push Notification' && <PushNotofication classes={classes} />}
      {activeTab === 'History' && <History classes={classes} />}
    </div>
  );
};

export default Notification;
