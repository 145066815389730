import React from 'react';
import BdLogo from '../../../assets/img/bd-govt.png';
import Sign from '../../../assets/img/finance-sign.png';
import Seal from '../../../assets/img/finance-seal.png';
import moment from 'moment';

const PdfForm = ({ pdfData }) => {
  const getTotal = data => {
    const amount = Number(data?.total_amount_with_vat_tax) - Number(data?.vat_amount);
    return amount.toFixed(2);
  };

  return (
    <div className='pdf-parent'>
      <div style={{ position: 'relative' }}>
        <div id='pdfForm'>
          <div className='header'>
            <div className='top'>
              <div className='left'>
                <img src={BdLogo} alt='' width='80' />
              </div>
              <div className='middle'>
                <p>গণপ্রজাতন্ত্রী বাংলাদেশ সরকার</p>
                <p className='fw-600'>জাতীয় রাজস্ব বোর্ড</p>
              </div>
              <div className='right'>
                <p>{pdfData?.type === 'user' ? 'ক্রেতা' : 'অফিস'} কপি</p>
                <p>মূসক - ৬.৩</p>
              </div>
            </div>
            <div className='bottom'>
              <div className='title'>
                <p className='fw-600 text-center'>কর চালানপত্র</p>
                <p className='fw-600 text-center'>[ বিধি ৪০ এর উপ-বিধি (১) এর দফা (গ) ও দফা (চ) দ্রষ্টব্য ]</p>
              </div>
              <div className='registered'>
                <div>
                  <p>নিবন্ধিত ব্যক্তির নাম: JEETO ONLINE</p>
                  <p>নিবন্ধিত ব্যক্তির বিআইইন: 005434224-0102</p>
                  <p>চালানপত্র ইস্যুর ঠিকানা: Level-7, Tropical Alauddin Tower, Plot No-32/C,</p>
                  <p>Road-2, Sector-3, Uttara, Dhaka-1230, Bangladesh.</p>
                </div>
              </div>
              <div className='buyer'>
                <div className='left'>
                  <p>ক্রেতার নাম: {pdfData?.user_info?.user_name}</p>
                  <p>ক্রেতার ঠিকানা: {pdfData?.user_info?.phone}</p>
                  <p>গন্তব্য স্থল: বাংলাদেশ </p>
                </div>
                <div className='right'>
                  <p>চালানপত্র নাম্বার: {pdfData?.serial}</p>
                  <p>ইস্যুর তারিখ: {pdfData?.pay_date_time && moment(pdfData?.pay_date_time).format('DD MMM YYYY')}</p>
                  <p>ইস্যুর সময়: {pdfData?.pay_date_time && moment(pdfData?.pay_date_time).format('hh:mm A')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className='table'>
            <table className='table table-bordered'>
              <thead>
                <tr>
                  <th>ক্রমিক</th>
                  <th style={{ width: '100%' }}>পণ্য বা সেবা বর্ণনা</th>
                  <th className='word-break-wordd'>সরবরাহের একক</th>
                  <th>পরিমাণ</th>
                  <th className='word-break-wordd'>একক মূল্য (টাকায়)</th>
                  <th className='word-break-wordd'>মোট মূল্য (টাকায়)</th>
                  <th className='word-break-wordd'>সম্পূরক শুল্কের পরিমাণ (টাকায়)</th>
                  <th className='word-break-wordd'>মূল্য সংযোজন করের হার/ সুনিদিষ্ট কর</th>
                  <th className='word-break-wordd'>মূল্য সংযোজন কর/ সুনিদিষ্ট কর এর পরিমাণ (টাকায়)</th>
                  <th className='word-break-wordd'>সকল প্রকার শুল্ক ও করসহ মূল্য</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1</td>
                  <td style={{ padding: '100px 10px' }}>
                    Online Gaming - <br /> {pdfData?.prize_event_info?.title}
                  </td>
                  <td>-</td>
                  <td>1</td>
                  <td>-</td>
                  <td>{getTotal(pdfData)}</td>
                  <td>-</td>
                  <td>15%</td>
                  <td>{Number(pdfData?.vat_amount).toFixed(2)}</td>
                  <td>{pdfData?.total_amount_with_vat_tax}</td>
                </tr>
                <tr style={{ fontWeight: 600 }}>
                  <td colSpan={5} style={{ textAlign: 'end' }}>
                    সর্বমোট
                  </td>
                  {/* <td>1</td>
                  <td></td> */}
                  <td>{getTotal(pdfData)}</td>
                  <td></td>
                  <td></td>
                  <td>{Number(pdfData?.vat_amount).toFixed(2)}</td>
                  <td>{pdfData?.total_amount_with_vat_tax}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className='footer'>
            <p>প্রতিষ্ঠান কর্তৃপক্ষের দায়িত্বপ্রাপ্ত ব্যক্তির নাম: MD Iqbal Hossain</p>
            <p>পদবী: Executive, Accounts & Finance</p>
            <p style={{ margin: '20px 0 40px' }}>স্বাক্ষর:</p>
            <p>সীল:</p>

            <div className='sign-seal'>
              <img src={Sign} alt='' width={150} />
              <img src={Seal} alt='' width={60} className='seal' />
            </div>

            {/* <p className='nb'>
              * উৎসে কর্তনযোগ্য সরবরাহের ক্ষেত্রে ফরমটি সমন্বিত কর চালানপত্র ও উৎসে কর কর্তন সনদপত্র হিসেবে বিবেচিত হইবে
              এবং উহা উৎসে কর কর্তনযোগ্য সরবরাহের ক্ষেত্রে প্রজোয্য হবে।{' '}
            </p>

            <p className='last-price'>সকল প্রকার কর ব্যতীত মূল্য</p> */}
          </div>
        </div>
        <div className='pdf-overlay'></div>
      </div>
    </div>
  );
};

export default PdfForm;
