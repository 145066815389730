import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useStyles from '../index.style';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import CmtAvatar from '@coremat/CmtAvatar';
import GridContainer from '@jumbo/components/GridContainer';
import moment from 'moment';
import { convertSeconds } from 'common/functions';
import { axiosInstance } from 'utils/axios';
import { toast } from 'react-hot-toast';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';

const PEView = ({ open, closeModal, selectedEvent, setData, editMode }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [distributionStatus, setDistributionStatus] = useState('onhold');

  useEffect(() => {
    setDistributionStatus(selectedEvent.prize_distribution_status ? 'sent' : 'onhold');
  }, [selectedEvent]);

  const {
    prize_event_banner_image,
    prize_event_title,
    prize_event_id,
    prize_event_start_time,
    prize_event_end_time,
    prize_event_entry_cost,
    winners,
    event_game_play_count,
    prize_event_session_time,
  } = selectedEvent;

  const handleSave = () => {
    dispatch(fetchStart());
    axiosInstance
      .patch(`/admin/event/change-prize-distribution-status/${selectedEvent?._id}`)
      .then(resp => {
        // console.log(resp.data);
        toast.success('Status updated successfully.');
        closeModal();
        dispatch(fetchSuccess());
        setData(prevData => {
          return {
            ...prevData,
            data: prevData?.data?.map(event => {
              if (event._id === resp?.data?.data?._id) {
                return {
                  ...event,
                  prize_distribution_status: resp?.data?.data?.prize_distribution_status,
                  status: resp?.data?.data?.status,
                };
              }
              return { ...event };
            }),
          };
        });
        // fetchInitial();
      })
      .catch(error => {
        // console.log(error.response);
        dispatch(fetchError());
        toast.error('Sorry, something went wrong. Please try again later.');
      });
  };

  return (
    <Dialog open={open} className={classes.dialogRootUser}>
      <DialogTitle className={classes.dialogTitleRoot}>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='h4' className={classes.titleHead}>
            Event Details
          </Typography>
          <IconButton onClick={closeModal}>
            <ClearIcon />
          </IconButton>
        </Box>
      </DialogTitle>

      <DialogContent dividers>
        <Box>
          <Box mr={3} display='flex' alignItems='start'>
            <Box className={classes.avatarView} mr={{ xs: 4, md: 6 }} display='flex' alignItems='start'>
              <Typography className={classes.titleRoot} style={{ marginRight: 15 }}>
                Event Banner:
              </Typography>
              <CmtAvatar size={70} src={prize_event_banner_image} alt={prize_event_title} />
            </Box>
            <Typography className={classes.titleRoot}>
              Event Id: <span className={classes.titleDesc}>{prize_event_id}</span>{' '}
            </Typography>
          </Box>
          <GridContainer style={{ margin: 10 }} />
          <Typography className={classes.titleRoot}>
            Event Name: <span className={classes.titleDesc}>{prize_event_title}</span>{' '}
          </Typography>
          <GridContainer style={{ margin: 2 }} />
          <Box mr={3} display='flex' alignItems='center'>
            <Typography className={classes.titleRoot} style={{ marginRight: 30 }}>
              Start Date:{' '}
              <span className={classes.titleDesc}>
                {moment(prize_event_start_time, 'DD/MM/YYYY HH:mm').format('DD MMM YYYY; hh:mm A')}
              </span>{' '}
            </Typography>
            <Typography className={classes.titleRoot}>
              End Date:{' '}
              <span className={classes.titleDesc}>
                {moment(prize_event_end_time, 'DD/MM/YYYY HH:mm').format('DD MMM YYYY; hh:mm A')}
              </span>{' '}
            </Typography>
          </Box>
          <GridContainer style={{ margin: 2 }} />
          <Box mr={3} display='flex' alignItems='center' style={{ gap: 50 }}>
            <Typography className={classes.titleRoot}>
              Total Participant: <span className={classes.titleDesc}>{winners?.total_participant_count || 0}</span>{' '}
            </Typography>
            <Typography className={classes.titleRoot}>
              Entry Cost: <span className={classes.titleDesc}>{prize_event_entry_cost}</span>{' '}
            </Typography>
            <Typography className={classes.titleRoot}>
              Total Play Count: <span className={classes.titleDesc}>{event_game_play_count || 0}</span>{' '}
            </Typography>
            <Typography className={classes.titleRoot}>
              Total Session Time:{' '}
              <span className={classes.titleDesc}>
                {prize_event_session_time ? convertSeconds(prize_event_session_time) : '0d 0h 0m 0s'}
              </span>{' '}
            </Typography>
          </Box>
          <GridContainer style={{ margin: 2 }} />
          {editMode && (
            <Box sx={{ minWidth: 120 }} className={classes.codeStatus}>
              <Typography className={classes.titleRoot} style={{ marginRight: 15, minWidth: 190 }}>
                Distribution Status:{' '}
              </Typography>
              <FormControl size='small' variant='outlined' style={{ width: 120 }}>
                <InputLabel id='demo-simple-select-label'>Status</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={distributionStatus}
                  label='Status'
                  onChange={e => setDistributionStatus(e.target.value)}
                >
                  <MenuItem value='onhold'>On Hold</MenuItem>
                  <MenuItem value='sent'>Sent</MenuItem>
                </Select>
              </FormControl>
            </Box>
          )}
          <GridContainer style={{ margin: 2 }} />
          <Typography className={classes.titleRoot}>Winner List:</Typography>
          <GridContainer style={{ margin: 5 }} />
          <WinnerTable classes={classes} winners={winners} />
        </Box>
      </DialogContent>

      <DialogActions>
        <Box display='flex' justifyContent='flex-end' mb={4}>
          <Button onClick={closeModal}>Cancel</Button>
          {editMode ? (
            <Box ml={2}>
              <Button variant='contained' color='primary' onClick={handleSave}>
                Save
              </Button>
            </Box>
          ) : (
            <Box ml={2}>
              <Button variant='contained' color='primary' onClick={closeModal}>
                Close
              </Button>
            </Box>
          )}
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default PEView;

const WinnerTable = ({ classes, winners }) => {
  return (
    <TableContainer component={Paper} style={{ maxHeight: 250 }}>
      <Table stickyHeader className={classes.table} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell>Rank</TableCell>
            <TableCell align='center'>Username</TableCell>
            <TableCell align='center'>App Login No.</TableCell>
            <TableCell align='center'>Payment Trx. No.</TableCell>
            <TableCell align='center'>Trx. Id</TableCell>
            <TableCell align='center'>Prize</TableCell>
            <TableCell align='center'>Score</TableCell>
            <TableCell align='center'>High Score Session Time</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {winners?.leaderBoard?.length > 0 ? (
            winners?.leaderBoard?.map(winner => (
              <TableRow key={winner?._id}>
                <TableCell component='th' scope='row'>
                  {winner?.rank}
                </TableCell>
                <TableCell align='center'>{winner?.user_name}</TableCell>
                <TableCell align='center'>{winner?.phone}</TableCell>
                <TableCell align='center'>{winner?.bkash_phone}</TableCell>
                <TableCell align='center'>{winner?.trx_id}</TableCell>
                <TableCell align='center'>{winner?.prize}</TableCell>
                <TableCell align='center'>{winner?.game_score}</TableCell>
                <TableCell align='center'>
                  {winner?.session_time ? convertSeconds(winner?.session_time) : '0d 0h 0m 0s'}
                </TableCell>
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={8} rowSpan={2}>
                <p>Sorry, there are no records found.</p>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
