import { Button, Popper } from '@material-ui/core';
import React from 'react';
import { CloseOutlined } from '@material-ui/icons';
import { useDispatch } from 'react-redux';
import { fetchError } from 'redux/actions';
import { DateTimePicker } from '@mantine/dates';

const Popover = ({ showPopover, setShowPopover, value, setValue }) => {
  const dispatch = useDispatch();

  const open = Boolean(showPopover);
  const id = open ? 'simple-popper' : undefined;

  const handleApplyDateRange = () => {
    if (!value) {
      dispatch(fetchError('Please select date and time.'));
      return;
    }

    // console.log(value);
    setShowPopover(null);
  };

  return (
    <Popper placement='top' id={id} open={open} anchorEl={showPopover}>
      <div className='anayltic-popover' style={{ width: 250 }}>
        <div className='flex justify-end pointer'>
          <CloseOutlined style={{ fill: '#000', width: 30 }} onClick={() => setShowPopover(null)} />
        </div>
        <DateTimePicker
          value={value}
          onChange={setValue}
          label='Pick date and time'
          placeholder='Pick date and time'
          maw={400}
          mx='auto'
          minDate={new Date()}
          valueFormat='hh:mm A - DD.MM.YYYY'
        />
        <Button onClick={handleApplyDateRange} variant='contained' color='primary' className='save'>
          Ok
        </Button>
      </div>
    </Popper>
  );
};

export default Popover;
