import React, { useEffect, useState } from 'react';
import useStyles from './index.style';
import SearchTransaction from './components/SearchTransaction.Component';
import PaymentHistory from './components/PaymentHistory.Component';
import Pdf from './components/Pdf.Component';
import { commonFetchAllEvents } from 'redux/actions/commonDataFetch';
import { C_S_D_SET_ALL_EVENTS } from '@jumbo/constants/ActionTypes';
import { useDispatch, useSelector } from 'react-redux';

const tabs = ['Payment History', 'Transactions'];

const Finance = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { allEvents: events } = useSelector(({ CommonSharedData }) => CommonSharedData);
  const [activeTab, setActiveTab] = useState(tabs[0]);

  useEffect(() => {
    if (!events) {
      dispatch(commonFetchAllEvents());
    }
  }, []);

  return (
    <div className={classes.root}>
      <div className='analyticTabs'>
        {tabs.map((tab, i) => (
          <button onClick={() => setActiveTab(tab)} className={activeTab === tab ? 'active' : ''} key={i}>
            {tab}
          </button>
        ))}
      </div>

      {activeTab === 'Payment History' && <PaymentHistory />}
      {activeTab === 'Transactions' && <SearchTransaction />}
      {activeTab === 'Pdf' && <Pdf />}
    </div>
  );
};

export default Finance;
