import React, { useCallback, useEffect, useState } from 'react';
import useStyles from './index.style';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core';
import CmtSearch from '@coremat/CmtSearch';
import { useDispatch } from 'react-redux';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import NoRecordFound from 'components/NoRecordFound.Component';
import moment from 'moment';
import { Delete, Edit, MoreHoriz, Visibility } from '@material-ui/icons';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import { Pagination } from '@material-ui/lab';
import { axiosInstance } from 'utils/axios';
import ULBan from './components/ULBan.Component';
import ULEdit from './components/ULEdit.Component';
import { Toaster } from 'react-hot-toast';
import ULDetails from './components/ULDetails.Component';

const pageSize = 20;

const UserList = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [search, setSearch] = useState('');

  const [data, setData] = useState(null);
  const [paginationDisabled, setPaginationDisabled] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedUser, setSelectedUser] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showBanModal, setShowBanModal] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = (search, page = 1) => {
    dispatch(fetchStart());
    setPaginationDisabled(true);
    const searchTxt = search || '';

    axiosInstance
      .get(`/admin/user/list`, {
        params: { page: page, size: pageSize, search: searchTxt },
      })
      .then(resp => {
        // console.log(resp.data);
        setTotalPages(Math.ceil(resp?.data?.total_count / pageSize));
        setData(resp?.data);
        dispatch(fetchSuccess());
        setPaginationDisabled(false);
      })
      .catch(error => {
        // console.log(error.response);
        setPaginationDisabled(false);
        dispatch(fetchError('Sorry, something went wrong. Please try again later.'));
      });
  };

  const handlePagination = (e, page) => {
    setCurrentPage(page);
    fetchData(search, page);
    // console.log(dateRange);
  };

  const handleSearch = e => {
    e.preventDefault();

    // console.log(search);
    setCurrentPage(1);
    fetchData(search, 1);
  };

  const handleViewUser = useCallback(user => {
    // console.log('view user', user);
    setSelectedUser(user);
    setShowDetailsModal(true);
  }, []);

  const handleEditUser = useCallback(user => {
    // console.log('edit user', user);
    setSelectedUser(user);
    setShowEditModal(true);
  }, []);

  const handleBanUser = useCallback(user => {
    // console.log('ban user', user);
    setSelectedUser(user);
    setShowBanModal(true);
  }, []);

  const fetchUsersAfterBan = () => {
    fetchData(search, currentPage);
    resetAllModal();
  };

  const resetAllModal = () => {
    setSelectedUser(null);
    setShowDetailsModal(false);
    setShowEditModal(false);
    setShowBanModal(false);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar className={classes.root} display='flex' style={{ justifyContent: 'space-between' }}>
          <Typography className={classes.title} variant='h4' id='tableTitle' component='div'>
            Users: {data?.total_count || 0}
          </Typography>

          <form onSubmit={handleSearch}>
            <Box display='flex'>
              <CmtSearch
                placeholder='name, phone'
                onChange={e => setSearch(e.target.value)}
                value={search}
                border={true}
                onlyIcon={false}
              />
              <Button type='submit' style={{ marginLeft: 10 }} variant='contained' color='primary'>
                Search
              </Button>
            </Box>
          </form>
        </Toolbar>

        <TableContainer className={classes.container}>
          <Table stickyHeader>
            <UserTableHead />
            {data && (
              <TableBody>
                {data?.data?.length > 0 ? (
                  data?.data?.map(user => (
                    <UserTableBody
                      key={user?._id}
                      user={user}
                      handleBanUser={handleBanUser}
                      handleEditUser={handleEditUser}
                      handleViewUser={handleViewUser}
                    />
                  ))
                ) : (
                  <TableRow style={{ height: 200 }}>
                    <TableCell colSpan={11} rowSpan={7}>
                      <NoRecordFound>There are no records found.</NoRecordFound>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        {totalPages > 1 && (
          <Box px={4} py={5} display='flex' justifyContent='flex-end'>
            <Pagination
              disabled={paginationDisabled}
              page={currentPage}
              count={totalPages}
              color='primary'
              onChange={handlePagination}
            />
          </Box>
        )}

        {showDetailsModal && (
          <ULDetails open={showDetailsModal} closeModal={resetAllModal} selectedUser={selectedUser} />
        )}

        {showEditModal && (
          <ULEdit open={showEditModal} closeModal={resetAllModal} selectedUser={selectedUser} setData={setData} />
        )}

        {showBanModal && (
          <ULBan
            open={showBanModal}
            closeModal={resetAllModal}
            selectedUser={selectedUser}
            fetchUsersAfterBan={fetchUsersAfterBan}
          />
        )}
      </Paper>

      <Toaster position='top-right' toastOptions={{ duration: 3000 }} />
    </div>
  );
};

export default UserList;

const UserTableBody = React.memo(({ user, handleViewUser, handleEditUser, handleBanUser }) => {
  const onUserMenuClick = menu => {
    if (menu.action === 'view') {
      handleViewUser(user);
    } else if (menu.action === 'edit') {
      handleEditUser(user);
    } else if (menu.action === 'ban') {
      handleBanUser(user);
    }
  };

  const getUserActions = () => {
    const actions = [
      { action: 'view', label: 'Details', icon: <Visibility /> },
      { action: 'edit', label: 'Edit', icon: <Edit /> },
      { action: 'ban', label: 'Ban', icon: <Delete /> },
    ];
    return actions;
  };

  const userActions = getUserActions(user);

  return (
    <TableRow>
      <TableCell align='left' style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
        <img style={{ borderRadius: 50 }} width={40} height={40} src={user?.profile_picture} alt={user?.user_name} />
        {user?.user_name}
      </TableCell>
      <TableCell align='left'>{user?.phone}</TableCell>
      {/* <TableCell align='left'>
        {!user.phone || user.phone === 'N/A' ? (
          <span className='text-danger'>Inactive</span>
        ) : (
          <span className='text-success'>Active</span>
        )}
      </TableCell> */}
      <TableCell align='left'>
        {/* {user?.registration_date ? (
          <>
            {moment(user.registration_date, 'DD/MM/YYYY ').isValid()
              ? moment(user.registration_date, 'DD/MM/YYYY HH:mm').format('hh.mm A, DD MMM YY')
              : ''}
            {moment(user.registration_date).isValid()
              ? moment(user.registration_date).format('hh.mm A, DD MMM YY')
              : ''}
          </>
        ) : (
          ''
        )} */}
        {user?.registration_date ? (
          <>
            {moment(user.registration_date, 'DD/MM/YYYY HH:mm').isValid() ? (
              moment(user.registration_date, 'DD/MM/YYYY HH:mm').format('hh.mm A, DD MMM YY')
            ) : (
              <>
                {moment(user.registration_date, 'YYYY-MM-DDTHH:mm').isValid()
                  ? moment(user.registration_date).format('hh.mm A, DD MMM YY')
                  : '-'}
              </>
            )}
          </>
        ) : (
          ''
        )}
      </TableCell>
      <TableCell align='left' onClick={e => e.stopPropagation()}>
        <CmtDropdownMenu items={userActions} onItemClick={onUserMenuClick} TriggerComponent={<MoreHoriz />} />
      </TableCell>
    </TableRow>
  );
});

const UserTableHead = React.memo(() => {
  const tableHeader = ['Name', 'Phone', 'Sign up Date', 'Actions'];

  return (
    <TableHead>
      <TableRow>
        {tableHeader?.map((d, i) => (
          <TableCell key={i} align='left' padding={'normal'}>
            {d}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
});
