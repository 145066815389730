import React, { useEffect } from 'react';
import useStyles from '../index.style';
import { Box, Button, Paper, Toolbar, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import OPCSingleCard from './OPCSingleCard.Component';
import { Pagination } from '@material-ui/lab';
import OPCAddEditModal from './OPCAddEditModal.Component';
import { useState } from 'react';
import Axios from 'axios';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';

const pageSize = 5;

const OPCDiscountOffer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [openModal, setOpenModal] = useState(false);

  const [eventsAndGoods, setEventsAndGoods] = useState({});

  const [discountData, setDiscountData] = useState(null);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationDisabled, setPaginationDisabled] = useState(false);

  const [editData, setEditData] = useState(null);

  useEffect(() => {
    getEventAndPhysical();
    getAllReferrals();
  }, []);

  const getEventAndPhysical = () => {
    Axios.get(`${process.env.REACT_APP_BASE_URL}/admin/referral/type-list`)
      .then(resp => {
        // console.log(resp?.data?.data);
        setEventsAndGoods(resp?.data?.data);
      })
      .catch(error => {
        // console.log(error.response);
      });
  };

  const getAllReferrals = (page = 1) => {
    dispatch(fetchStart());
    setPaginationDisabled(true);
    Axios.get(`${process.env.REACT_APP_BASE_URL}/admin/referral/offer`, {
      params: {
        page: page,
        size: pageSize,
      },
    })
      .then(resp => {
        // console.log(resp?.data);
        setTotalPages(Math.ceil(resp?.data?.total_count / pageSize));
        setDiscountData(resp?.data);
        dispatch(fetchSuccess());
        setPaginationDisabled(false);
      })
      .catch(error => {
        dispatch(fetchError('Sorry, something went wrong. Please try again later.'));
        setPaginationDisabled(false);
        // console.log(error.response);
      });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setEditData(null);
  };

  const handlePagination = (e, page) => {
    setCurrentPage(page);
    getAllReferrals(page);
    // console.log(dateRange);
  };

  return (
    <div className='past-offer'>
      <Paper className={classes.paper}>
        <Toolbar className={classes.root} style={{ justifyContent: 'space-between' }}>
          <Box>
            <Typography className={classes.title} variant='h4' id='tableTitle' component='div'>
              Referral Offers (Ongoing & Upcoming): {discountData?.total_count}
            </Typography>
          </Box>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Button variant='contained' color='primary' className='save' onClick={() => setOpenModal(true)}>
              Create Ref. Offer
            </Button>
          </div>
        </Toolbar>
      </Paper>

      {discountData && discountData?.data?.length > 0 ? (
        discountData?.data?.map(data => (
          <OPCSingleCard
            key={data?._id}
            data={data}
            isEditable={true}
            setOpenModal={setOpenModal}
            setEditData={setEditData}
            setDiscountData={setDiscountData}
          />
        ))
      ) : (
        <Paper className={classes.paper}>
          <Box padding={3}>
            <Typography>There are no records found.</Typography>
          </Box>
        </Paper>
      )}

      {totalPages > 1 && (
        <Box p={3} display='flex' justifyContent='end'>
          <Pagination
            disabled={paginationDisabled}
            page={currentPage}
            count={totalPages}
            color='primary'
            onChange={handlePagination}
          />
        </Box>
      )}

      {openModal && (
        <OPCAddEditModal
          handleCloseModal={handleCloseModal}
          open={openModal}
          eventsAndGoods={eventsAndGoods}
          getAllReferrals={getAllReferrals}
          setCurrentPage={setCurrentPage}
          editData={editData}
          setDiscountData={setDiscountData}
        />
      )}
    </div>
  );
};

export default OPCDiscountOffer;
