import React from 'react';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import PdfForm from './PdfForm.Component';

const Pdf = () => {
  const handleDownload = () => {
    html2canvas(document.querySelector('#pdfForm')).then(canvas => {
      let image = canvas.toDataURL('image/png');
      const doc = new jsPDF('p', 'mm', 'a4', true);
      const width = doc.internal.pageSize.getWidth() - 10;
      const height = (canvas.height * width) / canvas.width;
      doc.addImage(image, 'PNG', 5, 10, width, height);
      doc.save(`image.pdf`);
    });
  };

  return (
    <div>
      <button onClick={handleDownload}>Click Me</button>
      <PdfForm />
    </div>
  );
};

export default Pdf;
