import React from 'react';
import useStyles from '../index.style';
import { Box, Button, IconButton, Paper, TextField, Toolbar, Tooltip, Typography } from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import ConfirmDialog from '@jumbo/components/Common/ConfirmDialog';
import { useState } from 'react';
import { useEffect } from 'react';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import { useDispatch } from 'react-redux';
import Axios from 'axios';
import moment from 'moment';
import CustomAlerts from 'components/CustomAlerts.Component';

const OPCController = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [showConfirm, setShowConfirm] = useState(false);

  const [showToast, setShowToast] = useState(false);
  const [toastMsg] = useState('Data update success.');

  const [loading, setLoading] = useState(false);

  const [controllerData, setControllerData] = useState({
    RP_INCREMENT_VALUE_FOR_REG_USER: '',
    RP_INCREMENT_VALUE_FOR_PURCHASING_EVENT: '',
    RP_INCREMENT_VALUE_FOR_GAMETIME: '',
    UNIT_GAMETIME_FOR_RP_INCREMENT_IN_MINUTES: '',

    REFERRAL_STATUS: false,
    DEFAULT_RP_FOR_LINK: '',

    LEVEL_UP_MULTIPLIER: '',
    LEVEL_UP_BASE_VALUE: '',
  });
  const [controllerDate, setControllerDate] = useState({});
  const [controllerApiData, setControllerApiData] = useState({});

  useEffect(() => {
    fetchController(setLoading(true));
  }, []);

  const fetchController = callback => {
    dispatch(fetchStart());
    if (callback && typeof callback === 'function') callback();

    Axios.get(`${process.env.REACT_APP_BASE_URL}/admin/system-constant`)
      .then(resp => {
        // console.log(resp?.data);

        let dates = {};

        const a = resp?.data?.data.forEach(d => {
          // console.log(d.key_name, d.value);
          setControllerData(prevData => ({ ...prevData, [d.key_name]: d.value }));
          setControllerApiData(prevData => ({ ...prevData, [d.key_name]: d.value }));
          dates[d.key_name] = moment(d.updated_at).format('DD-MM-YYYY; hh:mm A');
        });

        setControllerDate(dates);

        setLoading(false);

        dispatch(fetchSuccess());
      })
      .catch(error => {
        // console.log(error.response);
        setLoading(false);
        dispatch(fetchError('Sorry, something went wrong.'));
      });
  };

  const postController = (data, callback) => {
    dispatch(fetchStart());

    Axios.post(`${process.env.REACT_APP_BASE_URL}/admin/system-constant`, data)
      .then(resp => {
        fetchController();
        // console.log(resp?.data);
        // dispatch(fetchSuccess('Updated done'));
        if (callback && typeof callback === 'function') callback();
        setShowToast(true);
      })
      .catch(error => {
        // console.log(error.response);
        dispatch(fetchError('Sorry, something went wrong.'));
      });
  };

  const handleCancelPause = () => {
    setShowConfirm(false);
  };

  const handleConfirmPause = () => {
    const data = {
      key_name: 'REFERRAL_STATUS',
      value: !controllerApiData.REFERRAL_STATUS,
    };

    // console.log(data);

    postController([data], setShowConfirm(false));
  };

  const handleInput = e => {
    const { name, value } = e.target;
    // console.log(name, value);
    setControllerData(prevData => ({ ...prevData, [name]: value }));
  };

  const handleUpdate = type => {
    // console.log(type);
    // console.log(controllerData[type]);

    if (!controllerData[type]) {
      dispatch(fetchError('Please input some value and then update.'));
      return;
    }

    if (controllerData[type] < 1) {
      dispatch(fetchError('Input can not be less than 1.'));
      return;
    }

    const data = {
      key_name: type,
      value: controllerData[type],
    };

    // console.log([data]);
    postController([data]);
  };

  const handleUpdateMinutePoint = () => {
    if (!controllerData.UNIT_GAMETIME_FOR_RP_INCREMENT_IN_MINUTES || !controllerData.RP_INCREMENT_VALUE_FOR_GAMETIME) {
      dispatch(fetchError('Please input some value and then update.'));
      return;
    }

    if (
      controllerData.UNIT_GAMETIME_FOR_RP_INCREMENT_IN_MINUTES < 1 ||
      controllerData.RP_INCREMENT_VALUE_FOR_GAMETIME < 1
    ) {
      dispatch(fetchError('Input can not be less than 1.'));
      return;
    }

    const data = [
      {
        key_name: 'UNIT_GAMETIME_FOR_RP_INCREMENT_IN_MINUTES',
        value: controllerData.UNIT_GAMETIME_FOR_RP_INCREMENT_IN_MINUTES,
      },
      {
        key_name: 'RP_INCREMENT_VALUE_FOR_GAMETIME',
        value: controllerData.RP_INCREMENT_VALUE_FOR_GAMETIME,
      },
    ];

    // console.log(data);
    postController(data);
  };

  return (
    !loading && (
      <>
        <RpCard
          classes={classes}
          controllerData={controllerData}
          handleInput={handleInput}
          handleUpdate={handleUpdate}
          controllerApiData={controllerApiData}
          controllerDate={controllerDate}
          handleUpdateMinutePoint={handleUpdateMinutePoint}
        />
        <LuCard
          classes={classes}
          controllerData={controllerData}
          handleInput={handleInput}
          handleUpdate={handleUpdate}
          controllerApiData={controllerApiData}
          controllerDate={controllerDate}
        />

        <ReceiverPointCard
          classes={classes}
          controllerData={controllerData}
          handleInput={handleInput}
          handleUpdate={handleUpdate}
          controllerApiData={controllerApiData}
          controllerDate={controllerDate}
        />

        <Box>
          <span className='fw-600'>{controllerData?.REFERRAL_STATUS ? 'Pause' : 'Start'} Referral Program </span>
          <Button
            variant='contained'
            color={controllerData?.REFERRAL_STATUS ? 'secondary' : 'primary'}
            style={{ width: 95, marginLeft: 10 }}
            onClick={() => setShowConfirm(true)}
          >
            {controllerData?.REFERRAL_STATUS ? 'Pause' : 'Start'}
          </Button>
        </Box>

        <ConfirmDialog
          open={showConfirm}
          title={'Pause Referral Program'}
          content={'Are you sure you want to pause the program?'}
          onClose={handleCancelPause}
          onConfirm={handleConfirmPause}
        />

        <CustomAlerts open={showToast} setOpen={setShowToast} message={toastMsg} type='success' />
      </>
    )
  );
};

export default OPCController;

const RpCard = ({
  classes,
  controllerData,
  handleInput,
  handleUpdate,
  controllerApiData,
  controllerDate,
  handleUpdateMinutePoint,
}) => (
  <Paper className={classes.paper + ' controller'}>
    <Toolbar>
      <Typography className={classes.title} variant='h4' component='div'>
        RP Table - Point Controller
      </Typography>
    </Toolbar>
    <Box mx={3} pb={4}>
      <ul>
        <li>
          <div className='left'>
            <span className='title'>• New Affiliated User Registered</span>
            <div className='inputs'>
              <div className='single'>
                <TextField
                  name='RP_INCREMENT_VALUE_FOR_REG_USER'
                  value={controllerData.RP_INCREMENT_VALUE_FOR_REG_USER}
                  onChange={handleInput}
                  style={{ width: 100 }}
                  type='number'
                  size='small'
                  variant='outlined'
                />
                <span>Points</span>
              </div>
            </div>
          </div>
          <div className='right' style={{ gap: 10 }}>
            <Button
              variant='contained'
              color='primary'
              style={{ width: 95 }}
              onClick={() => handleUpdate('RP_INCREMENT_VALUE_FOR_REG_USER')}
            >
              {!controllerApiData?.RP_INCREMENT_VALUE_FOR_REG_USER ? 'Push' : 'Update'}
            </Button>
            {!controllerDate?.RP_INCREMENT_VALUE_FOR_REG_USER ? (
              <Box width={48} />
            ) : (
              <Tooltip
                arrow
                title={`Last updated on ${controllerDate?.RP_INCREMENT_VALUE_FOR_REG_USER}`}
                placement='top'
              >
                <IconButton aria-label='delete'>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </li>
        <li>
          <div className='left'>
            <span className='title'>• Affiliated user Purchased Prize Event</span>
            <div className='inputs'>
              <div className='single'>
                <TextField
                  name='RP_INCREMENT_VALUE_FOR_PURCHASING_EVENT'
                  value={controllerData.RP_INCREMENT_VALUE_FOR_PURCHASING_EVENT}
                  onChange={handleInput}
                  style={{ width: 100 }}
                  type='number'
                  size='small'
                  variant='outlined'
                />
                <span>Points</span>
              </div>
            </div>
          </div>
          <div className='right' style={{ gap: 10 }}>
            <Button
              variant='contained'
              color='primary'
              style={{ width: 95 }}
              onClick={() => handleUpdate('RP_INCREMENT_VALUE_FOR_PURCHASING_EVENT')}
            >
              {!controllerApiData?.RP_INCREMENT_VALUE_FOR_PURCHASING_EVENT ? 'Push' : 'Update'}
            </Button>
            {!controllerDate?.RP_INCREMENT_VALUE_FOR_PURCHASING_EVENT ? (
              <Box width={48} />
            ) : (
              <Tooltip
                arrow
                title={`Last updated on ${controllerDate?.RP_INCREMENT_VALUE_FOR_PURCHASING_EVENT}`}
                placement='top'
              >
                <IconButton aria-label='delete'>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </li>
        <li>
          <div className='left'>
            <span className='title'>• Affiliated user played games</span>
            <div className='inputs'>
              <div className='single'>
                <TextField
                  name='UNIT_GAMETIME_FOR_RP_INCREMENT_IN_MINUTES'
                  value={controllerData.UNIT_GAMETIME_FOR_RP_INCREMENT_IN_MINUTES}
                  onChange={handleInput}
                  style={{ width: 100 }}
                  type='number'
                  size='small'
                  variant='outlined'
                />
                <span>minutes</span>
              </div>
              <div className='single'>
                <TextField
                  name='RP_INCREMENT_VALUE_FOR_GAMETIME'
                  value={controllerData.RP_INCREMENT_VALUE_FOR_GAMETIME}
                  onChange={handleInput}
                  style={{ width: 100 }}
                  type='number'
                  size='small'
                  variant='outlined'
                />
                <span>Points</span>
              </div>
            </div>
          </div>
          <div className='right' style={{ gap: 10 }}>
            <Button variant='contained' color='primary' style={{ width: 95 }} onClick={() => handleUpdateMinutePoint()}>
              {!controllerApiData?.UNIT_GAMETIME_FOR_RP_INCREMENT_IN_MINUTES &&
              !controllerApiData?.RP_INCREMENT_VALUE_FOR_GAMETIME
                ? 'Push'
                : 'Update'}
            </Button>
            {!controllerDate?.UNIT_GAMETIME_FOR_RP_INCREMENT_IN_MINUTES &&
            !controllerDate?.RP_INCREMENT_VALUE_FOR_GAMETIME ? (
              <Box width={48} />
            ) : (
              <Tooltip
                arrow
                title={`Last updated on ${controllerDate?.RP_INCREMENT_VALUE_FOR_GAMETIME}`}
                placement='top'
              >
                <IconButton aria-label='delete'>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            )}
          </div>
        </li>
      </ul>
    </Box>
  </Paper>
);

const LuCard = ({ classes, controllerData, handleInput, handleUpdate, controllerApiData, controllerDate }) => (
  <Paper className={classes.paper + ' controller'}>
    <Toolbar>
      <Typography className={classes.title} variant='h4' component='div'>
        Level Up - Point Controller
      </Typography>
    </Toolbar>
    <Box mx={3} pb={4}>
      <ul>
        <li>
          <div className='left'>
            <span className='title'>• Multiplier</span>
            <div className='inputs'>
              <div className='single'>
                <TextField
                  name='LEVEL_UP_MULTIPLIER'
                  value={controllerData.LEVEL_UP_MULTIPLIER}
                  onChange={handleInput}
                  style={{ width: 100 }}
                  type='number'
                  size='small'
                  variant='outlined'
                />
                {/* <span>Points</span> */}
              </div>
            </div>
          </div>
          <div className='right' style={{ gap: 10 }}>
            <Button
              variant='contained'
              color='primary'
              style={{ width: 95 }}
              onClick={() => handleUpdate('LEVEL_UP_MULTIPLIER')}
            >
              {!controllerApiData?.LEVEL_UP_MULTIPLIER ? 'Push' : 'Update'}
            </Button>
            {!controllerDate?.LEVEL_UP_MULTIPLIER ? (
              <Box width={48} />
            ) : (
              <Tooltip arrow title={`Last updated on ${controllerDate?.LEVEL_UP_MULTIPLIER}`} placement='top'>
                <IconButton aria-label='delete'>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            )}
            {/* <span style={{ color: 'gray' }}>Last updated on 15.04.23</span> */}
          </div>
        </li>
        <li>
          <div className='left'>
            <span className='title'>• Base Value</span>
            <div className='inputs'>
              <div className='single'>
                <TextField
                  name='LEVEL_UP_BASE_VALUE'
                  value={controllerData.LEVEL_UP_BASE_VALUE}
                  onChange={handleInput}
                  style={{ width: 100 }}
                  type='number'
                  size='small'
                  variant='outlined'
                />
                {/* <span>Points</span> */}
              </div>
            </div>
          </div>
          <div className='right' style={{ gap: 10 }}>
            <Button
              variant='contained'
              color='primary'
              style={{ width: 95 }}
              onClick={() => handleUpdate('LEVEL_UP_BASE_VALUE')}
            >
              {!controllerApiData?.LEVEL_UP_BASE_VALUE ? 'Push' : 'Update'}
            </Button>
            {!controllerDate?.LEVEL_UP_BASE_VALUE ? (
              <Box width={48} />
            ) : (
              <Tooltip arrow title={`Last updated on ${controllerDate?.LEVEL_UP_BASE_VALUE}`} placement='top'>
                <IconButton aria-label='delete'>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            )}
            {/* <span style={{ color: 'gray' }}>Last updated on 15.04.23</span> */}
          </div>
        </li>
      </ul>
    </Box>
  </Paper>
);

const ReceiverPointCard = ({
  classes,
  controllerData,
  handleInput,
  handleUpdate,
  controllerApiData,
  controllerDate,
}) => (
  <Paper className={classes.paper + ' controller'}>
    <Toolbar>
      <Typography className={classes.title} variant='h4' component='div'>
        Receiver Point
      </Typography>
    </Toolbar>
    <Box mx={3} pb={4}>
      <ul>
        <li>
          <div className='left'>
            <span className='title'>• RP Value</span>
            <div className='inputs'>
              <div className='single'>
                <TextField
                  name='DEFAULT_RP_FOR_LINK'
                  value={controllerData.DEFAULT_RP_FOR_LINK}
                  onChange={handleInput}
                  style={{ width: 100 }}
                  type='number'
                  size='small'
                  variant='outlined'
                />
                {/* <span>Points</span> */}
              </div>
            </div>
          </div>
          <div className='right' style={{ gap: 10 }}>
            <Button
              variant='contained'
              color='primary'
              style={{ width: 95 }}
              onClick={() => handleUpdate('DEFAULT_RP_FOR_LINK')}
            >
              {!controllerApiData?.DEFAULT_RP_FOR_LINK ? 'Push' : 'Update'}
            </Button>
            {!controllerDate?.DEFAULT_RP_FOR_LINK ? (
              <Box width={48} />
            ) : (
              <Tooltip arrow title={`Last updated on ${controllerDate?.DEFAULT_RP_FOR_LINK}`} placement='top'>
                <IconButton aria-label='delete'>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            )}
            {/* <span style={{ color: 'gray' }}>Last updated on 15.04.23</span> */}
          </div>
        </li>
      </ul>
    </Box>
  </Paper>
);
