import React, { useEffect, useState } from 'react';
import useStyles from './index.style';
import { useDispatch } from 'react-redux';
import Tabs from 'components/Tabs.Component';
import AllGames from './components/AllGames.Component';
import AllEvents from './components/AllEvents.Component';
import { Toaster } from 'react-hot-toast';
import PaidUsers from './components/PaidUsers.Component';
import { resetAnalyticData } from 'redux/actions/Analytic';
import DynamicLinks from './components/DynamicLinks.Component';

const tabs = ['Total', 'Paid Users', 'Events', 'Dynamic Links'];

const Analytic = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState(tabs[0]);

  useEffect(() => {
    return () => {
      dispatch(resetAnalyticData());
    };
  }, [dispatch]);

  return (
    <div className={classes.root}>
      <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />

      {activeTab === 'Total' && <AllGames />}

      {activeTab === 'Paid Users' && <PaidUsers />}

      {activeTab === 'Events' && <AllEvents />}

      {activeTab === 'Dynamic Links' && <DynamicLinks />}

      <Toaster position='top-right' toastOptions={{ duration: 3000 }} />
    </div>
  );
};

export default Analytic;
