import React, { useCallback, useEffect, useState } from 'react';
import useStyles from './index.style';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core';
import CmtSearch from '@coremat/CmtSearch';
import { useDispatch } from 'react-redux';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import NoRecordFound from 'components/NoRecordFound.Component';
import { MoreHoriz, Visibility } from '@material-ui/icons';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import { Pagination } from '@material-ui/lab';
import { axiosInstance } from 'utils/axios';
import { Toaster } from 'react-hot-toast';
import DRSummeryComponent from './components/DrAndAdSummery.Component';
import moment from 'moment';

const pageSize = 20;

const AccountDeleted = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [search, setSearch] = useState('');

  const [data, setData] = useState(null);
  const [paginationDisabled, setPaginationDisabled] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedUser, setSelectedUser] = useState(null);
  const [showSummaryModal, setShowSummaryModal] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = (search, page = 1) => {
    dispatch(fetchStart());
    setPaginationDisabled(true);
    const searchTxt = search || '';

    axiosInstance
      .get(`/admin/user/delete`, {
        params: { page: page, size: pageSize, search: searchTxt },
      })
      .then(resp => {
        // console.log(resp.data);
        setTotalPages(Math.ceil(resp?.data?.total_count / pageSize));
        setData(resp.data);
        dispatch(fetchSuccess());
        setPaginationDisabled(false);
      })
      .catch(error => {
        // console.log(error.response);
        setPaginationDisabled(false);
        dispatch(fetchError('Sorry, something went wrong. Please try again later.'));
      });
  };

  const handlePagination = (e, page) => {
    setCurrentPage(page);
    fetchData(search, page);
    // console.log(dateRange);
  };

  const handleSearch = e => {
    e.preventDefault();

    // console.log(search);
    setCurrentPage(1);
    fetchData(search, 1);
  };

  const handleViewSummary = useCallback(user => {
    // console.log(user);
    setSelectedUser(user);
    setShowSummaryModal(true);
  }, []);

  const resetModals = () => {
    setShowSummaryModal(false);
    setSelectedUser(null);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar className={classes.root} display='flex' style={{ justifyContent: 'space-between' }}>
          <Typography className={classes.title} variant='h4' id='tableTitle' component='div'>
            Delete Request: {data?.total_count || 0}
          </Typography>

          <form onSubmit={handleSearch}>
            <Box display='flex'>
              <CmtSearch
                placeholder='name, phone'
                onChange={e => setSearch(e.target.value)}
                value={search}
                border={true}
                onlyIcon={false}
              />
              <Button type='submit' style={{ marginLeft: 10 }} variant='contained' color='primary'>
                Search
              </Button>
            </Box>
          </form>
        </Toolbar>

        <TableContainer className={classes.container}>
          <Table stickyHeader>
            <UserTableHead />
            {data && (
              <TableBody>
                {data?.data?.length > 0 ? (
                  data?.data?.map(user => (
                    <UserTableBody key={user?._id} user={user} handleViewSummary={handleViewSummary} />
                  ))
                ) : (
                  <TableRow style={{ height: 200 }}>
                    <TableCell colSpan={11} rowSpan={7}>
                      <NoRecordFound>There are no records found.</NoRecordFound>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        {totalPages > 1 && (
          <Box px={4} py={5} display='flex' justifyContent='flex-end'>
            <Pagination
              disabled={paginationDisabled}
              page={currentPage}
              count={totalPages}
              color='primary'
              onChange={handlePagination}
            />
          </Box>
        )}
      </Paper>

      {showSummaryModal && (
        <DRSummeryComponent open={showSummaryModal} closeModal={resetModals} selectedUser={selectedUser} />
      )}

      <Toaster position='top-right' toastOptions={{ duration: 3000 }} />
    </div>
  );
};

export default AccountDeleted;

const UserTableBody = React.memo(({ user, handleViewSummary }) => {
  const onUserMenuClick = menu => {
    if (menu.action === 'summary') {
      handleViewSummary(user);
    }
  };

  const getUserActions = () => {
    const actions = [{ action: 'summary', label: 'Summary', icon: <Visibility /> }];
    return actions;
  };

  const userActions = getUserActions(user);

  const getStatus = data => {
    if (data.deletion_request === 1) {
      return <span className='text-warning'>Applied for deletion</span>;
    } else if (data.deletion_request === 2) {
      return <span className='text-success'>Code Sent</span>;
    } else {
      return <span></span>;
    }
  };

  return (
    <TableRow>
      <TableCell align='left' style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
        <img style={{ borderRadius: 50 }} width={40} height={40} src={user?.profile_picture} alt={user?.user_name} />
        {user?.user_name}
      </TableCell>
      <TableCell align='left'>{user?.phone}</TableCell>
      <TableCell align='left'>{getStatus(user)}</TableCell>
      <TableCell align='left'>
        {/* {user?.deletion_request_date || '-'} */}
        {/* {user?.deletion_request_date ? (
          <>
            {moment(user.deletion_request_date, 'DD/MM/YYYY').isValid()
              ? moment(user.deletion_request_date, 'DD/MM/YYYY HH:mm').format('hh.mm A, DD MMM YY')
              : ''}
            {moment(user.deletion_request_date).isValid()
              ? moment(user.deletion_request_date).format('hh.mm A, DD MMM YY')
              : ''}
          </>
        ) : (
          ''
        )} */}
        {user?.deletion_request_date ? (
          <>
            {moment(user.deletion_request_date, 'DD/MM/YYYY HH:mm').isValid() ? (
              moment(user.deletion_request_date, 'DD/MM/YYYY HH:mm').format('hh.mm A, DD MMM YY')
            ) : (
              <>
                {moment(user.deletion_request_date, 'YYYY-MM-DDTHH:mm').isValid()
                  ? moment(user.deletion_request_date).format('hh.mm A, DD MMM YY')
                  : '-'}
              </>
            )}
          </>
        ) : (
          ''
        )}
      </TableCell>
      <TableCell align='left' onClick={e => e.stopPropagation()}>
        <CmtDropdownMenu items={userActions} onItemClick={onUserMenuClick} TriggerComponent={<MoreHoriz />} />
      </TableCell>
    </TableRow>
  );
});

const UserTableHead = React.memo(() => {
  const tableHeader = ['Name', 'Phone', 'Status', 'Application Date', 'Actions'];

  return (
    <TableHead>
      <TableRow>
        {tableHeader?.map((d, i) => (
          <TableCell key={i} align='left' padding={'normal'}>
            {d}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
});
