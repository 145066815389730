import React, { useEffect, useState } from 'react';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import useStyles from '../index.style';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import CmtAvatar from '@coremat/CmtAvatar';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import GridContainer from '@jumbo/components/GridContainer';
import toast from 'react-hot-toast';
import { acceptFileType, fileSize } from 'common/functions';
import { postCategory, putCategory } from 'redux/actions/Categories';
import { SET_CATEGORIES } from '@jumbo/constants/ActionTypes';
import { commonFetchGameCategory } from 'redux/actions/commonDataFetch';

const imgTypes = ['image/png', 'image/jpg', 'image/jpeg'];

const AddEditCategory = ({ open, closeModal, fetchAfterActions }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { categories, selected_category } = useSelector(({ categoryReducer }) => categoryReducer);

  const [categoryName, setCategoryName] = useState('');
  const [categoryImage, setCategoryImage] = useState('');
  const [categoryImageURL, setCategoryImageURL] = useState('');

  useEffect(() => {
    if (selected_category) {
      setCategoryName(selected_category?.category_name);
      setCategoryImageURL(selected_category?.category_image_url);
    }
  }, [selected_category]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      if (!acceptFileType(acceptedFiles[0], imgTypes)) {
        toast.error('Category image must be png or jpg type.');
        return;
      }

      if (fileSize(acceptedFiles[0]) > 301) {
        toast.error('Category image size can not be grater than 300 kb.');
        return;
      }

      setCategoryImageURL(URL.createObjectURL(acceptedFiles[0]));
      setCategoryImage(acceptedFiles[0]);
    },
  });

  const handleSave = () => {
    if (!categoryName) {
      toast.error('Please enter category name.');
      return;
    }

    if (!categoryImageURL) {
      toast.error('Please enter category image.');
      return;
    }

    const categoryDetails = {
      game_category_name: categoryName,
      game_category_image: categoryImage,
    };

    if (selected_category) {
      dispatch(
        putCategory(categoryDetails, selected_category?._id, respData => {
          // console.log(respData);
          // console.log(categories);
          const newCategories = {
            ...categories,
            data: categories.data.map(cat => {
              if (cat?._id === respData?.data?._id) {
                return {
                  ...respData?.data,
                  gameList: respData.game,
                };
              }

              return { ...cat };
            }),
          };
          // console.log(newCategories);
          dispatch({ type: SET_CATEGORIES, payload: newCategories });
          closeModal();
          dispatch(commonFetchGameCategory());
        })
      );
    } else {
      dispatch(
        postCategory(categoryDetails, () => {
          fetchAfterActions();
          dispatch(commonFetchGameCategory());
        })
      );
    }
  };

  return (
    <Dialog open={open} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>
        {selected_category ? 'Edit Category' : 'Add New Category'}
      </DialogTitle>

      <DialogContent dividers>
        <Box display='flex' flexDirection={{ xs: 'column', md: 'row' }} alignItems='center' mb={{ xs: 6, md: 5 }}>
          <Box {...getRootProps()} mr={{ xs: 0, md: 5 }} mb={{ xs: 3, md: 0 }} className='pointer'>
            <input {...getInputProps()} accept='image/jpeg, image/png' />
            <CmtAvatar size={70} src={categoryImageURL} />
          </Box>
          <GridContainer>
            <Grid style={{ width: '100%' }}>
              <AppTextInput
                fullWidth
                variant='outlined'
                label='Category Name'
                value={categoryName}
                onChange={e => setCategoryName(e.target.value)}
                style={{ width: '97%' }}
              />
            </Grid>
          </GridContainer>
        </Box>
      </DialogContent>

      <DialogActions>
        <Box display='flex' justifyContent='flex-end' mb={4}>
          <Button onClick={closeModal}>Cancel</Button>
          <Box ml={2}>
            <Button variant='contained' color='primary' onClick={handleSave}>
              Save
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AddEditCategory;
