import React from "react";
import {Box} from "@material-ui/core";
import {NavLink} from "react-router-dom";
import CmtImage from "../../../../@coremat/CmtImage";

const FooterLogo = ({color, ...props}) => {
  return (
    <Box className="pointer" {...props}>
      <NavLink to="/">
        <CmtImage src='/images/addition/only-logo.png' alt="logo" width="40" />
      </NavLink>
    </Box>
  );
};

export default FooterLogo;
