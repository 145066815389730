import React from 'react';
import { DateTimePicker } from '@mantine/dates';

const DateTimePickerInput = ({
  value,
  onChange,
  placeholder = 'Select Date and time',
  format = 'DD.MM.YYYY - hh:mm A',
  clearable = true,
  firstDayOfWeek = 1,
  weekendDays = [],
  label,
  position,
}) => {
  return (
    <DateTimePicker
      value={value}
      onChange={onChange}
      valueFormat={format}
      placeholder={placeholder}
      clearable={clearable}
      variant='unstyled'
      className='mDateTimePickerInput'
      firstDayOfWeek={firstDayOfWeek}
      weekendDays={weekendDays}
      label={label}
      popoverProps={{ position: position }}
    />
  );
};

export default DateTimePickerInput;
