import React, { useState } from 'react';
import useStyles from '../index.style';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core';
import CmtSearch from '@coremat/CmtSearch';
import { Pagination } from '@material-ui/lab';
import RefUserModal from '../components/RefUserModal.Component';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import Axios from 'axios';
import NoRecordFound from 'components/NoRecordFound.Component';

const tableHeader = ['Name', 'Phone', 'Points Earned', 'Points Claimed', 'User Registered', 'Action'];
const pageSize = 20;

const RefUsers = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [modalData, setModalData] = useState(null);

  const [search, setSearch] = useState('');

  const [data, setData] = useState(null);
  const [paginationDisabled, setPaginationDisabled] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = (search, page = 1) => {
    dispatch(fetchStart());
    setPaginationDisabled(true);
    const searchTxt = search || '';

    Axios.get(`${process.env.REACT_APP_BASE_URL}/admin/referral/user/list`, {
      params: {
        page: page,
        size: pageSize,
        search: searchTxt,
      },
    })
      .then(resp => {
        // console.log(resp.data);
        setTotalPages(Math.ceil(resp?.data?.total_count / pageSize));
        setData(resp.data);
        dispatch(fetchSuccess());
        setPaginationDisabled(false);
      })
      .catch(error => {
        // console.log(error.response);
        setPaginationDisabled(false);
        dispatch(fetchError('Sorry, something went wrong. Please try again later.'));
      });
  };

  const fetchUserDetails = (userData, callback) => {
    dispatch(fetchStart());

    Axios.get(`${process.env.REACT_APP_BASE_URL}/admin/referral/user/${userData?.user_info?.id}`)
      .then(resp => {
        const modalData = {
          apiData: resp?.data?.data,
          userData: userData.user_info,
        };
        // console.log(modalData);
        dispatch(fetchSuccess());
        setModalData(modalData);
        if (callback && typeof callback === 'function') callback();
      })
      .catch(error => {
        // console.log(error.response);
        // setPaginationDisabled(false);
        dispatch(fetchError('Sorry, something went wrong. Please try again later.'));
      });
  };

  const handelModal = data => {
    // console.log(data?.user_info?.id);
    fetchUserDetails(data, setShowModal(true));
  };

  function handleCloseModal() {
    setShowModal(false);
    setModalData(null);
  }

  const handlePagination = (e, page) => {
    setCurrentPage(page);
    fetchData(search, page);
    // console.log(dateRange);
  };

  const handleSearch = e => {
    e.preventDefault();

    // console.log(search);

    setCurrentPage(1);
    fetchData(search, 1);
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar className={classes.root} display='flex' style={{ justifyContent: 'space-between' }}>
          <Typography className={classes.title} variant='h4' id='tableTitle' component='div'>
            Referrer Users: {data?.total_count || 0}
          </Typography>

          <form onSubmit={handleSearch}>
            <Box display='flex'>
              <CmtSearch onChange={e => setSearch(e.target.value)} value={search} border={true} onlyIcon={false} />
              <Button style={{ marginLeft: 10 }} type='submit' variant='contained' color='primary'>
                Search
              </Button>
            </Box>
          </form>
        </Toolbar>

        <TableContainer className={classes.container}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {tableHeader?.map((d, i) => (
                  <TableCell key={i} align='left' padding={'normal'}>
                    {d}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            {data && (
              <TableBody>
                {data?.data?.length > 0 ? (
                  data?.data.map(user => (
                    <TableRow key={user?._id}>
                      <TableCell align='left' style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
                        <img
                          style={{ borderRadius: 50 }}
                          width={40}
                          height={40}
                          src={user?.user_info?.profile_picture}
                          alt=''
                        />
                        {user?.user_info?.user_name}
                      </TableCell>
                      <TableCell align='left'>{user?.user_info?.phone}</TableCell>
                      <TableCell align='left'>{user?.total_rp}</TableCell>
                      <TableCell align='left'>{user?.total_rp - user?.current_rp || 0}</TableCell>
                      <TableCell align='left'>{user?.reg_user_count}</TableCell>
                      <TableCell align='left' className='pointer' onClick={() => handelModal(user)}>
                        <div className='text-primary'>View</div>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow style={{ height: 200 }}>
                    <TableCell colSpan={11} rowSpan={7}>
                      <NoRecordFound>There are no records found.</NoRecordFound>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        {totalPages > 1 && (
          <Box px={4} py={5} display='flex' justifyContent='flex-end'>
            <Pagination
              disabled={paginationDisabled}
              page={currentPage}
              count={totalPages}
              color='primary'
              onChange={handlePagination}
            />
          </Box>
        )}
      </Paper>

      {showModal && <RefUserModal open={showModal} handleCloseModal={handleCloseModal} modalData={modalData} />}
    </div>
  );
};

export default RefUsers;
