import { SET_CATEGORIES, SET_CATEGORY_P_D, SET_CATEGORY_TOTAL_PAGES } from '@jumbo/constants/ActionTypes';
import { fetchError, fetchStart, fetchSuccess } from './Common';
import { axiosInstance } from 'utils/axios';
import toast from 'react-hot-toast';

export const getAllCategories = (pageSize, page = 1, search = '', callbackFun) => {
  return async dispatch => {
    dispatch({ type: SET_CATEGORY_P_D, payload: true });

    dispatch(fetchStart());

    await axiosInstance
      .get(`/admin/game/category/list`, {
        params: { page: page, size: pageSize, search: search },
      })
      .then(resp => {
        // console.log(resp?.data);
        dispatch(fetchSuccess());
        dispatch({ type: SET_CATEGORIES, payload: resp?.data });
        dispatch({ type: SET_CATEGORY_P_D, payload: false });
        dispatch({ type: SET_CATEGORY_TOTAL_PAGES, payload: Math.ceil(resp?.data?.total_count / pageSize) });
        if (callbackFun) callbackFun(resp.data);
      })
      .catch(error => {
        dispatch(fetchError());
        toast.error('There was something issue in responding server');
      });
  };
};

export const postCategory = (data, callbackFun) => {
  return async dispatch => {
    let formData = new FormData();

    // formData.append('game_category_name', data.game_category_name);
    formData.append('game_category_image', data.game_category_image, data.game_category_image.name);
    formData.append('data', JSON.stringify(data));

    dispatch(fetchStart());

    axiosInstance
      .post('/admin/game/category/add', formData)
      .then(resp => {
        // console.log(resp.data);
        dispatch(fetchSuccess());
        toast.success('Category added successfully.');
        if (callbackFun) callbackFun(resp.data);
      })
      .catch(error => {
        // console.log(error.response);
        toast.error('Sorry, something went wrong. Please try again later.');
        dispatch(fetchError());
      });
  };
};

export const putCategory = (data, id, callbackFun) => {
  return async dispatch => {
    let formData = new FormData();

    if (data.game_category_image) {
      formData.append('game_category_image', data.game_category_image, data.game_category_image.name);
    }

    formData.append('data', JSON.stringify(data));

    dispatch(fetchStart());

    axiosInstance
      .put(`/admin/game/category/update/${id}`, formData)
      .then(resp => {
        // console.log(resp?.data);
        dispatch(fetchSuccess());
        toast.success('Category updated successfully.');
        if (callbackFun) callbackFun(resp?.data);
      })
      .catch(error => {
        // console.log(error.response);
        toast.error('Sorry, something went wrong. Please try again later.');
        dispatch(fetchError());
      });
  };
};

export const deleteCategory = (id, callbackFun) => {
  return async dispatch => {
    dispatch(fetchStart());

    axiosInstance
      .delete(`/admin/game/category/delete/${id}`)
      .then(resp => {
        // console.log(resp.data);
        dispatch(fetchSuccess());
        toast.success('Category deleted successfully.');
        if (callbackFun) callbackFun(resp.data);
      })
      .catch(error => {
        // console.log(error.response);
        toast.error('Sorry, something went wrong. Please try again later.');
        dispatch(fetchError());
      });
  };
};
