import {
  Box,
  Button,
  Paper,
  Popper,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Toolbar,
  Typography,
  Table,
} from '@material-ui/core';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { DateRangeOutlined, CloseOutlined } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { fetchError } from 'redux/actions';
import NoRecordFound from '../NoRecordFound';
import HistoryTableRow from './HistoryTableRow';
import DatePicker from './DatePicker';
import { fetchHistoryNotifications } from 'redux/actions/Notifications';
import { Pagination } from '@material-ui/lab';
import {
  HISTORY_NOTIFICATIONS_CURRENT_PAGE,
  SET_HISTORY_NOTIFICATIONS_DATE,
  SET_HISTORY_NOTIFICATIONS_DATE_APPLIED,
} from '@jumbo/constants/ActionTypes';

const History = ({ classes }) => {
  const dispatch = useDispatch();
  const { historyNotifications } = useSelector(({ notificationReducer }) => notificationReducer);

  // console.log(historyNotifications);

  const [isDateRangeApplied, setIsDateRangeApplied] = useState(false);
  const [showPopover, setShowPopover] = useState(null);

  useEffect(() => {
    if (!historyNotifications?.isDataFetched) {
      dispatch(fetchHistoryNotifications(historyNotifications?.pageSize));
    }
  }, []);

  const open = Boolean(showPopover);
  const id = open ? 'simple-popper' : undefined;

  const handleDateClear = () => {
    dispatch(fetchHistoryNotifications(historyNotifications?.pageSize));
    // setIsDateRangeApplied(false);
    dispatch({ type: SET_HISTORY_NOTIFICATIONS_DATE_APPLIED, payload: false });
    dispatch({ type: SET_HISTORY_NOTIFICATIONS_DATE, payload: [null, null] });
  };

  const handleApplyDateRange = () => {
    if (!historyNotifications?.dateRange[0]) {
      dispatch(fetchError('Please select start date.'));
      return;
    }

    if (!historyNotifications?.dateRange[1]) {
      dispatch(fetchError('Please select end date.'));
      return;
    }

    const startDateFormat = moment(historyNotifications?.dateRange[0]).format('YYYY-MM-DDT00:00:00+06:00');
    const endDateFormat = moment(historyNotifications?.dateRange[1]).format('YYYY-MM-DDT23:59:59+06:00');

    dispatch(
      fetchHistoryNotifications(
        historyNotifications?.pageSize,
        historyNotifications?.currentPage,
        startDateFormat,
        endDateFormat,
        dispatch({ type: SET_HISTORY_NOTIFICATIONS_DATE_APPLIED, payload: true })
      )
    );
    setShowPopover(null);
  };

  const handlePagination = (e, page) => {
    // setCurrentPage(page);
    // fetchData(search, page);
    // console.log(page);
    dispatch({ type: HISTORY_NOTIFICATIONS_CURRENT_PAGE, payload: page });
    dispatch(
      fetchHistoryNotifications(
        historyNotifications?.pageSize,
        page,
        historyNotifications?.dateRange[0],
        historyNotifications?.dateRange[1]
      )
    );
  };

  const setDateRange = value => {
    dispatch({ type: SET_HISTORY_NOTIFICATIONS_DATE, payload: value });
  };

  return (
    <Paper className={classes.paper}>
      <Toolbar className={classes.root} style={{ justifyContent: 'space-between' }}>
        <Box>
          <Typography className={classes.title} variant='h4' id='tableTitle' component='div'>
            Total Notification: {historyNotifications?.data?.total_count || 0}
          </Typography>

          {historyNotifications?.dateRangeApplied && (
            <Typography className='' style={{ marginTop: 5 }} variant='h5' id='tableTitle' component='div'>
              Showing results for:{' '}
              <b>
                {historyNotifications && (
                  <>
                    {historyNotifications?.dateRange[0] &&
                      moment(historyNotifications?.dateRange[0]).format('DD MMM, YY')}{' '}
                    -{' '}
                    {historyNotifications?.dateRange[1] &&
                      moment(historyNotifications?.dateRange[1]).format('DD MMM, YY')}{' '}
                  </>
                )}
              </b>{' '}
              <span className='clr-btn' onClick={handleDateClear}>
                Clear
              </span>
            </Typography>
          )}
        </Box>

        <div style={{ display: 'flex', alignItems: 'center' }}>
          <button
            aria-describedby={id}
            className='date-range-picker'
            onClick={event => setShowPopover(showPopover ? null : event.currentTarget)}
          >
            <DateRangeOutlined style={{ fill: '#fff', width: 20 }} />
          </button>
        </div>
      </Toolbar>

      <Popper placement='left-start' id={id} open={open} anchorEl={showPopover}>
        <div className='anayltic-popover'>
          <div className='flex justify-end pointer'>
            <CloseOutlined style={{ fill: '#000', width: 30 }} onClick={() => setShowPopover(null)} />
          </div>
          <div className='flex justify-between head'>
            <span>
              {historyNotifications?.dateRange && historyNotifications?.dateRange[0] ? (
                moment(historyNotifications?.dateRange[0]).format('DD MMM, YY')
              ) : (
                <div style={{ color: '#ced4da' }}>start date</div>
              )}
            </span>
            <span>
              {historyNotifications?.dateRange && historyNotifications?.dateRange[1] ? (
                moment(historyNotifications?.dateRange[1]).format('DD MMM, YY')
              ) : (
                <div style={{ color: '#ced4da' }}>end date</div>
              )}
            </span>
          </div>
          <DatePicker
            allowSingleDateInRange
            type='range'
            value={historyNotifications?.dateRange}
            onChange={setDateRange}
            maxDate={new Date()}
          />
          <Button onClick={handleApplyDateRange} variant='contained' color='primary' className='save'>
            Apply
          </Button>
        </div>
      </Popper>

      <TableContainer className={classes.container}>
        <Table className='' aria-labelledby='tableTitle' aria-label='sticky enhanced table'>
          <TableBody>
            {historyNotifications?.data?.data?.length ? (
              historyNotifications?.data?.data.map((row, index) => <HistoryTableRow key={index} row={row} />)
            ) : (
              <TableRow style={{ height: 53 * 6 }}>
                <TableCell colSpan={7} rowSpan={10}>
                  <NoRecordFound>There are no records found with your filter.</NoRecordFound>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {historyNotifications?.totalPages > 1 ? (
        <Box px={4} py={5} display='flex' justifyContent='flex-end'>
          <Pagination
            disabled={historyNotifications?.paginationDisabled}
            page={historyNotifications?.currentPage}
            count={historyNotifications?.totalPages}
            color='primary'
            onChange={handlePagination}
          />
        </Box>
      ) : null}
    </Paper>
  );
};

export default History;
