import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Login from './Auth/Login';
import UpdateMaintenance from './UpdateMaintenance/UpdateMaintenance.Page';
import Notification from './Notification/Notification.Page';
import NotFound from './NotFound/NotFound.Page';
import Finance from './Finance/Finance.Page';
import { getLocalUserRole } from 'common/userRoleAccess';
import RefPhysicalGoods from './Referrals/pages/RefPhysicalGoods.Page';
import RefUsers from './Referrals/pages/RefUsers.Page';
import RefOfferPoint from './Referrals/pages/RefOfferPoint.Page';
import RfeAnalytic from './Referrals/pages/RfeAnalytic.Page';

import UserList from './UserModule/UserList.Page';
import BannedUser from './UserModule/BannedUser.Page';
import DeleteRequest from './UserModule/DeleteRequest.Page';
import AccountDeleted from './UserModule/AccountDeleted.Page';
import EventList from './EventModule/EventList.page';
import PastEvents from './EventModule/PastEvents.page';
import Games from './GameModule/Games.Page';
import Category from './GameModule/Category.Page';
import GameTutorial from './GameModule/GameTutorial.Page';
import AdminUsers from './AdminUser/AdminUsers.Page';
import Analytic from './AnalyticModule/Analytic.Page';

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  //console.log(authUser);
  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const userAccessList = {
  super_admin: [
    { path: '/users', component: UserList },
    { path: '/banned-users', component: BannedUser },
    { path: '/delete-request', component: DeleteRequest },
    { path: '/account-deleted', component: AccountDeleted },
    { path: '/prize-event-lists', component: EventList },
    { path: '/past-events', component: PastEvents },
    { path: '/admin-users', component: AdminUsers },
    { path: '/games', component: Games },
    { path: '/game-category', component: Category },
    { path: '/game-tutorials/:id', component: GameTutorial },

    { path: '/finance', component: Finance },
    { path: '/referral/analytics', component: RfeAnalytic },
    { path: '/referral/discounts', component: RefOfferPoint },
    { path: '/referral/physical-goods', component: RefPhysicalGoods },
    { path: '/referral/all-user', component: RefUsers },
    { path: '/analytic-visualization', component: Analytic },
    { path: '/notification', component: Notification },
    { path: '/update-maintenance', component: UpdateMaintenance },
  ],

  admin: [
    { path: '/users', component: UserList },
    { path: '/banned-users', component: BannedUser },
    { path: '/delete-request', component: DeleteRequest },
    { path: '/account-deleted', component: AccountDeleted },
    { path: '/prize-event-lists', component: EventList },
    { path: '/past-events', component: PastEvents },
    { path: '/games', component: Games },
    { path: '/game-category', component: Category },
    { path: '/game-tutorials/:id', component: GameTutorial },

    { path: '/finance', component: Finance },
    { path: '/referral/analytics', component: RfeAnalytic },
    { path: '/referral/discounts', component: RefOfferPoint },
    { path: '/referral/physical-goods', component: RefPhysicalGoods },
    { path: '/referral/all-user', component: RefUsers },
    { path: '/analytic-visualization', component: Analytic },
    { path: '/notification', component: Notification },
    { path: '/update-maintenance', component: UpdateMaintenance },
  ],

  finance_admin: [{ path: '/finance', component: Finance }],
};

const Routes = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const location = useLocation();

  const role = getLocalUserRole();
  const dynamicRoutes = userAccessList[role?.type];
  // const dynamicRoutes = userAccessList['super_admin'];

  if (location.pathname === '' || location.pathname === '/') {
    if (role && role?.type === 'finance_admin') return <Redirect to={'/finance'} />;
    return <Redirect to={'/users'} />;
  } else if (authUser && location.pathname === '/signin') {
    if (role && role?.type === 'finance_admin') return <Redirect to={'/finance'} />;
    return <Redirect to={'/users'} />;
  }

  return (
    <React.Fragment>
      <Switch>
        <Route path='/signin' component={Login} />
        {dynamicRoutes?.map((route, i) => (
          <RestrictedRoute key={i} path={route.path} component={route.component} />
        ))}
        <RestrictedRoute path='*' component={NotFound} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
