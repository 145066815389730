import React from 'react';
import useStyles from '../index.style';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import Axios from 'axios';
import moment from 'moment';
import { useEffect } from 'react';

const PGModal = ({ open, handleCloseModal, data, getCategoryName, fetchInitial }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [disbursementStatus, setDisbursementStatus] = useState('');

  useEffect(() => {
    if (data) setDisbursementStatus(data?.delivery_status);
  }, [data]);

  const handleSave = () => {
    if (!disbursementStatus) {
      dispatch(fetchError('Please select status and update.'));
      return;
    }

    // console.log(disbursementStatus);

    const postData = {
      delivery_status: disbursementStatus,
    };

    dispatch(fetchStart());
    Axios.patch(`${process.env.REACT_APP_BASE_URL}/admin/physical-good/delivery-status/${data?._id}`, postData)
      .then(resp => {
        // console.log(resp.data);
        dispatch(fetchSuccess('Status updated successfully.'));
        handleCloseModal();
        fetchInitial();
      })
      .catch(error => {
        // console.log(error.response);
        dispatch(fetchError('Sorry, something went wrong. Please try again later.'));
      });
  };

  return (
    <Dialog open={open} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>View Details</DialogTitle>
      <DialogContent dividers>
        <Box>
          <p style={{ marginBottom: 10 }}>
            <span className='fw-600'>Claimed Person : </span>
            {data?.user_info?.user_name}
          </p>
          <p style={{ marginBottom: 10 }}>
            <span className='fw-600'>User Login No. : </span>
            {data?.user_info?.phone}
          </p>
          <p style={{ marginBottom: 10 }}>
            <span className='fw-600'>Claimed Offer : </span>
            {data?.referral_gift_info?.title}
          </p>
          <p style={{ marginBottom: 10 }}>
            <span className='fw-600'>Redeem Point : </span>
            {data?.referral_gift_info?.required_point}
          </p>
          <p style={{ marginBottom: 10 }}>
            <span className='fw-600'>Offer Claim Timeline : </span>
            {moment(data?.referral_gift_info?.claim_start_date).format('hh.mm A, DD MMM YY')} -{' '}
            {moment(data?.referral_gift_info?.claim_end_date).format('hh.mm A, DD MMM YY')}
          </p>
          <p style={{ marginBottom: 10 }} className='text-capitalize'>
            <span className='fw-600'>Offer Category : </span>
            {data?.referral_gift_info?.type && getCategoryName(data?.referral_gift_info?.type)}
          </p>
          {data?.referral_gift_info?.type === 'clothing' && (
            <p style={{ marginBottom: 10 }}>
              <span className='fw-600'>Size : </span>
              {data?.size}
            </p>
          )}
          <p style={{ marginBottom: 10 }}>
            <span className='fw-600'>Claimed Date : </span>
            {data?.entry_time && moment(data?.entry_time).format('hh.mm A, DD MMM YY')}
          </p>
          <br />
          <br />
          <p style={{ marginBottom: 15 }}>
            <span className='fw-600'>Contact Name : </span>
            {data?.full_name}
          </p>
          <p style={{ marginBottom: 15 }}>
            <span className='fw-600'>Contact No : </span>
            {data?.contact_phone}
          </p>
          <p style={{ marginBottom: 10 }}>
            <span className='fw-600'>User Address. : </span>
            {data?.address}
          </p>
        </Box>
        <Box mb={{ xs: 6, md: 5 }}>
          <FormControl size='small' fullWidth variant='outlined'>
            <InputLabel id='p_e_123'>Disbursement Status</InputLabel>
            <Select
              labelId='p_e_123'
              id='demo-simple-select'
              label='Disbursement Status'
              value={disbursementStatus}
              onChange={e => setDisbursementStatus(e.target.value)}
            >
              <MenuItem value='PENDING'>Pending</MenuItem>
              <MenuItem value='RELEASED'>Released</MenuItem>
              <MenuItem value='DELIVERED'>Delivered</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box display='flex' justifyContent='flex-end' mb={1}>
          <Button onClick={handleCloseModal}>Close</Button>
          <Box ml={2}>
            <Button variant='contained' color='primary' onClick={handleSave}>
              Update
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PGModal;
