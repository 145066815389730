import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import useStyles from '../index.style';
import { useDropzone } from 'react-dropzone';
import CmtAvatar from '@coremat/CmtAvatar';
import GridContainer from '@jumbo/components/GridContainer';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import { useDispatch, useSelector } from 'react-redux';
import { Close } from '@material-ui/icons';
import toast from 'react-hot-toast';
import { acceptFileType, fileSize } from 'common/functions';
import { useEffect } from 'react';
import { postNewGame, putGame } from 'redux/actions/GamesV2';
import { SET_ALL_GAMES } from '@jumbo/constants/ActionTypes';
import { commonFetchGames } from 'redux/actions/commonDataFetch';

const imgTypes = ['image/png', 'image/jpg', 'image/jpeg'];

const AddEditGame = ({ open, closeModal, selectedGame, fetchDataAfterActions }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { gameCategory } = useSelector(({ CommonSharedData }) => CommonSharedData);
  const { games } = useSelector(({ gameReducer }) => gameReducer);

  const [gameIcon, setGameIcon] = useState('');
  const [gameIconFile, setGameIconFile] = useState('');
  const [gameBanner, setGameBanner] = useState('');
  const [gameBannerFile, setGameBannerFile] = useState('');
  const [gameTitle, setGameTitle] = useState('');
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const [gameFile, setGameFile] = useState('');
  const [gameMandatory, setGameMandatory] = useState(false);
  const [showPrevGameFile, setShowPrevGameFile] = useState(true);
  const [gameDescription, setGameDescription] = useState('');
  const [gameOrientation, setGameOrientation] = useState('');
  const [gameSearchKeys, setGameSearchKeys] = useState('');
  const [gameSlot, setGameSlot] = useState('');
  const [gameActiveStatus, setGameActiveStatus] = useState(1);
  const [gameIsFeatured, setGameIsFeatured] = useState(true);
  const [gameUniqueTag, setGameUniqueTag] = useState('');

  useEffect(() => {
    if (selectedGame) {
      // console.log(selectedGame);
      setGameIcon(selectedGame?.game_icon_url);
      setGameBanner(selectedGame?.game_featured_banner_url);
      setGameTitle(selectedGame?.game_title);
      setSelectedCategoryId(selectedGame?.game_category?._id);
      setGameDescription(selectedGame?.game_details);
      setGameOrientation(selectedGame?.game_orientation);
      setGameSearchKeys(selectedGame?.game_search_keys);
      setGameSlot(selectedGame?.game_slot);
      setGameActiveStatus(selectedGame?.game_active_status);
      setGameIsFeatured(selectedGame?.game_featured);

      if (selectedGame?.game_tag) {
        setGameUniqueTag(selectedGame?.game_tag);
      }
    }
  }, []);

  const { getRootProps: gameIconGetRootProps, getInputProps: gameIconGetInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      //console.log(theUrl);
    },
  });

  const handleIconAdd = async value => {
    // console.log(value);
    if (!acceptFileType(value, imgTypes)) {
      toast.error('Game icon image must be png or jpg type.');
      return;
    }

    if (fileSize(value) > 201) {
      toast.error('Game icon image size can not be grater than 200 kb.');
      return;
    }

    setGameIcon(URL.createObjectURL(value));
    setGameIconFile(value);
  };

  const { getRootProps: gameFeaturedBannerGetRootProps, getInputProps: gameFeaturedBannerGetInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      // setGameFeaturedBannerUrl(URL.createObjectURL(acceptedFiles[0]));
    },
  });

  const handleBannerAdd = async value => {
    if (!acceptFileType(value, imgTypes)) {
      toast.error('Game banner image must be png or jpg type.');
      return;
    }

    if (fileSize(value) > 301) {
      toast.error('Game banner image size can not be grater than 300 kb.');
      return;
    }

    setGameBanner(URL.createObjectURL(value));
    setGameBannerFile(value);
  };

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: '.zip',
    directory: '',
    webkitdirectory: '',
    type: 'file',
    onDrop: acceptedFiles => {
      //console.log(acceptedFiles)
      setGameFile(acceptedFiles[0]);
      setGameMandatory(false);
    },
  });

  const files = acceptedFiles.map(file => (
    <ListItem key={file.path}>
      {file.path} - {file.size} bytes
    </ListItem>
  ));

  const getCategoryDetails = (id, type) => {
    const cDetails = gameCategory.find(cat => cat._id === id);
    // console.log(cDetails);

    if (type === 'name') {
      return cDetails?.category_name;
    } else if (type === 'img') {
      return cDetails?.category_image_url;
    } else {
      return '';
    }
  };

  const handleSave = () => {
    if (!gameIcon) {
      toast.error('Game icon image is required.');
      return;
    }

    if (gameIconFile && !acceptFileType(gameIconFile, imgTypes)) {
      toast.error('Game icon image must be png or jpg type.');
      return;
    }

    if (!gameBanner) {
      toast.error('Game banner image is required.');
      return;
    }

    if (!gameTitle) {
      toast.error('Game title is required.');
      return;
    }

    if (!selectedCategoryId) {
      toast.error('Game category is required.');
      return;
    }

    if (!selectedGame && !gameFile) {
      toast.error('Game File Must be Uploaded.');
      return;
    }

    if (selectedGame && gameMandatory) {
      toast.error('Game File Must be Uploaded.');
      return;
    }

    if (!gameUniqueTag) {
      toast.error('Game tag is required.');
      return;
    }

    if (!gameDescription) {
      toast.error('Game description is required.');
      return;
    }

    if (!gameOrientation) {
      toast.error('Game orientation is required.');
      return;
    }

    if (!gameSearchKeys) {
      toast.error('Game search keys is required.');
      return;
    }

    if (!gameSlot) {
      toast.error('Game slot is required.');
      return;
    }

    if (gameSlot < 1 || gameSlot % 1 !== 0) {
      toast.error('Game slot can not be decimal and must be greater than zero.');
      return;
    }

    const apiData = {
      game_title: gameTitle,
      game_category_id: selectedCategoryId,
      game_category: getCategoryDetails(selectedCategoryId, 'name'),
      game_category_image: getCategoryDetails(selectedCategoryId, 'img'),
      game_details: gameDescription,
      game_orientation: gameOrientation,
      game_search_keys: gameSearchKeys,
      game_slot: gameSlot,
      game_active_status: gameActiveStatus,
      game_featured: gameIsFeatured,

      game_icon: gameIconFile,
      game_featured_banner: gameBannerFile,
      game_file: gameFile,
      game_tag: gameUniqueTag,
    };

    if (selectedGame) {
      dispatch(
        putGame(apiData, selectedGame?._id, respData => {
          closeModal();
          // console.log(respData);
          // console.log(games?.data);

          const newGameData = {
            ...games?.data,
            data: games?.data?.data?.map(game => {
              if (game._id === respData?.data?._id) {
                return {
                  // ...game,
                  ...respData?.data,
                  game_category: respData?.update_category,
                };
              }
              return { ...game };
            }),
          };

          // console.log(newGameData);
          dispatch({ type: SET_ALL_GAMES, payload: newGameData });
          dispatch(commonFetchGames());
        })
      );
    } else {
      dispatch(
        postNewGame(apiData, () => {
          closeModal();
          fetchDataAfterActions();
          dispatch(commonFetchGames());
        })
      );
    }
  };

  return (
    <Dialog open={open} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>{selectedGame ? 'Edit Game' : 'Add New Game'}</DialogTitle>

      <DialogContent dividers>
        <Box display='flex' flexDirection={{ xs: 'column', md: 'row' }} alignItems='center' mb={{ xs: 6, md: 5 }}>
          <label style={{ textAlign: 'center' }}>
            <Box {...gameIconGetRootProps()} mr={{ xs: 0, md: 5 }} mb={{ xs: 3, md: 0 }} className='pointer'>
              <input
                accept='image/jpeg, image/png'
                style={{ display: 'none' }}
                onChange={e => handleIconAdd(e.target.files[0])}
                type='file'
              />
              <CmtAvatar size={70} src={gameIcon} />
              Game Icon
            </Box>
          </label>
          <label style={{ textAlign: 'center' }}>
            <Box {...gameFeaturedBannerGetRootProps()} mr={{ xs: 0, md: 5 }} mb={{ xs: 3, md: 0 }} className='pointer'>
              <input
                accept='image/jpeg, image/png'
                style={{ display: 'none' }}
                onChange={e => handleBannerAdd(e.target.files[0])}
                type='file'
              />
              <CmtAvatar size={70} src={gameBanner} />
              Game Banner
            </Box>
          </label>
          <GridContainer>
            <Grid item xs={12} sm={12}>
              <AppTextInput
                fullWidth
                variant='outlined'
                label='Game Name'
                value={gameTitle}
                onChange={e => setGameTitle(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl size='small' fullWidth variant='outlined'>
                <InputLabel id='demo-simple-select-label'>Game Category</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={selectedCategoryId}
                  label='Game Category'
                  onChange={e => setSelectedCategoryId(e.target.value)}
                >
                  {gameCategory?.map(category => (
                    <MenuItem key={category._id} value={category._id}>
                      {category.category_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </GridContainer>
        </Box>

        <Box>
          <Box {...getRootProps({ className: 'dropzone' })}>
            {selectedGame ? (
              <input {...getInputProps()} disabled={showPrevGameFile ? 'disabled' : ''} />
            ) : (
              <input {...getInputProps()} />
            )}

            <Typography>Drag 'n' drop some files here, or click to select files</Typography>
          </Box>

          {selectedGame && showPrevGameFile && (
            <Box display='flex' style={{ marginTop: 10 }}>
              <span>{selectedGame?.game_folder_name}.zip</span>
              <Close
                onClick={() => {
                  if (window.confirm('Are you sure to delete this game file?')) {
                    setShowPrevGameFile(false);
                    setGameMandatory(true);
                  }
                }}
                style={{ fontSize: 22 }}
                className='text-danger pointer'
              />
            </Box>
          )}
          <aside>
            <Typography component='h4' variant='inherit'>
              Files
            </Typography>
            <List>{files}</List>
          </aside>
        </Box>

        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            fullWidth
            variant='outlined'
            label='Game Tag'
            value={gameUniqueTag}
            onChange={e => setGameUniqueTag(e.target.value)}
          />
        </Box>

        <Box mb={{ xs: 6, md: 5 }}>
          <TextField
            fullWidth
            multiline
            minRows={4}
            variant='outlined'
            label='Game Description'
            value={gameDescription}
            onChange={e => setGameDescription(e.target.value)}
          />
        </Box>

        <FormControl size='small' fullWidth variant='outlined' style={{ marginBottom: 20 }}>
          <InputLabel id='demo-simple-select-label'>Game Orientation</InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={gameOrientation}
            label='Game Orientation'
            onChange={e => setGameOrientation(e.target.value)}
          >
            <MenuItem value='portrait'>Portrait</MenuItem>
            <MenuItem value='landscape'>Landscape</MenuItem>
          </Select>
        </FormControl>

        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            fullWidth
            variant='outlined'
            label='Game Search Keys'
            value={gameSearchKeys}
            onChange={e => setGameSearchKeys(e.target.value)}
          />
          <small>Comma seperated value (e.g. Abc, Def, Xyz)</small>
        </Box>

        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            fullWidth
            variant='outlined'
            label='Game Slot'
            value={gameSlot}
            onChange={e => setGameSlot(e.target.value)}
            type={'number'}
            inputProps={{ min: 1 }}
          />
        </Box>
        <FormControl size='small' fullWidth variant='outlined' style={{ marginBottom: 20 }}>
          <InputLabel id='demo-simple-select-label'>Game Status</InputLabel>
          <Select
            labelId='demo-simple-select-label'
            id='demo-simple-select'
            value={gameActiveStatus}
            label='Game Status'
            onChange={e => setGameActiveStatus(e.target.value)}
          >
            <MenuItem value='0'>Hidden</MenuItem>
            <MenuItem value='1'>Test</MenuItem>
            <MenuItem value='2'>Live</MenuItem>
          </Select>
        </FormControl>
        <Box mb={{ xs: 6, md: 5 }}>
          <Typography className={classes.titleRoot}>
            Feature Game:
            <Switch
              checked={gameIsFeatured}
              onChange={() => setGameIsFeatured(!gameIsFeatured)}
              name='gameFeature'
              inputProps={{ 'aria-label': 'secondary checkbox' }}
            />
          </Typography>
        </Box>
      </DialogContent>

      <DialogActions>
        <Box display='flex' justifyContent='flex-end' mb={4}>
          <Button onClick={closeModal}>Cancel</Button>
          <Box ml={2}>
            <Button variant='contained' color='primary' onClick={handleSave}>
              Save
            </Button>
          </Box>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default AddEditGame;
