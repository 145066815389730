import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from '@material-ui/core';
import useStyles from '../index.style';
import CmtAvatar from '@coremat/CmtAvatar';
import GridContainer from '@jumbo/components/GridContainer';
import moment from 'moment';
import AppTextInput from '@jumbo/components/Common/formElements/AppTextInput';
import { useDropzone } from 'react-dropzone';
import { acceptFileType, checkImgRatio, generateRandomID, randomString } from 'common/functions';
import { useDispatch } from 'react-redux';
import { fetchError, fetchStart, fetchSuccess } from 'redux/actions';
import CmtList from '@coremat/CmtList';
import DollerImg from '../../../assets/img/doller.jpg';
import CancelIcon from '@material-ui/icons/Cancel';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { toast } from 'react-hot-toast';
import { axiosInstance } from 'utils/axios';
import Axios from 'axios';
import { commonFetchAllEvents } from 'redux/actions/commonDataFetch';

const AddEditModal = ({ open, closeModal, selectedEvent, gameList, fetchEventsAfterBan, setData }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [prize_event_id, setPrizeEventId] = useState('');
  const [prize_event_title, setPrizeEventTitle] = useState('');
  const [prize_event_start_time, setPrizeEventStartTime] = useState('');
  const [prize_event_banner_image, setPrizeEventBannerImage] = useState('');
  const [prize_event_banner_image_file, setPrizeEventBannerImageFile] = useState('');
  const [prize_event_slot, setPrizeEventSlot] = useState('');
  const [prize_event_entry_cost, setPrizeEventEntryCost] = useState('');
  const [prize_event_end_time, setPrizeEventEndTime] = useState('');
  const [prize_event_description, setPrizeEventDescription] = useState('');
  const [prize_event_search_keys, setPrizeEventSearchKeys] = useState('');
  const [gameTitles, setGameTitles] = useState('');
  const [prizes, setPrizes] = useState([{ prize_name: '', prize_image_url: '' }]);

  const [rewardList, setRewardList] = useState([
    { prize_name: '', prize_image_file: '', prize_image_url: '', img_r_type: 'physical_good' },
  ]);

  const [discount, setDiscount] = useState('');
  const [discountStart, setDiscountStart] = useState('');
  const [discountEnd, setDiscountEnd] = useState('');

  const [prizeEventDistributionStatus, setPrizeEventDistributionStatus] = useState(false);
  const [prizeEventVisibilityStatus, setPrizeEventVisibilityStatus] = useState(true);

  const [rewardCover, setRewardCover] = useState(null);
  const [rewardCoverNew, setRewardCoverNew] = useState(null);

  useEffect(() => {
    setPrizeEventId(generateRandomID(20));

    if (selectedEvent) {
      // console.log(selectedEvent);
      setPrizeEventId(selectedEvent?.prize_event_id);
      setPrizeEventTitle(selectedEvent?.prize_event_title);
      setPrizeEventSlot(selectedEvent?.prize_event_slot);
      setPrizeEventEntryCost(selectedEvent?.prize_event_entry_cost);
      setPrizeEventDescription(selectedEvent?.prize_event_description);
      setPrizeEventSearchKeys(selectedEvent?.prize_events_search_keys);
      setPrizeEventBannerImage(selectedEvent?.prize_event_banner_image);
      setPrizes(selectedEvent?.prizes);
      setGameTitles(selectedEvent?.game_id);

      const discountDateTimeStart = moment(selectedEvent?.discount_start_date).format('YYYY-MM-DDTHH:mm');
      const discountDateTimeEnd = moment(selectedEvent?.discount_end_date).format('YYYY-MM-DDTHH:mm');

      if (selectedEvent?.discount_start_date) {
        setDiscountStart(discountDateTimeStart);
      }
      if (selectedEvent?.discount_end_date) {
        setDiscountEnd(discountDateTimeEnd);
      }

      if (selectedEvent?.discount_percentage_in_event > 0) {
        setDiscount(selectedEvent?.discount_percentage_in_event);
      }

      setPrizeEventDistributionStatus(
        selectedEvent?.prize_distribution_status && selectedEvent?.prize_distribution_status === 'true' ? true : false
      );
      setPrizeEventVisibilityStatus(
        selectedEvent?.prize_event_visibility_status && selectedEvent?.prize_event_visibility_status === '1'
          ? true
          : false
      );

      const startDateTime = moment(selectedEvent?.prize_event_start_time, 'DD/MM/YYYY HH:mm').format(
        'YYYY-MM-DDTHH:mm'
      );
      const endDateTime = moment(selectedEvent?.prize_event_end_time, 'DD/MM/YYYY HH:mm').format('YYYY-MM-DDTHH:mm');
      setPrizeEventStartTime(startDateTime);
      setPrizeEventEndTime(endDateTime);

      const prize_feature_banner = {
        rew_img: '',
        rew_img_url: selectedEvent?.prize_feature_banner_image,
      };
      setRewardCover(prize_feature_banner);

      const rewList = selectedEvent?.prizes.map(p => {
        return {
          prize_name: p.prize_name,
          prize_image_file: '',
          prize_image_url: p.reward === 'Cash' ? '' : p.prize_image_url,
          img_r_type: p.reward === 'Cash' ? 'cash' : 'physical_good',
        };
      });
      setRewardList(rewList);
    }
  }, [selectedEvent]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: 'image/*',
    onDrop: acceptedFiles => {
      setPrizeEventBannerImage(URL.createObjectURL(acceptedFiles[0]));
      setPrizeEventBannerImageFile(acceptedFiles[0]);
    },
  });

  const handleRewardCoverAdd = async e => {
    const image = e.target.files[0];
    const s = await checkImgRatio(image, 400, 720);
    if (!s) {
      dispatch(fetchError('Reward cover must be 720x400.'));
      return;
    }

    const imgLink = URL.createObjectURL(image);

    const data = {
      rew_img: image,
      rew_img_url: imgLink,
    };
    setRewardCover(data);
    setRewardCoverNew(image);
  };

  const handleBannerImgAdd = async (e, i) => {
    const image = e.target.files[0];

    const s = await checkImgRatio(image, 128, 128);
    if (!s) {
      dispatch(fetchError('Prize list image must be 128x128.'));
      return;
    }

    const imgLink = URL.createObjectURL(image);
    const newData = [...rewardList].map((inp, idx) => {
      if (idx === i) {
        return {
          ...inp,
          prize_image_file: image,
          prize_image_url: imgLink,
        };
      }
      return inp;
    });
    setRewardList(newData);
  };

  const handleRewardRadioAdd = (e, index) => {
    const newUpdatedList = [...rewardList];
    newUpdatedList[index].img_r_type = e.target.value;
    setRewardList(newUpdatedList);
  };

  const handleRewardNameAdd = (e, index) => {
    const newUpdatedList = [...rewardList];
    newUpdatedList[index].prize_name = e.target.value;
    setRewardList(newUpdatedList);
  };

  const handleRewardListRemove = index => {
    let data = [...rewardList];
    data.splice(index, 1);
    setRewardList(data);
  };

  const handleRewardListAdd = () => {
    setRewardList(
      rewardList.concat({ prize_name: '', prize_image_file: '', prize_image_url: '', img_r_type: 'physical_good' })
    );
  };

  const handleSave = () => {
    const discountStartDateCheck = moment(discountStart).isBetween(
      prize_event_start_time,
      prize_event_end_time,
      null,
      '[]'
    );

    const discountEndDateCheck = moment(discountEnd).isBetween(
      prize_event_start_time,
      prize_event_end_time,
      null,
      '[]'
    );

    // const eventNameCheckRegex = /[a-zA-Z]{3,}/g;
    const eventNameCheckRegex = /^[0-9 ]+$/;
    // const rewardRegex = /[^a-zA-Z0-9\-\s]|\-\d/;
    // const searchKeyPattern = /^[a-zA-Z, ]{2,}$/;

    const imgTypes = ['image/png', 'image/jpg', 'image/jpeg'];

    if (!prize_event_banner_image) {
      toast.error('Please select event banner image.');
      return;
    }

    if (prize_event_banner_image_file && !acceptFileType(prize_event_banner_image_file, imgTypes)) {
      toast.error('Event banner image must be png or jpg type.');
      return;
    }

    if (!prize_event_title) {
      toast.error('Please enter event title.');
      return;
    }

    if (eventNameCheckRegex.test(prize_event_title)) {
      toast.error('Please enter a valid event name.');
      return;
    }

    if (!prize_event_slot) {
      toast.error('Please enter a valid slot number.');
      return;
    }

    if (prize_event_slot < 1) {
      toast.error('Game slot can not be less than one.');
      return;
    }

    if (!prize_event_description) {
      toast.error('Please enter a valid description.');
      return;
    }

    if (!prize_event_start_time) {
      toast.error('Event start date field is required.');
      return;
    }

    if (!prize_event_end_time) {
      toast.error('Event end date field is required.');
      return;
    }

    if (moment(prize_event_start_time).isAfter(moment(prize_event_end_time))) {
      toast.error('Event end date can not be greater than event start date.');
      return;
    }

    if (!prize_event_entry_cost) {
      toast.error('Please enter event entry cost.');
      return;
    }

    if (prize_event_entry_cost < 0) {
      toast.error('Entry cost can not be less than zero.');
      return;
    }

    if (discount !== '' && (discount < 0 || discount > 100)) {
      toast.error('Discount must be in between 0 to 100.');
      return;
    }

    if (discount !== '' && !discountStart) {
      toast.error('Please enter discount start date.');
      return;
    }

    if (discount !== '' && !discountEnd) {
      toast.error('Please enter discount end date.');
      return;
    }

    if (discountStart !== '' && !discountStartDateCheck) {
      toast.error('Discount start date should between event start and end date.');
      return;
    }

    if (discountStart !== '' && !discountEndDateCheck) {
      toast.error('Discount end date should between event start and end date.');
      return;
    }

    if (!prize_event_search_keys) {
      toast.error('Please enter a valid search keys.');
      return;
    }

    if (!gameTitles) {
      toast.error('Please select a game.');
      return;
    }

    if (!rewardCover?.rew_img_url || rewardCover?.rew_img_url?.includes('undefined')) {
      toast.error('Reward cover must be uploaded!');
      return;
    }

    if (!selectedEvent && rewardCover) {
      if (!acceptFileType(rewardCover?.rew_img, imgTypes)) {
        toast.error('Reward cover must be png or jpg type.');
        return;
      }
    }

    if (!selectedEvent && rewardList[0].prize_name === '') {
      toast.error('Prize name must be included.');
      return;
    }

    if (!selectedEvent && rewardList[0].img_r_type === 'physical_good' && rewardList[0].prize_image_file === '') {
      toast.error('Prize image must be included.');
      return;
    }

    if (selectedEvent && rewardList.length === 0) {
      toast.error('Prize list can not be empty.');
      return;
    }

    let rewardCheck = false;

    rewardList.forEach((item, i) => {
      if (item.prize_name === '' || (item.img_r_type === 'physical_good' && item.prize_image_url === '')) {
        rewardCheck = true;
      }
    });

    if (rewardCheck) {
      toast.error('Prize name and prize image can not be empty.');
      return;
    }

    const data = {
      prize_event_id,
      prize_event_title,
      prize_event_start_time: moment(prize_event_start_time).format('DD/MM/YYYY HH:mm'),
      prize_event_banner_image,
      prize_event_slot,
      prize_event_entry_cost,
      prize_event_end_time: moment(prize_event_end_time).format('DD/MM/YYYY HH:mm'),
      prize_event_description,
      prize_event_search_keys,
      gameTitles,
      prize_event_visibility_status: prizeEventVisibilityStatus === true ? '1' : '0',
      prize_distribution_status: prizeEventDistributionStatus === true ? true : false,
      new_banner_image_file: prize_event_banner_image_file,
      rewardCover: rewardCover,
      new_prizes: rewardList,
      rewardCoverNew: rewardCoverNew,
      discount_percentage_in_event: discount || 0,
    };

    if (discount > 0) {
      data.discount_start_date = `${discountStart}+06:00`;
      data.discount_end_date = `${discountEnd}+06:00`;
    } else {
      data.discount_start_date = '';
      data.discount_end_date = '';
    }

    if (selectedEvent) {
      data._id = selectedEvent._id;
      patchEvent(data);
      // console.log(data, 'edit');
    } else {
      // console.log(data, 'add');
      postEvent(data);
    }
  };

  const patchEvent = async patchData => {
    // console.log(patchData);
    dispatch(fetchStart());

    let data = new FormData();

    if (patchData.new_banner_image_file) {
      let image = patchData.new_banner_image_file;
      data.append('prize_event_banner_image', image, image.name);
    }

    if (patchData.rewardCoverNew) {
      let pfb_image = patchData.rewardCoverNew;
      data.append('prize_feature_banner_image', pfb_image, pfb_image.name);
    }

    // await patchData.new_prizes.forEach(async (field, i) => {
    //   if (field.prize_image_url && !field.prize_image_url.includes('amazonaws.com')) {
    //     let img = field.prize_image_url;
    //     img = await fetch(img)
    //       .then(r => r.blob())
    //       .then(
    //         blobFile =>
    //           new File([blobFile], `prize_list_${i}_${Date.now()}.png`, {
    //             type: blobFile.type,
    //           })
    //       );
    //     // console.log(img);
    //     data.append('prize_image_' + i, img, randomString(20) + img.name);
    //   }
    // });

    // for (let i = 0; i < patchData.new_prizes.length; i++) {
    //   if (
    //     patchData.new_prizes[i].prize_image_url &&
    //     !patchData.new_prizes[i].prize_image_url.includes('amazonaws.com')
    //   ) {
    //     // console.log(patchData.new_prizes[i]);
    //     let img = patchData.new_prizes[i].prize_image_url;
    //     img = await fetch(img)
    //       .then(r => r.blob())
    //       .then(
    //         blobFile =>
    //           new File([blobFile], `prize_list_${i}_${Date.now()}.png`, {
    //             type: blobFile.type,
    //           })
    //       );
    //     data.append('prize_image_' + i, img, randomString(20) + img.name);
    //   }
    // }

    for (let i = 0; i < patchData.new_prizes.length; i++) {
      const field = patchData.new_prizes[i];
      if (field.prize_image_url && !field.prize_image_url.includes('amazonaws.com')) {
        data.append('prize_image_' + i, field.prize_image_file, randomString(20) + field.prize_image_file.name);
      }
    }

    data.append('data', JSON.stringify(patchData));

    // Axios.patch('http://192.168.10.154:7500/admin/event/update/', data)
    axiosInstance
      .patch(`/admin/event/update/`, data)
      .then(resp => {
        // console.log(resp.data);
        dispatch(fetchSuccess());
        closeModal();
        toast.success('Event updated successfully.');
        fetchEventsAfterBan();
        dispatch(commonFetchAllEvents());
      })
      .catch(error => {
        // console.log(error.response);
        toast.error('Sorry, something went wrong. Please try again later.');
        dispatch(fetchError());
      });
  };

  const postEvent = async data => {
    // console.log(data);
    // return

    let formData = new FormData();

    formData.append('prize_event_banner_image', data.new_banner_image_file, data.new_banner_image_file.name);
    formData.append('prize_feature_banner_image', data.rewardCover.rew_img, data.rewardCover.rew_img.name);

    for (let i = 0; i < data.new_prizes.length; i++) {
      const field = data.new_prizes[i];
      if (field.prize_image_file) {
        formData.append('prize_image_' + i, field.prize_image_file, randomString(20) + field.prize_image_file.name);
      }
    }

    dispatch(fetchStart());

    delete data.prize_event_banner_image;
    delete data.new_banner_image_file;
    delete data.rewardCover;
    delete data.rewardCoverNew;

    formData.append('data', JSON.stringify(data));

    axiosInstance
      .post('/admin/event/add', formData)
      .then(resp => {
        // console.log(resp.data);
        dispatch(fetchSuccess());
        closeModal();
        toast.success('Event added successfully.');
        fetchEventsAfterBan();
        dispatch(commonFetchAllEvents());
      })
      .catch(error => {
        // console.log(error.response);
        toast.error('Sorry, something went wrong. Please try again later.');
        dispatch(fetchError());
      });
  };

  const handleActiveInactive = event => {
    dispatch(fetchStart());
    axiosInstance
      .patch(`/admin/event/change-prize-event-visibility-status/${event._id}`)
      .then(resp => {
        // console.log(resp.data);
        dispatch(fetchSuccess());
        closeModal();
        toast.success('Event updated successfully.');

        setData(prevData => {
          return {
            ...prevData,
            data: prevData?.data?.map(event => {
              if (event._id === resp?.data?.data?._id) {
                return {
                  ...event,
                  prize_event_visibility_status: resp?.data?.data?.prize_event_visibility_status,
                  // status: resp?.data?.data?.status,
                };
              }
              return { ...event };
            }),
          };
        });
      })
      .catch(error => {
        // console.log(error.response)
        toast.error('Sorry, something went wrong. Please try again later.');
        dispatch(fetchError());
      });
  };

  return (
    <Dialog open={open} className={classes.dialogRoot}>
      <DialogTitle className={classes.dialogTitleRoot}>
        {selectedEvent ? 'Edit Event Details' : 'Create New Event'}
      </DialogTitle>

      <DialogContent dividers>
        <Box display='flex' flexDirection={{ xs: 'column', md: 'row' }} alignItems='center' mb={{ xs: 6, md: 5 }}>
          <Box {...getRootProps()} mr={{ xs: 0, md: 5 }} mb={{ xs: 3, md: 0 }} className='pointer'>
            <input {...getInputProps()} />
            <CmtAvatar size={70} src={`${prize_event_banner_image}`} />
          </Box>
          <GridContainer>
            <Grid>
              <AppTextInput
                fullWidth
                variant='outlined'
                label='Prize Event Id'
                value={prize_event_id}
                onChange={e => setPrizeEventId(e.target.value)}
                disabled={true}
                style={{ width: 250 }}
              />
            </Grid>
          </GridContainer>
        </Box>

        {selectedEvent && (
          <Box mb={{ xs: 6, md: 5 }} style={{ display: 'flex', alignItems: 'center' }}>
            <Typography className={classes.titleRoot}>
              Visibility status:{' '}
              {selectedEvent.prize_event_visibility_status === '1' ? (
                <span className='text-success'>Active</span>
              ) : (
                <span className='text-danger'>Inactive</span>
              )}
            </Typography>
            <Button
              color='primary'
              size='small'
              variant='contained'
              onClick={() => handleActiveInactive(selectedEvent)}
              style={{ marginLeft: 5 }}
            >
              Change
            </Button>
          </Box>
        )}

        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            fullWidth
            variant='outlined'
            label='Prize Event Title'
            value={prize_event_title}
            onChange={e => setPrizeEventTitle(e.target.value)}
          />
        </Box>

        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            fullWidth
            variant='outlined'
            label='Feature Game Slot No.'
            value={prize_event_slot}
            onChange={e => setPrizeEventSlot(e.target.value)}
            type={'number'}
            InputProps={{ inputProps: { min: 1 } }}
          />
        </Box>

        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            fullWidth
            variant='outlined'
            label='Description'
            value={prize_event_description}
            onChange={e => setPrizeEventDescription(e.target.value)}
          />
        </Box>

        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            fullWidth
            variant='outlined'
            id='date'
            label='Start Date'
            type='datetime-local'
            value={prize_event_start_time}
            onChange={e => setPrizeEventStartTime(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            fullWidth
            variant='outlined'
            id='date'
            label='End Date'
            type='datetime-local'
            value={prize_event_end_time}
            onChange={e => setPrizeEventEndTime(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </Box>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            fullWidth
            variant='outlined'
            label='Entry Cost'
            value={prize_event_entry_cost}
            onChange={e => {
              setPrizeEventEntryCost(e.target.value);
            }}
            type={'number'}
            InputProps={{ inputProps: { min: 1 } }}
          />
        </Box>

        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            fullWidth
            variant='outlined'
            label='Discount'
            value={discount}
            onChange={e => setDiscount(e.target.value)}
            type={'number'}
            InputProps={{ inputProps: { min: 0, max: 100 } }}
          />
        </Box>
        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            fullWidth
            variant='outlined'
            label='Discount Start Date'
            value={discountStart}
            onChange={e => setDiscountStart(e.target.value)}
            type='datetime-local'
            InputLabelProps={{ shrink: true }}
          />
        </Box>

        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            fullWidth
            variant='outlined'
            label='Discount End Date'
            value={discountEnd}
            onChange={e => setDiscountEnd(e.target.value)}
            type='datetime-local'
            InputLabelProps={{ shrink: true }}
          />
        </Box>

        <Box mb={{ xs: 6, md: 5 }}>
          <AppTextInput
            fullWidth
            variant='outlined'
            label='Search Keys'
            value={prize_event_search_keys}
            onChange={e => {
              setPrizeEventSearchKeys(e.target.value);
            }}
          />
          <small>Comma seperated value (e.g. Action, Sports)</small>
        </Box>

        <Box mb={{ xs: 6, md: 5 }}>
          <FormControl size='small' fullWidth variant='outlined'>
            <InputLabel id='demo-simple-select-label'>Select Game</InputLabel>
            <Select
              labelId='demo-simple-select-label'
              id='demo-simple-select'
              value={gameTitles}
              label='Select Game'
              onChange={e => setGameTitles(e.target.value)}
            >
              {gameList?.map(game => (
                <MenuItem key={game._id} value={game._id}>
                  {game?.game_title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        <hr style={{ marginBottom: 15 }} />

        <Box mb={{ xs: 6, md: 5 }}>
          <h3>Rewards</h3>
          <p>Upload Prize Cover</p>
          <input
            type='file'
            accept='image/jpeg, image/png'
            onChange={e => handleRewardCoverAdd(e)}
            className='reward-cover-input'
            style={{
              backgroundImage: rewardCover?.rew_img_url !== '' ? `url('${rewardCover?.rew_img_url}')` : '',
            }}
          />
        </Box>

        <Box mb={{ xs: 6, md: 5 }}>
          <h3 style={{ marginBottom: 10 }}>Prize List</h3>
          <CmtList
            data={rewardList}
            renderRow={(item, index) => (
              <GridContainer style={{ marginBottom: 2 }} key={index}>
                <Grid item xs={12} sm={11}>
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    {index + 1}.
                    <Box mr={{ xs: 0, md: 5 }} mb={{ xs: 3, md: 0 }} ml={3}>
                      {item.img_r_type === 'cash' ? (
                        <img src={DollerImg} alt='' className='reward-static-cash' />
                      ) : (
                        <input
                          type='file'
                          accept='image/jpeg, image/png'
                          onChange={e => handleBannerImgAdd(e, index)}
                          className='tutorial-banner-input'
                          style={{
                            backgroundImage: item.prize_image_url !== '' ? `url('${item.prize_image_url}')` : '',
                          }}
                        />
                      )}
                    </Box>
                    <div>
                      <AppTextInput
                        fullWidth
                        variant='outlined'
                        label='Prize Name'
                        value={item.prize_name}
                        onChange={e => handleRewardNameAdd(e, index)}
                      />
                      <FormControl component='fieldset'>
                        <RadioGroup
                          aria-label='gender'
                          name='gender1'
                          style={{ flexDirection: 'row' }}
                          value={item.img_r_type}
                          onChange={e => handleRewardRadioAdd(e, index)}
                        >
                          <FormControlLabel
                            value='physical_good'
                            control={<Radio color='primary' />}
                            label='Physical Good'
                          />
                          <FormControlLabel value='cash' control={<Radio color='primary' />} label='Cash' />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                </Grid>
                {index > 0 && (
                  <Grid container item xs={1} sm={1} justifyContent='center' alignItems='center'>
                    <IconButton color='inherit' onClick={() => handleRewardListRemove(index)} size='small'>
                      <CancelIcon />
                    </IconButton>
                  </Grid>
                )}
              </GridContainer>
            )}
          />
        </Box>

        <Box mb={{ xs: 6, md: 5 }} display='inline-flex' alignItems='center' className='pointer'>
          <Button color='primary' onClick={handleRewardListAdd} startIcon={<AddCircleOutlineIcon />}>
            Add More
          </Button>
        </Box>
      </DialogContent>

      <DialogActions>
        <Box display='flex' justifyContent='flex-end' mb={4}>
          <Button onClick={closeModal}>Cancel</Button>
          <Box ml={2}>
            <Button variant='contained' color='primary' onClick={handleSave}>
              Save
            </Button>
          </Box>
        </Box>
      </DialogActions>

      {/* <Box className={classes.modalHead}>
        <Typography variant='h4' className={classes.titleHead}>
          User Details
        </Typography>
        <IconButton onClick={closeModal}>
          <ClearIcon />
        </IconButton>
      </Box>
      <Box className={classes.userInfoRoot}>
        <Box
          mr={3}
          display='flex'
          alignItems={{ xs: 'center', md: 'start' }}
          flexDirection={{ xs: 'column', md: 'row' }}
        >
          <Box className={classes.avatarView} mr={{ xs: 4, md: 6 }}>
            <CmtAvatar size={100} src={profile_picture} alt={user_name} />
          </Box>

          <Box mt={-2}>
            <Box>
              <Typography className={classes.titleRoot}>Name: {user_name}</Typography>
              <Typography className={classes.titleRoot}>Phone: {phone}</Typography>
              <Typography className={classes.titleRoot}>
                Sign up Date: {moment(registration_date).format('hh.mm A, DD MMM YY')}
              </Typography>
              <Typography className={classes.titleRoot}>Events Played: {played_events}</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <GridContainer style={{ marginBottom: 12 }} />
      <Box display='flex' justifyContent='flex-end' mt={4} mb={4} mr={3}>
        <Box ml={2}>
          <Button variant='contained' color='primary' onClick={closeModal}>
            Close
          </Button>
        </Box>
      </Box> */}
    </Dialog>
  );
};

export default AddEditModal;
