import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core';
import useStyles from '../index.style';
import NoRecordFound from 'components/NoRecordFound.Component';
import { Pagination } from '@material-ui/lab';
import { handleDownloadUserCSV } from '../functions';
import ShowingDate from 'components/ShowingDate.Component';
import { DateRangeOutlined } from '@material-ui/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getDynamicLinks } from 'redux/actions/Analytic';
import DatePopover from 'components/OnlydatePopover.Component';
import { SET_DYNAMIC_LINKS_CP, SET_DYNAMIC_LINKS_DR, SET_DYNAMIC_LINKS_DRA } from '@jumbo/constants/ActionTypes';
import moment from 'moment';
import { fetchError } from 'redux/actions';

const pageSize = 20;

const DynamicLinks = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let { dynamicLinks } = useSelector(({ analyticReducer }) => analyticReducer);

  //   console.log(dynamicLinks);

  useEffect(() => {
    if (!dynamicLinks?.dynamicLinksData) {
      dispatch(getDynamicLinks(pageSize));
    }
  }, [dispatch]);

  const [showPopover, setShowPopover] = useState(null);
  const open = Boolean(showPopover);
  const id = open ? 'simple-popper' : undefined;

  const handleApplyDateRange = () => {
    if (!dynamicLinks?.dynamicLinksDR[0]) {
      dispatch(fetchError('Please select start date.'));
      return;
    }

    if (!dynamicLinks?.dynamicLinksDR[1]) {
      dispatch(fetchError('Please select end date.'));
      return;
    }

    // const startDateFormat = new Date(dateRange[0]); 2023-03-18T11:38:38.384+06:00
    const startDateFormat = moment(dynamicLinks?.dynamicLinksDR[0]).format('YYYY-MM-DDT00:00:00+06:00');
    const endDateFormat = moment(dynamicLinks?.dynamicLinksDR[1]).format('YYYY-MM-DDT23:59:59+06:00');

    // fetchDynamicLinks(startDateFormat, endDateFormat, setDlDateRangeApplied(true));
    dispatch(
      getDynamicLinks(pageSize, startDateFormat, endDateFormat, 1, () => {
        dispatch({ type: SET_DYNAMIC_LINKS_DRA, payload: true });
        dispatch({ type: SET_DYNAMIC_LINKS_CP, payload: 1 });
      })
    );

    // console.log(startDateFormat);
    // console.log(endDateFormat);
    setShowPopover(null);
  };

  const handleDateClear = () => {
    // fetchDynamicLinks('', '', setDlDateRangeApplied(false));
    dispatch(
      getDynamicLinks(pageSize, '', '', 1, () => {
        dispatch({ type: SET_DYNAMIC_LINKS_DRA, payload: false });
        dispatch({ type: SET_DYNAMIC_LINKS_CP, payload: 1 });
      })
    );
    setDateRange([null, null]);
  };

  const setDateRange = value => {
    dispatch({ type: SET_DYNAMIC_LINKS_DR, payload: value });
  };

  const handlePagination = (e, page) => {
    // setCurrentPageTotal(page);
    dispatch({ type: SET_DYNAMIC_LINKS_CP, payload: page });
    // getTotalData(page);
    // console.log(dateRange);
    dispatch(getDynamicLinks(pageSize, dynamicLinks?.dynamicLinksDR[0], dynamicLinks?.dynamicLinksDR[1], page));
  };

  return (
    <Paper className={classes.paper}>
      <Toolbar className={classes.root} style={{ justifyContent: 'space-between' }}>
        <Box>
          <Typography className={classes.title} variant='h4' id='tableTitle' component='div'>
            DL Campaign Data Report
          </Typography>

          {dynamicLinks?.dynamicLinksDRA && (
            <ShowingDate dateRange={dynamicLinks?.dynamicLinksDR} handleClear={handleDateClear} />
          )}
        </Box>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <button
            type='button'
            aria-describedby={id}
            className='date-range-picker'
            onClick={event => setShowPopover(showPopover ? null : event.currentTarget)}
          >
            <DateRangeOutlined style={{ fill: '#fff', width: 20 }} />
          </button>

          <Button
            onClick={() => {
              handleDownloadUserCSV('dl_table', 'dynamic_links_table');
            }}
            variant='contained'
            color='primary'
          >
            Export
          </Button>
        </div>
      </Toolbar>

      <TableContainer className={classes.container}>
        <Table stickyHeader className='dl_table'>
          <DynamicLinksTableHead />
          {dynamicLinks && (
            <TableBody>
              {dynamicLinks?.dynamicLinksData?.data?.length > 0 ? (
                dynamicLinks?.dynamicLinksData?.data?.map((links, i) => <DynamicLinksTableBody key={i} links={links} />)
              ) : (
                <TableRow style={{ height: 200 }}>
                  <TableCell colSpan={11} rowSpan={7}>
                    <NoRecordFound>There are no records found.</NoRecordFound>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>

      {dynamicLinks?.dynamicLinksTP > 1 && (
        <Box px={4} py={5} display='flex' justifyContent='flex-end'>
          <Pagination
            disabled={dynamicLinks?.dynamicLinksPD}
            page={dynamicLinks?.dynamicLinksCP}
            count={dynamicLinks?.dynamicLinksTP}
            color='primary'
            onChange={handlePagination}
          />
        </Box>
      )}

      <DatePopover
        id={id}
        open={open}
        showPopover={showPopover}
        setShowPopover={setShowPopover}
        dateRange={dynamicLinks?.dynamicLinksDR}
        setDateRange={setDateRange}
        handleApplyDateRange={handleApplyDateRange}
        type='range'
        allowSingleDateInRange
      />
    </Paper>
  );
};

export default DynamicLinks;

const DynamicLinksTableBody = React.memo(({ links }) => {
  return (
    <TableRow hover role='checkbox' tabIndex={-1}>
      <TableCell align='left' style={{ paddingLeft: 20 }}>
        {links?.dynamicLink || '-'}
      </TableCell>
      <TableCell align='center'>{links?.total_installed || 0}</TableCell>
      <TableCell align='center'>{links?.paid_user || 0}</TableCell>
      <TableCell align='center'>{links?.paid_event_played || 0}</TableCell>
      <TableCell align='center'>{links?.free_event_played || 0}</TableCell>
      <TableCell align='center'>
        {links?.total_purchased_amount ? 'BDT ' + links?.total_purchased_amount : 'BDT 0'}
      </TableCell>
    </TableRow>
  );
});

const DynamicLinksTableHead = React.memo(() => {
  const tableHeader = [
    'Dynamic Links',
    'Registered User',
    'Paid User',
    'Event Purchased (Paid)',
    'Event Purchased (Free)',
    'Total Purchased Amount',
  ];

  return (
    <TableHead>
      <TableRow>
        {tableHeader?.map((d, i) => (
          <TableCell key={i} align={i === 0 ? 'left' : 'center'} padding={'normal'}>
            {d}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
});
