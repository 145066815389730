import React from 'react';
import GeneralNotification from './GeneralNotification.Component';
import ScheduleNotification from './ScheduleNotification.Component';

const PushNotofication = ({ classes }) => {
  return (
    <>
      <GeneralNotification classes={classes} />
      <br />
      <ScheduleNotification classes={classes} />
    </>
  );
};

export default PushNotofication;
