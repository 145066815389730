import {
  RESET_ANALYTIC_DATA,
  SET_DYNAMIC_LINKS_CP,
  SET_DYNAMIC_LINKS_DATA,
  SET_DYNAMIC_LINKS_DR,
  SET_DYNAMIC_LINKS_DRA,
  SET_DYNAMIC_LINKS_PD,
  SET_DYNAMIC_LINKS_TP,
  SET_EVENT_CURRENT_PAGE,
  SET_EVENT_DATA,
  SET_EVENT_PAGINATION_DISABLED,
  SET_EVENT_TOTAL_PAGES,
  SET_PAID_USER_ALL,
  SET_PAID_USER_ALL_DATE,
  SET_PAID_USER_ALL_FILTER,
  SET_PAID_USER_REGISTERED,
  SET_PAID_USER_REGISTERED_DATE,
  SET_PAID_USER_REGISTERED_FILTER,
  SET_TOTAL_CURRENT_PAGE,
  SET_TOTAL_DATA,
  SET_TOTAL_PAGINATION_DISABLED,
  SET_TOTAL_TOTAL_PAGES,
} from '@jumbo/constants/ActionTypes';

const INIT_STATE = {
  totalGames: {
    data: null,
    paginationDisabled: false,
    totalPages: 0,
    currentPage: 1,
  },
  paidUsers: {
    paidUserAll: null,
    paidUserAllDate: [null, null],
    isFilterAppliedAll: false,
    //
    paidUserRegistered: null,
    paidUserRegisteredDate: [null, null],
    isFilterAppliedRegistered: false,
  },
  events: {
    data: null,
    paginationDisabled: false,
    totalPages: 0,
    currentPage: 1,
  },
  dynamicLinks: {
    dynamicLinksData: null, // total data
    dynamicLinksPD: false, //pagination Disabled
    dynamicLinksTP: 0, //total pages
    dynamicLinksCP: 1, // current page
    dynamicLinksDR: [null, null], // date range
    dynamicLinksDRA: false, // date range applied
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_ANALYTIC_DATA: {
      return (state = action.payload);
    }
    case SET_TOTAL_DATA: {
      return {
        ...state,
        totalGames: {
          ...state.totalGames,
          data: action.payload,
        },
      };
    }
    case SET_TOTAL_PAGINATION_DISABLED: {
      return {
        ...state,
        totalGames: {
          ...state.totalGames,
          paginationDisabled: action.payload,
        },
      };
    }
    case SET_TOTAL_TOTAL_PAGES: {
      return {
        ...state,
        totalGames: {
          ...state.totalGames,
          totalPages: action.payload,
        },
      };
    }
    case SET_TOTAL_CURRENT_PAGE: {
      return {
        ...state,
        totalGames: {
          ...state.totalGames,
          currentPage: action.payload,
        },
      };
    }
    case SET_PAID_USER_ALL: {
      return {
        ...state,
        paidUsers: {
          ...state.paidUsers,
          paidUserAll: action.payload,
        },
      };
    }
    case SET_PAID_USER_ALL_DATE: {
      return {
        ...state,
        paidUsers: {
          ...state.paidUsers,
          paidUserAllDate: action.payload,
        },
      };
    }
    case SET_PAID_USER_ALL_FILTER: {
      return {
        ...state,
        paidUsers: {
          ...state.paidUsers,
          isFilterAppliedAll: action.payload,
        },
      };
    }
    case SET_PAID_USER_REGISTERED: {
      return {
        ...state,
        paidUsers: {
          ...state.paidUsers,
          paidUserRegistered: action.payload,
        },
      };
    }
    case SET_PAID_USER_REGISTERED_DATE: {
      return {
        ...state,
        paidUsers: {
          ...state.paidUsers,
          paidUserRegisteredDate: action.payload,
        },
      };
    }
    case SET_PAID_USER_REGISTERED_FILTER: {
      return {
        ...state,
        paidUsers: {
          ...state.paidUsers,
          isFilterAppliedRegistered: action.payload,
        },
      };
    }
    case SET_DYNAMIC_LINKS_DATA: {
      return {
        ...state,
        dynamicLinks: {
          ...state.dynamicLinks,
          dynamicLinksData: action.payload,
        },
      };
    }
    case SET_DYNAMIC_LINKS_PD: {
      return {
        ...state,
        dynamicLinks: {
          ...state.dynamicLinks,
          dynamicLinksPD: action.payload,
        },
      };
    }
    case SET_DYNAMIC_LINKS_TP: {
      return {
        ...state,
        dynamicLinks: {
          ...state.dynamicLinks,
          dynamicLinksTP: action.payload,
        },
      };
    }
    case SET_DYNAMIC_LINKS_CP: {
      return {
        ...state,
        dynamicLinks: {
          ...state.dynamicLinks,
          dynamicLinksCP: action.payload,
        },
      };
    }
    case SET_DYNAMIC_LINKS_DR: {
      return {
        ...state,
        dynamicLinks: {
          ...state.dynamicLinks,
          dynamicLinksDR: action.payload,
        },
      };
    }
    case SET_DYNAMIC_LINKS_DRA: {
      return {
        ...state,
        dynamicLinks: {
          ...state.dynamicLinks,
          dynamicLinksDRA: action.payload,
        },
      };
    }
    case SET_EVENT_DATA: {
      return {
        ...state,
        events: {
          ...state.events,
          data: action.payload,
        },
      };
    }
    case SET_EVENT_PAGINATION_DISABLED: {
      return {
        ...state,
        events: {
          ...state.events,
          paginationDisabled: action.payload,
        },
      };
    }
    case SET_EVENT_TOTAL_PAGES: {
      return {
        ...state,
        events: {
          ...state.events,
          totalPages: action.payload,
        },
      };
    }
    case SET_EVENT_CURRENT_PAGE: {
      return {
        ...state,
        events: {
          ...state.events,
          currentPage: action.payload,
        },
      };
    }
    default:
      return state;
  }
};
