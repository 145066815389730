import React, { useCallback, useEffect, useState } from 'react';
import useStyles from './index.style';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@material-ui/core';
import CmtSearch from '@coremat/CmtSearch';
import { deleteGame, getAllGames } from 'redux/actions/GamesV2';
import { Delete, Edit, MoreHoriz, Visibility } from '@material-ui/icons';
import NoRecordFound from 'components/NoRecordFound.Component';
import CmtDropdownMenu from '@coremat/CmtDropdownMenu';
import { Pagination } from '@material-ui/lab';
import { GAMES_CURRENT_PAGE, GAMES_SET_SEARCH } from '@jumbo/constants/ActionTypes';
import { Toaster } from 'react-hot-toast';
import ConfirmDialog from '@jumbo/components/Common/ConfirmDialog';
import { useHistory } from 'react-router-dom';
import AddEditGame from './components/AddEditGame.Components';
import { commonFetchGameCategory, commonFetchGames } from 'redux/actions/commonDataFetch';
const pageSize = 20;

const Games = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { games } = useSelector(({ gameReducer }) => gameReducer);
  const { gameCategory } = useSelector(({ CommonSharedData }) => CommonSharedData);
  const history = useHistory();

  const [selectedGame, setSelectedGame] = useState(null);
  const [showAddEditModal, setShowAddEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  useEffect(() => {
    if (!games?.data) {
      dispatch(getAllGames(pageSize));
    }

    if (!gameCategory) {
      dispatch(commonFetchGameCategory());
    }
  }, [dispatch]);

  const handleSearch = e => {
    e.preventDefault();

    // console.log(search);
    // setCurrentPage(1);
    dispatch({ type: GAMES_CURRENT_PAGE, payload: 1 });
    dispatch(getAllGames(pageSize, 1, games?.search));
  };

  // console.log(games);

  const handleTutorial = useCallback(game => {
    // console.log('tutorial', game);
    history.push(`/game-tutorials/${game._id}`);
  }, []);

  const handleEditGame = useCallback(game => {
    // console.log('edit', game);
    setSelectedGame(game);
    setShowAddEditModal(true);
  }, []);

  const handleDeleteGame = useCallback(game => {
    // console.log('delete', game);
    setSelectedGame(game);
    setShowDeleteModal(true);
  }, []);

  const handlePagination = (e, page) => {
    // setCurrentPage(page);
    // fetchData(search, page);
    // console.log(page);
    dispatch({ type: GAMES_CURRENT_PAGE, payload: page });
    dispatch(getAllGames(pageSize, page, games?.search));
  };

  const resetAllModal = () => {
    setSelectedGame(null);
    setShowAddEditModal(false);
    setShowDeleteModal(false);
  };

  const fetchDataAfterActions = () => {
    dispatch(getAllGames(pageSize, games?.currentPage, games?.search));
  };

  const handleConfirmDelete = () => {
    dispatch(
      deleteGame(selectedGame?._id, () => {
        fetchDataAfterActions();
        resetAllModal();
        dispatch(commonFetchGames());
      })
    );
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Toolbar className={classes.root} display='flex' style={{ justifyContent: 'space-between' }}>
          <Box display='flex' alignItems='center'>
            <Typography className={classes.title} variant='h4' id='tableTitle' component='div'>
              Total Games: {games?.data?.total_count || 0}
            </Typography>
            <Button
              type='submit'
              style={{ marginLeft: 10 }}
              variant='contained'
              color='primary'
              onClick={() => setShowAddEditModal(true)}
            >
              Add new game
            </Button>
          </Box>

          <form onSubmit={handleSearch}>
            <Box display='flex'>
              <CmtSearch
                onChange={e => dispatch({ type: GAMES_SET_SEARCH, payload: e.target.value })}
                value={games?.search}
                border={true}
                onlyIcon={false}
              />
              <Button type='submit' style={{ marginLeft: 10 }} variant='contained' color='primary'>
                Search
              </Button>
            </Box>
          </form>
        </Toolbar>

        <TableContainer className={classes.container}>
          <Table stickyHeader>
            <GameTableHead />
            {games && (
              <TableBody>
                {games?.data?.data?.length > 0 ? (
                  games?.data?.data?.map(game => (
                    <GameTableBody
                      key={game?._id}
                      game={game}
                      handleTutorial={handleTutorial}
                      handleEditGame={handleEditGame}
                      handleDeleteGame={handleDeleteGame}
                    />
                  ))
                ) : (
                  <TableRow style={{ height: 200 }}>
                    <TableCell colSpan={11} rowSpan={7}>
                      <NoRecordFound>There are no records found.</NoRecordFound>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        {games?.totalPages > 1 && (
          <Box px={4} py={5} display='flex' justifyContent='flex-end'>
            <Pagination
              disabled={games?.paginationDisabled}
              page={games?.currentPage}
              count={games?.totalPages}
              color='primary'
              onChange={handlePagination}
            />
          </Box>
        )}

        {showAddEditModal && (
          <AddEditGame
            open={showAddEditModal}
            closeModal={resetAllModal}
            selectedGame={selectedGame}
            fetchDataAfterActions={fetchDataAfterActions}
          />
        )}

        {showDeleteModal && (
          <ConfirmDialog
            open={showDeleteModal}
            title={`Confirm Delete ${selectedGame?.game_title}`}
            content='Are you sure to delete this game?'
            onClose={resetAllModal}
            onConfirm={handleConfirmDelete}
          />
        )}
      </Paper>

      <Toaster position='top-right' toastOptions={{ duration: 3000 }} />
    </div>
  );
};

export default Games;

const GameTableBody = React.memo(({ game, handleTutorial, handleEditGame, handleDeleteGame }) => {
  const onUserMenuClick = menu => {
    if (menu.action === 'tutorials') {
      handleTutorial(game);
    } else if (menu.action === 'edit') {
      handleEditGame(game);
    } else if (menu.action === 'delete') {
      handleDeleteGame(game);
    }
  };

  const getGameActions = () => {
    const actions = [
      { action: 'edit', label: 'Edit', icon: <Edit /> },
      { action: 'delete', label: 'Delete', icon: <Delete /> },
      { action: 'tutorials', label: 'Tutorials', icon: <Visibility /> },
    ];
    return actions;
  };

  const userActions = getGameActions(game);

  const getGameActiveStatus = status => {
    if (status === '0') {
      return <span className='text-danger'>Hidden</span>;
    } else if (status === '1') {
      return <span className='text-warning'>Test</span>;
    } else if (status === '2') {
      return <span className='text-success'>Live</span>;
    } else {
      return <span />;
    }
  };

  return (
    <TableRow>
      <TableCell align='left' style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
        <img className='p-image' src={game?.game_icon_url} alt={game?.game_title} />
        {game?.game_title}
      </TableCell>
      <TableCell align='center'>{game?.game_category?.category_name}</TableCell>
      <TableCell align='center'>{getGameActiveStatus(game?.game_active_status)}</TableCell>
      <TableCell align='center' className='text-capitalize'>
        {game?.game_orientation}
      </TableCell>
      <TableCell align='center' onClick={e => e.stopPropagation()}>
        <CmtDropdownMenu items={userActions} onItemClick={onUserMenuClick} TriggerComponent={<MoreHoriz />} />
      </TableCell>
    </TableRow>
  );
});

const GameTableHead = React.memo(() => {
  const tableHeader = ['Game Name', 'Category', 'Status', 'Orientation', 'Actions'];

  return (
    <TableHead>
      <TableRow>
        {tableHeader?.map((d, i) => (
          <TableCell key={i} align={i === 0 ? 'left' : 'center'} padding={'normal'}>
            {d}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
});
