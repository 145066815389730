import React, { useState } from 'react';
import useStyles from '../index.style';
import Tabs from 'components/Tabs.Component';
import OPCController from '../components/OPCController.Component';
import OPCPastOffer from '../components/OPCPastOffer.Component';
import OPCDiscountOffer from '../components/OPCDiscountOffer.Component';

const tabs = ['Discount Offers', 'Past Offers', 'Controller'];

const RefOfferPoint = () => {
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(tabs[0]);

  return (
    <div className={classes.root} id='referralOpc'>
      <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab} />
      {activeTab === 'Discount Offers' && <OPCDiscountOffer />}
      {activeTab === 'Past Offers' && <OPCPastOffer />}
      {activeTab === 'Controller' && <OPCController />}
    </div>
  );
};

export default RefOfferPoint;
