import React from 'react';

const Tabs = ({ tabs, activeTab, setActiveTab, style }) => {
  return (
    <div className='customTabs' style={style}>
      {tabs.map((tab, i) => (
        <button onClick={() => setActiveTab(tab)} className={activeTab === tab ? 'active' : ''} key={i}>
          {tab}
        </button>
      ))}
    </div>
  );
};

export default Tabs;
